import React from 'react';
import PropTypes from 'prop-types';
import {Card} from "antd";
import {Model} from "survey-core";
import {Survey} from "survey-react-ui";

class ProviderAccountForm extends React.Component {
    state = {
        provider: null,
    }

    constructor(props) {
        super(props);


        const model = new Model(
            {
                "logoPosition": "right",
                "widthMode": "responsive",
                "pages": [
                    {
                        "name": "page1",
                        "elements": [
                            {
                                "type": "text",
                                "name": "Name",
                                "title": "Name",
                                "isRequired": true
                            },
                            {
                                "type": "text",
                                "name": "Email",
                                "title": "Email",
                                "isRequired": true,
                                "inputType": "email"
                            },
                            {
                                "type": "text",
                                "name": "Phone",
                                "startWithNewLine": false,
                                "title": "Phone",
                                "inputType": "tel"
                            },
                            {
                                "type": "comment",
                                "name": "Address",
                                "title": "Address"
                            }
                        ],
                        "title": "Account",
                        "description": "Manage Transporter | provider"
                    }
                ]
            }
        )


        this.state = {
            provider: props.provider || null,
            loading: false,
            model: model
        }
    }

    render() {

        const {
            model,
            loading,
            provider
        } = this.state;

        model.data = provider;
        model.completeText = "Save";

        model.onComplete.add((form, options) => {
            this.setState({provider: form.data})
        });

        return (
            <Card
                bordered={false}
            >
                <Survey model={model}/>
            </Card>
        );


    }


}

// PROPS
ProviderAccountForm.propTypes = {
    provider: PropTypes.object.isRequired
}

export default ProviderAccountForm
