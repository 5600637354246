import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  Input,
  Alert,
  Select,
  AutoComplete,
  Empty,
  Spin,
  Col,
  Row,
  Table,
} from "antd";
import {
  exportToExcel,
  exportToCSV,
  exportToPDF,
} from "../../utils/generateReports";
import "antd/dist/antd.css";

const { Option } = Select;

const summaryColumns = [
  {
    title: "Student Type",
    dataIndex: "type",
    key: "type",
    width: 200,
  },
  {
    title: "Count",
    dataIndex: "count",
    key: "count",
    width: 200,
  },
];

const studentTypes = [
  { id: 1, label: "Paying" },
  { id: 2, label: "Not Paying" },
  { id: 3, label: "Sponsored" },
  { id: 4, label: "Boarding" },
];

const StudentTypeSummary = () => {
  const [busy, setBusy] = useState(false);
  const [text, setText] = useState("Generate Report");
  const [summaryData, setSummaryData] = useState([]);
  const [totalStudents, setTotalStudents] = useState(0);
  const [error, setError] = useState("");
  const [startDate, setStartDate] = useState(
    new Date().toLocaleDateString("en-CA")
  );
  const [endDate, setEndDate] = useState(
    new Date().toLocaleDateString("en-CA")
  );
  const [token, setToken] = useState("");
  const [students, setStudents] = useState([]);
  const [classes, setClasses] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState(students);
  const [baseUrl, setBaseUrl] = useState("");
  const [selectedStudent, setSelectedStudent] = useState("");
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedType, setSelectedType] = useState("");

  useEffect(() => {
    const account = JSON.parse(localStorage.getItem("account"));
    const BaseUrl = localStorage.getItem("api");

    if (account && account.access_token) {
      setToken(account.access_token);
    } else {
      setError("Missing authentication token. Please log in again.");
    }

    if (BaseUrl) {
      setBaseUrl(BaseUrl);
    } else {
      setError("Missing base URL. Please configure your settings.");
    }

    const storedStudents = JSON.parse(localStorage.getItem("students")) || [];
    const storedClasses = JSON.parse(localStorage.getItem("classes")) || [];
    setStudents(storedStudents);
    setClasses(storedClasses);
  }, []);

  useEffect(() => {
    setFilteredStudents(students);
  }, [students]);

  const handleStudentSearch = (value) => {
    if (!value) {
      setFilteredStudents(students);
    } else {
      const lowercasedValue = value.toLowerCase();
      const filtered = students.filter((student) =>
        student.Name.toLowerCase().includes(lowercasedValue)
      );
      setFilteredStudents(filtered);
    }
  };

  const calculateTotals = (data) => {
    const typeCounts = data.reduce(
      (acc, item) => {
        acc[item.Type] = (acc[item.Type] || 0) + 1;
        acc.total += 1;
        return acc;
      },
      { total: 0 }
    );

    setTotalStudents(typeCounts.total);
    return Object.keys(typeCounts)
      .filter((key) => key !== "total")
      .map((key) => ({ type: key, count: typeCounts[key] }))
      .concat({ type: "Total", count: typeCounts.total });
  };

  const generateReport = async () => {
    if (!token) {
      setError("Missing authentication token. Please log in again.");
      return;
    }

    setBusy(true);
    setText("Generating Report...");
    setError("");
    setSummaryData([]);
    try {
      const url = `${baseUrl}/reports/student-types?Student=${selectedStudent}&Class=${selectedClass}&Type=${selectedType}&StartDate=${startDate}&EndDate=${endDate}`;
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const { data } = await axios.get(url, { headers });

      const typeSummary = calculateTotals(data.data);
      setSummaryData(typeSummary);
    } catch (error) {
      setError(
        `Error fetching data: ${error.response?.data?.message || error.message}`
      );
    } finally {
      setBusy(false);
      setText("Generate Report");
    }
  };

  return (
    <div style={{ marginTop: 30 }}>
      <h4 style={{ textAlign: "center", marginBottom: 20 }}>
        Student Type Summary
      </h4>
      <div style={{ width: "50%", margin: "0 auto", marginTop: 30 }}>
        <Row gutter={[16, 16]} style={{ marginBottom: "40px" }}>
          {/* Date and filter fields */}
          <Col span={12}>
            <label htmlFor="startDate">Start Date</label>
            <Input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              style={{ width: "100%" }}
            />
          </Col>

          <Col span={12}>
            <label htmlFor="endDate">End Date</label>
            <Input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              style={{ width: "100%" }}
            />
          </Col>

          <Col span={12}>
            <label htmlFor="studentType">Student Type</label>
            <Select
              placeholder="Select a student type..."
              style={{ width: "100%" }}
              onChange={setSelectedType}
              value={selectedType}
            >
              <Option value="">All</Option>
              {studentTypes.map((type) => (
                <Option key={type.id} value={type.label}>
                  {type.label}
                </Option>
              ))}
            </Select>
          </Col>

          <Col span={12}>
            <label htmlFor="students">Students</label>
            <AutoComplete
              placeholder="Search for a student..."
              style={{ width: "100%" }}
              onSearch={handleStudentSearch}
              onChange={setSelectedStudent}
              value={selectedStudent}
            >
              {filteredStudents.map((student) => (
                <AutoComplete.Option key={student.id} value={student.Adm}>
                  {student.Name}
                </AutoComplete.Option>
              ))}
            </AutoComplete>
          </Col>

          <Col span={12}>
            <label htmlFor="class">Class</label>
            <Select
              placeholder="Select Class"
              style={{ width: "100%" }}
              onChange={setSelectedClass}
              value={selectedClass}
            >
              <Option value="">All</Option>
              {classes.map((classItem) => (
                <Option key={classItem} value={classItem}>
                  {classItem}
                </Option>
              ))}
            </Select>
          </Col>

          <Col span={12}>
            <Button
              type="primary"
              onClick={generateReport}
              loading={busy}
              disabled={!token || busy}
              style={{ width: "100%", marginTop: "26px" }}
            >
              {text}
            </Button>
          </Col>
        </Row>
      </div>

      <hr />
      {error && <Alert message={error} type="error" showIcon />}
      <Spin spinning={busy}>
        {summaryData.length > 0 ? (
          <div
            style={{
              width: "80%",
              margin: "auto",
              marginTop: "40px",
              overflowX: "auto",
            }}
          >
            <Row justify="end" style={{ marginBottom: 20 }}>
              <Col>
                <Button
                  type="primary"
                  onClick={() =>
                    exportToExcel(summaryData, "StudentTypeSummary")
                  }
                  style={{ marginRight: 10 }}
                >
                  Export to Excel
                </Button>
                <Button
                  type="primary"
                  onClick={() => exportToCSV(summaryData, "StudentTypeSummary")}
                  style={{ marginRight: 10 }}
                >
                  Export to CSV
                </Button>
                <Button
                  type="primary"
                  onClick={() =>
                    exportToPDF(
                      summaryColumns,
                      summaryData,
                      "StudentTypeSummary",
                      "Student Type Summary Report",
                      [0, 175, 80],
                      "/img/go-green.png"
                    )
                  }
                >
                  Export to PDF
                </Button>
              </Col>
            </Row>
            <Table
              columns={summaryColumns}
              dataSource={summaryData}
              pagination={false}
              rowKey="type"
            />
          </div>
        ) : (
          <div style={{ textAlign: "center", marginTop: 40 }}>
            <Empty
              description="No data available. Please generate a report."
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          </div>
        )}
      </Spin>
    </div>
  );
};

export default StudentTypeSummary;
