import React from "react";
import { get2, select } from "../../../utils/Storage";
import Ant, { Icon } from "antd";
import ProviderAccountForm from "./ProviderAccountForm";
import "react-tabulator/lib/styles.css";
import _ from "linq";
import Axios from "axios";
import goAPI from "../../../Server/Go";

class ProviderList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      providers: goAPI.getUser().isAdmin()
        ? select("providers")
        : _.from([goAPI.getUser().getProvider()]),
      provider: null,
      showManager: false,
      search: null,
      permissions: get2("permissions"),
    };
  }

  SaveProvider = (e) => {
    // Save Provider
    e.preventDefault();

    Ant.message.loading(null, 0);

    // Validate
    let { provider } = this.state;
    if (provider.Name && provider.Name.toString().trim().length === 0) {
      Ant.message.error("Provider Name is required");
      return;
    }

    // validate optional fields
    if (provider.Address === "") provider.Address = null;
    if (provider.Phone === "") provider.Phone = null;
    if (provider.Email === "") provider.Email = null;

    const saveAction = provider.id === -1 ? Axios.post : Axios.patch;
    const route = provider.id === -1 ? "providers" : `providers/${provider.id}`;

    //

    // use axios to post data to providers endpoint
    saveAction(route, provider)
      .then((response) => {
        // Update Provider List

        let { providers } = this.state;
        if (provider.id === -1) {
          // Add Provider
          provider.id = response.data.id;
          providers.push(provider);
        } else {
          // Update Provider
          let index = _.from(providers).indexOf((p) => p.id === provider.id);

          providers[index] = provider;
        }

        // W
        this.setState({ provider: null, providers: providers });

        Ant.notification.success({
          message: "Provider Saved",
          description: "Provider has been saved successfully",
        });
      })
      .catch((error) => {
        console.log(error);
        Ant.notification.error({
          message: "Error",
          description: "An error occurred while saving provider",
        });
      })
      .finally(() => {
        Ant.message.destroy();
      });
  };

  handleProviderChange = (e) => {
    let { provider } = this.state;
    provider[e.target.name] = e.target.value;
    this.setState({ provider: provider });
  };

  render() {
    let { showManager, provider, providers, loading } = this.state;

    const { Text } = Ant.Typography;
    //  Handle Search
    providers = providers
      .where(
        (provider) =>
          this.state.search === null ||
          provider.Name.toLowerCase().includes(this.state.search.toLowerCase())
      )
      .select((provider) => {
        if (provider) provider.color = window.getColor(provider.Name);
        return provider ? provider : {};
      });

    if (provider && provider.id === -1) providers = _.from([this.state.provider]);

    if (loading) setTimeout(() => this.setState({ loading: false }), 1500);

    return (
      <>
        {/*DIALOGS AND DRAWERS*/}
        <Ant.Modal
          visible={showManager}
          footer={null}
          onCancel={() => this.setState({ showManager: false })}
          okText={provider === null ? "Add" : "Save"}
          width={"60%"}
          closable={true}
          maskClosable={false}
          title={this.state.provider === null ? "Add Provider" : "Edit " + this.state.provider.Name}
        >
          <ProviderAccountForm provider={provider} />
          <br />
          <br />
        </Ant.Modal>

        <div style={{ background: "#F0F2F5", padding: "30px" }}>
          <Ant.Row gutter={16} style={{ width: "100%" }}>
            {!provider && (
              <>
                <Ant.Col span={6} style={{ marginTop: 20, marginBottom: 50 }}>
                  <Ant.Input.Search
                    placeholder="Search Providers..."
                    enterButton
                    onSearch={(value) => this.setState({ search: value, loading: true })}
                  />
                </Ant.Col>
                {this.state.permissions.get(28) && this.state.permissions.get(28).can_add == 1 ? (
                  <Ant.Col span={1} style={{ marginTop: 20, marginBottom: 50 }}>
                    <Ant.Button
                      icon="plus"
                      type={"primary"}
                      onClick={() =>
                        this.setState({
                          provider: {
                            id: -1,
                            Name: "",
                            Address: "",
                            Phone: "",
                            Email: "",
                          },
                        })
                      }
                    />
                  </Ant.Col>
                ) : null}

                <Ant.Col span={13} />
                <Ant.Col align={"right"} span={4}>
                  <Ant.Radio.Group>
                    <Ant.Button icon="sync" />
                    <Ant.Button icon="download" />
                  </Ant.Radio.Group>
                </Ant.Col>
                <Ant.Divider />
              </>
            )}

            <Ant.List
              // loading={loading}
              style={this.state.provider && { marginLeft: "30%", marginRight: "30%" }}
              grid={{ gutter: 16, column: this.state.provider ? 1 : 3 }}
              dataSource={providers.where((r) =>
                this.state.provider ? r.id === this.state.provider.id : true
              )}
              renderItem={(provider) => {
                let selProvider = this.state.provider;
                let isSelected = selProvider && selProvider.id === provider.id;

                let isDisabled = selProvider && selProvider.id !== provider.id;

                return (
                  <>
                    <Ant.List.Item>
                      <Ant.Card
                        bordered={false}
                        loading={loading}
                        className={"shadow"}
                        cover={
                          <div
                            align={"center"}
                            style={{
                              height: 200,
                              background: `radial-gradient(circle, ${provider.color}  30%, rgb(0, 175, 80) 130%)`,
                              backgroundBlendMode: "multiply",
                              backgroundColor: provider.color,
                            }}
                          >
                            <br />

                            <h4 style={{ marginTop: 30, color: "#00AF50" }}>
                              {loading || (
                                <img
                                  style={{ color: "#00AF50" }}
                                  src={"./img/bus.png"}
                                  width={70}
                                />
                              )}

                              {loading ? (
                                <Icon style={{ color: "#ECEEF1" }} type="loading" />
                              ) : (
                                <>
                                  <br />
                                  <Text
                                    className={"text-shadow"}
                                    style={{
                                      color: "#ECEEF1",
                                      textShadow: `2px 2px ${provider.color}`,
                                    }}
                                  >
                                    {provider.Name}
                                  </Text>
                                </>
                              )}
                            </h4>
                          </div>
                        }
                        actions={[
                          <Ant.Tooltip title={"Edit"}>
                            {isSelected ? (
                              // Show Save Button ✔️
                              <>
                                <Ant.Tooltip title={"Cancel & Go Back"}>
                                  <Ant.Icon
                                    type="close"
                                    onClick={() => this.setState({ provider: null })}
                                  />
                                </Ant.Tooltip>
                              </>
                            ) : (
                              <>
                                {this.state.permissions.get(28) &&
                                this.state.permissions.get(28).can_modify == 1 ? (
                                  <Ant.Tooltip title={"Edit Record"}>
                                    <Ant.Icon
                                      theme={"filled"}
                                      onClick={() => this.setState({ provider: provider })}
                                      type="edit"
                                    />
                                  </Ant.Tooltip>
                                ) : null}
                              </>
                            )}
                          </Ant.Tooltip>,
                          <>
                            {isSelected ? (
                              // Show Save Button ✔️
                              <>
                                <Ant.Tooltip title={"Save Changes"}>
                                  <Ant.Button
                                    type={"primary"}
                                    shape={"round"}
                                    onClick={this.SaveProvider}
                                    style={{
                                      backgroundColor: provider.color,
                                      borderColor: provider.color,
                                    }}
                                  >
                                    <Ant.Icon type="check" key="arrow-right" />
                                  </Ant.Button>
                                </Ant.Tooltip>
                              </>
                            ) : (
                              // Show Open Profile Button
                              <>
                                {/*<Ant.Tooltip title={"View"}>*/}
                                {/*    <Ant.Button*/}
                                {/*        type={"primary"}*/}
                                {/*        shape={"round"}*/}
                                {/*        style={{*/}
                                {/*            backgroundColor: provider.color,*/}
                                {/*            borderColor: provider.color*/}
                                {/*        }}>*/}
                                {/*        <a*/}
                                {/*            style={{color: "white"}}*/}
                                {/*            onClick={() => Njia.Anchor(`provider?id=${provider.id}`)}*/}
                                {/*        >*/}
                                {/*            <Ant.Icon type="arrow-right"/>*/}
                                {/*        </a>*/}

                                {/*    </Ant.Button>*/}
                                {/*</Ant.Tooltip>*/}
                              </>
                            )}
                          </>,
                        ]}
                      >
                        <Ant.Card.Meta
                          avatar={
                            <Ant.Avatar style={{ backgroundColor: provider.color }}>
                              {" "}
                              {isSelected ? (
                                <Ant.Icon
                                  type="arrow-left"
                                  onClick={() => this.setState({ provider: null })}
                                />
                              ) : (
                                <Ant.Icon type="car" />
                              )}
                            </Ant.Avatar>
                          }
                          title={
                            isSelected ? (
                              <>
                                <Ant.Input
                                  name={"Name"}
                                  defaultValue={provider.Name}
                                  onChange={this.handleProviderChange}
                                />
                              </>
                            ) : (
                              <>{provider.Name}</>
                            )
                          }
                          description={
                            <>
                              <Ant.Divider dashed={true} />
                              <Ant.Icon type="mail" style={{ color: provider.color }} />{" "}
                              &nbsp;&nbsp;
                              {isSelected ? (
                                <>
                                  <Ant.Input
                                    type={"email"}
                                    required={true}
                                    name={"Email"}
                                    defaultValue={provider.Email}
                                    placeholder={"Enter Email Address"}
                                    style={{ width: "90%" }}
                                    onChange={this.handleProviderChange}
                                  />
                                </>
                              ) : (
                                <>
                                  <Text type="secondary"> {provider.Email || "(None)"} </Text>
                                </>
                              )}
                              <Ant.Divider dashed={true} />
                              <Ant.Icon type="phone" style={{ color: provider.color }} />{" "}
                              &nbsp;&nbsp;
                              {isSelected ? (
                                <>
                                  <Ant.Input
                                    type={"tel"}
                                    name={"Phone"}
                                    defaultValue={provider.Phone}
                                    placeholder={"Enter Phone Number"}
                                    style={{ width: "90%" }}
                                    onChange={this.handleProviderChange}
                                  />
                                </>
                              ) : (
                                <>
                                  <Text type="secondary"> {provider.Phone || "(None)"} </Text>
                                </>
                              )}
                              <Ant.Divider dashed={true} />
                              <Ant.Icon type="home" style={{ color: provider.color }} />{" "}
                              &nbsp;&nbsp;
                              {isSelected ? (
                                <>
                                  <Ant.Input
                                    name={"Address"}
                                    defaultValue={provider.Address}
                                    placeholder={"Enter Address"}
                                    style={{ width: "90%" }}
                                    onChange={this.handleProviderChange}
                                  />
                                </>
                              ) : (
                                <>
                                  <Text type="secondary"> {provider.Address || "(None)"} </Text>
                                </>
                              )}
                            </>
                          }
                        />
                      </Ant.Card>
                    </Ant.List.Item>
                  </>
                );
              }}
            ></Ant.List>
          </Ant.Row>
        </div>
      </>
    );
  }
}

export default ProviderList;
