import React, { Component } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Collapse,
  Divider,
  Drawer,
  Icon,
  Input,
  Layout,
  message,
  Radio,
  Row,
  Select,
  Tabs,
  Tag,
  TreeSelect,
} from "antd";
import { get, get2, put, select } from "../../../utils/Storage";
import axios from "axios";
import auth from "../../../Components/Auth";
import ScheduleCard from "./ScheduleCard";
import Schedule from "./Schedule";
import _ from "linq";
import moment from "moment";
import { Param } from "../../../utils/Routing";
import goAPI from "../../../Server/Go";

class Schedules extends Component {
  state = {
    name: "Schedules",
    schedules: [],
    vehicles: goAPI.getUser().isAdmin()
      ? select("vehicles")
      : goAPI.getUser().getProvider()?.getVehicles(),
    direction: "",
    vehicle: "",
    route: [],
    selRoutes: [],
    search: "",
    edit: null,
    loading: false,
    filterRoutes: "",
    year: null,
    permissions: get2("permissions"),
  };

  componentWillMount() {
    let _schedules = _.from(get("schedules"));

    if (this.props.onFilter) _schedules = _schedules.where((s) => this.props.onFilter(s));

    this.setState({
      schedules: _schedules.toArray(),
    });
  }

  LoadData() {
    this.setState({ loading: true });
    axios
      .get("/m/schedule")
      .then((res) => {
        // res.data
        put("schedules", res.data);

        let _schedules = _.from(res.data);

        if (this.props.onFilter) _schedules = _schedules.where((s) => this.props.onFilter(s));

        this.setState({
          schedules: _schedules.toArray(),
          loading: false,
        });
      })
      .catch((error) => {
        message.error(error["message"]);
      });
  }

  componentDidMount() {
    this.LoadData();

    if (Param("id") && this.state.schedules) {
      let id = Param("id");
      //   window.location.href = "#/schedules";
      let schedule = _.from(this.state.schedules).first((s) => s.id == id);
      if (schedule) {
        this.setState({
          edit: schedule,
        });
      }
    }
  }

  render() {
    let years = _.from(this.state.schedules)
      .select((s) => parseInt(s.Year.toString()))
      .distinct()
      .orderByDescending((s) => s);

    if (!this.state.year) this.state.year = years.any() ? years.first() : moment().year();

    let _routes = get("routes");
    let _routesMap = get("routes-map");
    let _stations = get("stations");

    let vehicles = get("vehicles-map");
    let drivers = get("drivers-map");
    let users = get("users-map");
    let providers = get("providers-map");

    let routeData = [];

    for (let i = 0; i < _routes.length; i++) {
      let route = _routes[i];

      routeData.push({
        key: route.id,
        title: "⛳ " + window.TitleCase(route.Name) + " Route",
        value: route.id,
      });
    }

    _routes = {
      onChange: (value) => this.setState({ route: value, selRoutes: value }),
      searchPlaceholder: `⚡• Showing all Routes [${_routes.length}]`,
      allowClear: true,
      style: { width: "100%" },
      treeCheckable: true,
      treeData: routeData,
      value: this.state.route,
    };

    const operations = (
      <span>
        <Button onClick={() => this.LoadData()} shape="circle" size={"small"}>
          <Icon type="sync" spin={this.state.loading} />
        </Button>
        &nbsp;
        {this.state.permissions.get(14) && this.state.permissions.get(14).can_add == 1 ? (
          <Button
            hidden={auth.noInsert(auth.Schedulles) || moment().year() != this.state.year}
            onClick={() => {
              let newSchedule = {
                id: -1,
                Name: "",
                RouteId: this.state.selRoutes.length > 0 ? this.state.selRoutes[0] : "",
                VehicleId: this.state.vehicle !== "" ? this.state.vehicle : "",
                DriverId: "",
                AttendantId: "",
                StartTime: "",
                EndTime: "",
                Direction: this.state.direction,
              };

              this.setState({
                edit: newSchedule,
              });
            }}
            type="primary"
            shape="round"
            icon="plus"
            size={"small"}
          >
            Add Schedule
          </Button>
        ) : null}
      </span>
    );

    let schedules = _.from(this.state.schedules)
      .where(
        (sc) =>
          goAPI.getUser().isAdmin() ||
          goAPI
            .getUser()
            .getProvider()
            ?.getSchedules()
            .any((sc1) => sc1.id == sc.id)
      )
      .where((schedule) => schedule.Year == this.state.year)
      .orderByDescending((schedule) => parseInt(schedule.id))
      .toArray();

    schedules = schedules
      .filter((r) => r.Direction.toString().includes(this.state.direction))
      .filter((r) =>
        this.state.vehicle == "" ? true : r.VehicleId.toString() === this.state.vehicle.toString()
      )
      .filter((schedule) => {
        let routeIds = this.state.selRoutes;

        if (routeIds.length == 0) return true;

        return _.from(routeIds).any((routeId) => schedule.RouteId == routeId);
      })
      .filter(
        (r) =>
          r.Name.toLocaleLowerCase()
            .trim()
            .includes(this.state.search.toLocaleLowerCase().trim()) ||
          users[r.AttendantId].name
            .toLocaleLowerCase()
            .trim()
            .includes(this.state.search.toLocaleLowerCase().trim()) ||
          users[r.DriverId].name
            .toLocaleLowerCase()
            .trim()
            .includes(this.state.search.toLocaleLowerCase().trim())
      );

    let schedulesPerVehicle = [];
    schedulesPerVehicle = Object.values(
      schedules.reduce(function (r, a) {
        r[a.VehicleId] = r[a.VehicleId] || [];
        r[a.VehicleId].push(a);
        return r;
      }, Object.create(null))
    );

    if (this.state.edit) {
      return (
        <Drawer
          title={this.state.edit.id > 0 ? `📆 ${this.state.edit.Name}` : "➕ Add Schedule"}
          visible={true}
          onClose={() => this.setState({ edit: null })}
          footer={null}
          width={"91%"}
          closable={true}
          maskClosable={false}
        >
          <Schedule
            dataSource={this.state.edit}
            onSave={() => {
              this.setState({ edit: null });
              this.LoadData();
            }}
          />
        </Drawer>
      );
    }

    const that = this;

    const _vehicles = _.from(get("vehicles"));
    const _users = _.from(get("users"));

    return (
      <Layout.Content>
        <Card bordered={false} style={{ marginTop: -15 }}>
          <Tabs
            activeKey={this.state.year.toString()}
            tabBarExtraContent={operations}
            onChange={(key) => this.setState({ year: parseInt(key) })}
          >
            {years.select((y) => (
              <Tabs.TabPane
                tab={
                  <span>
                    <Icon
                      theme={this.state.year == y ? "filled" : "outlined"}
                      type={y == moment().year() ? "clock-circle" : "lock"}
                    />
                    Schedules • {y}
                    &nbsp; &nbsp;
                    {this.state.year != y && (
                      <Tag color="cyan">
                        <Icon type="lock" theme={this.state.year == y ? "filled" : "outlined"} />
                        Archived
                      </Tag>
                    )}
                  </span>
                }
                key={y.toString()}
              />
            ))}
          </Tabs>
          <Card bordered={false} loading={false}>
            <Row gutter={24}>
              <Col span={10}>
                <Input.Search
                  style={{ width: "100%" }}
                  defaultValue={this.state.search}
                  onChange={(e) => this.setState({ search: e.target.value })}
                  placeholder="👩‍💼 • Search  Name / Phone / Email"
                  enterButton={true}
                />
              </Col>
              <Col span={4}> </Col>
              <Col span={10} align={"right"}>
                <Radio.Group
                  defaultValue={""}
                  style={{ width: "100%" }}
                  buttonStyle="solid"
                  onChange={(e) => this.setState({ direction: e.target.value })}
                >
                  <Radio.Button value="">⛳ &nbsp;&nbsp; All Directions</Radio.Button>
                  <Radio.Button value="To School">🔺 To School</Radio.Button>
                  <Radio.Button value="From School">🔻 From School</Radio.Button>
                </Radio.Group>
              </Col>

              {/*<Select*/}
              {/*    style={{width: "100%"}}*/}
              {/*    defaultValue=""*/}
              {/*    onSelect={(val) => this.setState({direction: val})}*/}
              {/*>*/}
              {/*    <Select.Option value="">📌 • All Directions</Select.Option>*/}
              {/*    <Select.Option value="To School">🔺 To School</Select.Option>*/}
              {/*    <Select.Option value="From School">*/}
              {/*        🔻 From School*/}
              {/*    </Select.Option>*/}
              {/*</Select>*/}
              {/*&nbsp;*/}
              {/*<Select*/}
              {/*    defaultValue=""*/}
              {/*    style={{width: 100}}*/}
              {/*    onSelect={(val) => this.setState({order: val})}*/}
              {/*>*/}
              {/*    <Select.Option value="">All Trips</Select.Option>*/}
              {/*    <Select.Option value={0}>Trip 1</Select.Option>*/}
              {/*    <Select.Option value={1}>Trip 2</Select.Option>*/}
              {/*    <Select.Option value={2}>Trip 3</Select.Option>*/}
              {/*    <Select.Option value={3}>Trip 4</Select.Option>*/}
              {/*    <Select.Option value={4}>Trip 5</Select.Option>*/}
              {/*</Select>*/}
              {/*&nbsp;*/}
              {/*<Select*/}
              {/*    defaultValue={"Active"}*/}
              {/*    style={{width: 100}}*/}
              {/*    onSelect={(val) => this.setState({status: val})}*/}
              {/*>*/}
              {/*    <Select.Option value={"Active"}>Active</Select.Option>*/}
              {/*    <Select.Option value={"Draft"}>Draft</Select.Option>*/}
              {/*    <Select.Option value={"Demo"}>Demo</Select.Option>*/}
              {/*    <Select.Option value={"Archived"}>Archived</Select.Option>*/}
              {/*</Select>*/}
            </Row>
            <Divider dashed={true} />
            <Row gutter={24}>
              <Col span={10}>
                <TreeSelect {..._routes} />
              </Col>
              <Col span={4}></Col>
              <Col span={10} align={"right"}>
                <Select
                  style={{ width: "100%" }}
                  showSearch
                  optionFilterProp="children"
                  onChange={(val) => {
                    this.setState({ vehicle: val });
                    this.forceUpdate();
                  }}
                  defaultValue=""
                >
                  <Select.Option value=""> 🚌 • All Vehicles</Select.Option>
                  {_.from(this.state.schedules)
                    .select((schedule) =>
                      _.from(this.state.vehicles)
                        .where((veh) => veh?.id === schedule.VehicleId)
                        .firstOrDefault()
                    )
                    .groupBy((veh) => veh?.id)
                    .select((veh) => veh.firstOrDefault())
                    .select((veh) => (
                      <Select.Option key={veh?.id} value={veh?.id}>
                        {" • 🚌 " + veh?.PlateNo + "-" + veh?.Model + " • 🏠 " + veh?.Provider}
                      </Select.Option>
                    ))
                    .toArray()}
                </Select>
              </Col>
            </Row>
            <Divider dashed={true} />
            <Card bordered={false} loading={this.state.schedules.length == 0}>
              <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 20]} style={{ width: "100%" }}>
                {schedulesPerVehicle.map((vehicleSchedules) => {
                  return (
                    <Col span={that.props.onFilter ? 24 : 24}>
                      <ScheduleCard
                        rating={false}
                        compact={true}
                        readOnly={this.state.year != moment().year()}
                        schedules={vehicleSchedules}
                      />
                    </Col>
                  );
                })}
              </Row>
            </Card>

            <Card hidden={true} bordered={false} loading={this.state.schedules.length == 0}>
              <Collapse
                expandIcon={({ isActive }) => (
                  <Icon
                    style={{ color: "#00AF50" }}
                    type="caret-right"
                    rotate={isActive ? 90 : 0}
                  />
                )}
                style={{ marginLeft: -30 }}
                accordion
              >
                {schedules.map(
                  function (data, idx) {
                    if (data.id >= 0) {
                      return (
                        <Collapse.Panel
                          extra={
                            <Badge
                              style={{
                                backgroundColor:
                                  data.Direction === "To School" ? "#00AF50" : "#67686E",
                              }}
                              count={data.Direction}
                            />
                          }
                          header={
                            data.id +
                            ":   " +
                            data.Direction +
                            " - " +
                            data.Route +
                            " - " +
                            data.Name +
                            " - " + //todo:
                            (_vehicles.firstOrDefault((r) => r.id == data.VehicleId) &&
                              _vehicles.firstOrDefault((r) => r.id == data.VehicleId).Model) +
                            " (" +
                            (_vehicles.firstOrDefault((r) => r.id == data.VehicleId) &&
                              _vehicles.firstOrDefault((r) => r.id == data.VehicleId).PlateNo) +
                            ") Driver: " +
                            (_users.firstOrDefault((r) => r.id == data.DriverId) &&
                              _users.firstOrDefault((r) => r.id == data.DriverId).name) +
                            ", Agent (" +
                            (_users.firstOrDefault((r) => r.id == data.AttendantId) &&
                              _users.firstOrDefault((r) => r.id == data.AttendantId).name) +
                            ")"
                          }
                          key={data.id}
                        >
                          <Schedule dataSource={data} onSave={() => this.LoadData()} />
                        </Collapse.Panel>
                      );
                    }
                  }.bind(this)
                )}
              </Collapse>
            </Card>
          </Card>
        </Card>
      </Layout.Content>
    );
  }
}

export default Schedules;
