import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Input,
  Alert,
  Empty,
  Select,
  Spin,
  Col,
  Row,
} from "antd";
import axios from "axios";
import {
  exportToExcel,
  exportToCSV,
  exportToPDF,
} from "../../utils/generateReports";
import "antd/dist/antd.css";

function VehicleEarnings() {
  const [metric, setMetric] = useState("Schedules");
  const [percentage, setPercentage] = useState(12);
  const [tranche, setTranche] = useState(3);
  const [minTrips, setMinTrips] = useState(5);
  const [results, setResults] = useState([]);
  const [token, setToken] = useState("");
  const [startDate, setStartDate] = useState(
    new Date().toLocaleDateString("en-CA") // Ensures 'YYYY-MM-DD' format in local time
  );
  const [endDate, setEndDate] = useState(
    new Date().toLocaleDateString("en-CA")
  );

  const [baseUrl, setBaseUrl] = useState("");
  const [busy, setBusy] = useState(false);
  const [error, setError] = useState(null);

  const { Option } = Select;

  useEffect(() => {
    const account = JSON.parse(localStorage.getItem("account"));
    const BaseUrl = localStorage.getItem("api");
    if (account && account.access_token) {
      setToken(account.access_token);
    } else {
      setError("Missing authentication token. Please log in again.");
    }
    if (BaseUrl) {
      setBaseUrl(BaseUrl);
    } else {
      setError("Missing base URL. Please configure your settings.");
    }
  }, []);

  const calculateTotals = (data) => {
    let totalCost = 0;
    let adminCost = 0;
    let netTotal = 0;
    let trips = 0;

    const uniqueVehicles = new Set();

    data.forEach((item) => {
      totalCost += item.Cost || 0;
      adminCost += item.AdminCost || 0;
      netTotal += item.NetTotal || 0;
      trips += item.Trips || 0;

      uniqueVehicles.add(item.Vehicle);
    });
    return {
      Cost: totalCost,
      AdminCost: adminCost,
      NetTotal: netTotal,
      Trips: trips,
      Vehicle: `${uniqueVehicles.size} Unique Vehicles`,
    };
  };

  const generateReport = async () => {
    if (!token) {
      setError("Missing authentication token. Please log in again.");
      return;
    }
    setBusy(true);
    setError(null);

    try {
      const url = `${baseUrl}/reports/summaries/earnings?Metric=${metric}&MinTrips=${minTrips}&Percentage=${percentage}&Tranche=${tranche}&StartDate=${startDate}&EndDate=${endDate}`;
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const { data } = await axios.get(url, { headers });

      const totalRow = calculateTotals(data);
      setResults([...data, totalRow]); // Append the total row at the end
    } catch (e) {
      console.error(e.message);
    } finally {
      setBusy(false);
    }
  };

  const columns = [
    { title: "Vehicle", dataIndex: "Vehicle", key: "vehicle" },
    {
      title: "Per Vehicle Cost Total",
      dataIndex: "Cost",
      key: "totalCost",
      render: (text) => (Number.isFinite(text) ? text.toLocaleString() : "0"),
    },
    {
      title: `Admin Cost (${percentage}%)`,
      dataIndex: "AdminCost",
      key: "adminCost",
      render: (text) => (Number.isFinite(text) ? text.toLocaleString() : "0"),
    },
    {
      title: "Net Total Less Admin Amount (KES)",
      dataIndex: "NetTotal",
      key: "netTotal",
      render: (text) => (Number.isFinite(text) ? text.toLocaleString() : "0"),
    },
    {
      title: "Trips",
      dataIndex: "Trips",
      key: "totalTrips",
      render: (text) => (Number.isFinite(text) ? text.toLocaleString() : "0"),
    },
  ];

  return (
    <div style={{ marginTop: 30 }}>
      <h4 style={{ textAlign: "center", marginBottom: 20 }}>
        Vehicle Earnings
      </h4>
      <div style={{ width: "50%", margin: "0 auto", marginTop: 30 }}>
        <Row gutter={[16, 16]} style={{ marginBottom: "40px" }}>
          <Col span={12}>
            <label htmlFor="datePicker" style={{ paddingRight: 10 }}>
              StartDate
            </label>
            <Input
              type="date"
              cursor="pointer"
              size="large"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              style={{ width: "100%" }}
            />
          </Col>

          <Col span={12}>
            <label htmlFor="datePicker" style={{ paddingRight: 10 }}>
              EndDate
            </label>
            <Input
              type="date"
              cursor="pointer"
              size="large"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              style={{ width: "100%" }}
            />
          </Col>
          <Col span={12}>
            <label htmlFor="metricPicker" style={{ paddingRight: 10 }}>
              Metrics
            </label>
            <Select
              id="metricPicker"
              value={metric}
              onChange={(value) => setMetric(value)}
              size="large"
              style={{ width: "100%" }}
            >
              <Option value="Schedules">Schedules</Option>
              <Option value="Trips">Trips</Option>
            </Select>
          </Col>

          <Col span={12}>
            <label htmlFor="percentageInput" style={{ paddingRight: 10 }}>
              Percentage
            </label>
            <Input
              id="percentageInput"
              type="number"
              value={percentage}
              onChange={(e) => setPercentage(e.target.value)}
              size="large"
              style={{ width: "100%" }}
            />
          </Col>

          <Col span={12}>
            <label htmlFor="minTripsInput" style={{ paddingRight: 10 }}>
              Min Trips
            </label>
            <Input
              id="minTripsInput"
              type="number"
              value={minTrips}
              onChange={(e) => setMinTrips(e.target.value)}
              size="large"
              style={{ width: "100%" }}
            />
          </Col>

          <Col span={12}>
            <label htmlFor="trancheInput" style={{ paddingRight: 10 }}>
              Tranche
            </label>
            <Input
              id="trancheInput"
              type="number"
              value={tranche}
              onChange={(e) => setTranche(e.target.value)}
              size="large"
              style={{ width: "100%" }}
            />
          </Col>

          <Col span={12}>
            <Button
              type="primary"
              size="large"
              onClick={generateReport}
              loading={busy}
              style={{ width: "100%", marginTop: "26px" }}
              disabled={!token || busy}
            >
              Generate Report
            </Button>
          </Col>
        </Row>
      </div>
      <hr />
      {error && <Alert message={error} type="error" showIcon />}
      <Spin spinning={busy}>
        {results.length > 0 ? (
          <div
            style={{
              width: "80%",
              margin: "auto",
              marginTop: "40px",
              overflowX: "auto",
            }}
          >
            <Row justify="end" style={{ marginBottom: 20 }}>
              <Col>
                <Button
                  type="primary"
                  onClick={() =>
                    exportToExcel(results, "VehicleSummaryEarnings")
                  }
                  style={{ marginRight: 10 }}
                >
                  Export to Excel
                </Button>
                <Button
                  type="primary"
                  onClick={() => exportToCSV(results, "VehicleSummaryEarnings")}
                  style={{ marginRight: 10 }}
                >
                  Export to CSV
                </Button>
                <Button
                  type="primary"
                  onClick={() =>
                    exportToPDF(
                      columns,
                      results,
                      "VehicleSummaryEarnings",
                      "All Vehicle Summary Earnings",
                      [0, 175, 80],
                      "/img/go-green.png",
                      startDate,
                      endDate
                    )
                  }
                >
                  Export to PDF
                </Button>
              </Col>
            </Row>
            <Table
              columns={columns}
              dataSource={results}
              scroll={{ x: "max-content" }}
              pagination={false}
              bordered
              style={{ marginTop: 16, minWidth: 1200 }}
            />
          </div>
        ) : (
          <div style={{ textAlign: "center", marginTop: 40 }}>
            <Empty
              description="No data available. Please generate a report."
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          </div>
        )}
      </Spin>
    </div>
  );
}

export default VehicleEarnings;
