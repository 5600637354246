import {Button, Card, Divider, Input, message, PageHeader, Popconfirm, Switch, Table, Tag} from "antd";
import {linq, put} from "../../../utils/Storage";
import GoSp from "../../../Components/GoSp";
import React, {useReducer, useState} from "react";
import _ from "linq";
import axios from "axios";

export default function Student_Add_Schedules(props) {
    // states
    const [, forceUpdate] = useReducer(x => x + 1, 0);

    const [vehicles] = useState(linq("vehicles"));
    const [users] = useState(linq("users"));
    const [providers] = useState(linq("providers"));
    const [routes] = useState(linq("routes"));

    const [student, setStudent] = useState(props.student);
    const [schedules, setSchedules] = useState(linq("schedules").where(s => s.Year == window.year));

    const [isToSchool, setIsToSchool] = useState(true);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(-1);

    const on_Add_Student_To_Schedule = (schedule) => {
        let scheduleId = schedule.id;
        setLoading(scheduleId);
        forceUpdate()

        let _schedule = linq("schedules")
            .firstOrDefault(r => r.id.toString() === scheduleId.toString());


        if (!_schedule) return;


        message.loading("Adding Student to Schedule", 0);

        _schedule.students = linq("students")
            .where(s => _
                .from(s.schedules)
                .any(s2 => s2.id.toString() === scheduleId.toString()))
            .select(s => {
                return {
                    id: s.id
                }
            })
            .toArray();

        _schedule.students.push({id: student.id})
        axios.patch("/schedule", _schedule)
            .then((response) => {
                let _student = student;
                _student.schedules.push(
                    linq("schedules")
                        .first(r => r.id.toString() === scheduleId.toString())
                );


                put("students",
                    linq("students")
                        .select(r => {
                            if (r.id.toString() === _student.id.toString())
                                return _student;

                            return r;
                        })
                        .toArray()
                );

                console.log(_student);
                setStudent(_student);
                forceUpdate();
                message.success("Successful 👍");
            })
            .catch((error) => {
                message.error(error["message"]);
            })
            .finally(() => message.destroy());


        // Done
        setLoading(-1);
    }


    // Filter
    let data = schedules
        .where(s => s.Direction.toString() === (isToSchool ? "To School" : "From School"))
        .select(s => {
            // Load vehicle
            s.Vehicle = vehicles.firstOrDefault(v => v.id == s.VehicleId);
            s.Driver = users.firstOrDefault(d => d.id == s.DriverId);
            s.Attendant = users.firstOrDefault(a => a.id == s.AttendantId);


            //  is student in schedule
            s.isStudentInSchedule = _
                .from(student.schedules)
                .any(s2 => s2.id == s.id);

            /** Templates **/

            // Add
            s.colAdd = s.isStudentInSchedule
                // Already in schedule
                ? <Popconfirm onConfirm={() => on_Add_Student_To_Schedule(s)}
                              okText={`Add ${window.TitleCase(s.Name)} `}
                              title={`Add ${window.TitleCase(student.Name)} to Schedule`}>
                    <Button
                        size={"small"}
                        shape={"round"}
                        icon={"check"}
                        type={"primary"}
                        disabled={true}/>

                </Popconfirm>

                // Not in schedule
                : <Popconfirm onConfirm={() => on_Add_Student_To_Schedule(s)}
                              okText={`Add ${window.TitleCase(s.Name)} `}
                              title={`Add ${window.TitleCase(student.Name)} to Schedule`}>
                    <Button loading={loading.toString() === s.id.toString()}
                            size={"small"}
                            shape={"round"}
                            icon={"plus"}
                            disabled={loading.toString() !== s.id.toString() && loading >= 0}
                            type={"primary"}/>
                </Popconfirm>

            // Name
            s.colName = <a href={`#/schedules?id=${s.id}`} target={"_blank"}>
                <Button
                    type={"link"}
                    style={{color: (s.isStudentInSchedule && "#5E7880")}}>
                    <b> {window.TitleCase(s.Name)}</b>
                </Button>
            </a>

            // Vehicle
            s.colVehicle = s.Vehicle && <a href={`#/m-vehicle?id=${s.Vehicle.id}`} target={"_blank"}>
                <Button icon={"car"} type={"link"} style={{color: "darkorange"}}><b>
                    <GoSp/>
                    {s.Vehicle.PlateNo} • {s.Vehicle.Provider}
                </b></Button>
            </a>
            s.colRoute = <Tag color="gray">{s.Route}</Tag>

            s.colDriver = s.Driver && <a href={`#/m-user?id=${s.Driver.id}`} target={"_blank"}>
                <Button icon={"user"} type={"link"} style={{color: "purple"}}><b>
                    <GoSp/> {s.Driver.name} • {s.Driver.phone}
                </b></Button>
            </a>

            s.colAttendant = s.Attendant && <a href={`#/m-user?id=${s.Attendant.id}`} target={"_blank"}>
                <Button icon={"user"} type={"link"} style={{color: "slateblue"}}><b>
                    <GoSp/> {s.Attendant.name} • {s.Attendant.phone}
                </b></Button>
            </a>

            return s;
        });

    // Search
    if (search && search.trim().length > 0) {
        data = data
            .where(
                s => {
                    const queries = _.from(search.trim().toLowerCase().split('+'));
                    let searchables = _
                        .from(
                            [
                                s.Name,
                                s.Route,

                                s.Vehicle.PlateNo,

                                s.Attendant.name,
                                //s.Attendant.email,

                                s.Driver.name,
                                //  s.Driver.email
                            ]
                        );

                    console.log(searchables.toArray())

                    let match = true;

                    queries
                        .forEach(q => {
                            if (!searchables.any(ss => ss.toLowerCase().trim().includes(q.toLowerCase().trim())))
                                match = false;
                        });

                    return match;
                })
    }


    // Render
    return (
        <>
            <Card bordered={false}>
                <div>
                    <PageHeader

                        title={`📅 Add Schedules • [${student.schedules.length}]`}
                        subTitle={<Tag color={"green"}>{student.Name || null} • {student.Adm || null}</Tag>}
                        extra={[
                            <>

                                <Switch
                                    // Css
                                    style={{
                                        height: 33,
                                        borderRadius: 6,
                                        backgroundColor: isToSchool ? "#01C589" : "#32535E"
                                    }}
                                    // Props
                                    defaultChecked={true}
                                    checkedChildren={<h5 style={{color: "white", marginTop: 8}}>
                                        <GoSp cols={3}/>🌅 To School <GoSp cols={2}/>
                                    </h5>}
                                    unCheckedChildren={<h5 style={{color: "whitesmoke", marginTop: 8}}>
                                        <GoSp cols={2}/>🌇 From School<GoSp cols={4}/></h5>}
                                    size={"small"}
                                    // Events
                                    onChange={setIsToSchool}/>
                                <GoSp cols={3}/>
                                <Input.Search
                                    enterButtond
                                    placeholder="Search Schedule • Name / Vehicle ..."
                                    onSearch={setSearch}
                                    style={{width: 400}}
                                />
                            </>

                        ]}
                    />
                </div>
                <Divider orientation={"left"}>Records: {data.count()} / {schedules.count()} </Divider>
                <Table
                    dataSource={data.toArray()}
                    size={"small"}
                    pagination={false}
                    columns={
                        [
                            {
                                title: '',
                                dataIndex: 'colAdd',
                                key: 'Add',
                            },
                            {
                                title: '',
                                dataIndex: 'colName',
                                key: 'Name',

                            },
                            {
                                title: '',
                                dataIndex: 'colVehicle',
                                key: 'Vehicle',
                            },

                            {
                                title: 'Driver',
                                dataIndex: 'colDriver',
                                key: 'Driver',
                            }, {
                            title: 'Attendant',
                            dataIndex: 'colAttendant',
                            key: 'Attendant',
                        },
                            {
                                title: '',
                                dataIndex: 'colRoute',
                                key: 'Route',
                            }
                        ]
                    }/>

            </Card>
        </>
    );

}

