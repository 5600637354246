import React, { Component } from "react";
import { subscribe } from "../../../utils/bunifu-redux";
import Ant, {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Dropdown,
  Icon,
  Input,
  Layout,
  Menu,
  message,
  Row,
  Select,
  Table,
  Tabs,
  Tag,
} from "antd";

import axios from "axios";
import { get, get2, keyValue, put } from "../../../utils/Storage";
import Message from "../Message";
import auth from "../../../Components/Auth";

import searchCol from "../../../Components/SearchCol";
import SortAlphabetic from "../../../Components/SortAlphabetic";
import SortNumeric from "../../../Components/SortNumeric";
import _ from "linq";
import Go from "../../../Server/Go";

class Users extends Component {
  state = {
    name: "Users",
    current: "Active",
    withRoles: true,
    roles: [],
    edit: -2,
    schedules: get("schedules").filter((r) => r.Year === window.year),
    sendMessage: false,
    messages: [],
    data: [],
    //  Added V2
    sync: true,
    filters: [],
    currentDataSource: [],
    permissions: get2("permissions"),
  };

  componentWillMount() {
    subscribe(this);
    let users = _.from(get("users"));
    //  Filter out the props
    if (this.props.provider) users = users.where((r) => r.providerid == this.props.provider.id);

    if (Go.getUser().noAdmin())
      users = users.where((r) => r.providerid == Go.getUser().getProvider()?.id);

    this.setState({ data: users.toArray() });
    this.LoadData();

    setInterval(() => {
      this.LoadData();
    }, 1000 * 60 * 15);
  }

  LoadData() {
    this.setState({ sync: true });
    axios
      .get("/users")
      .then(
        function (response) {
          put("users", response.data);
          let admin = get("admin");
          admin.user = keyValue(get("users"))[admin.user.id];
          put("admin", admin);

          let users = _.from(response.data);
          //  Filter out the props
          if (this.props.provider)
            users = users.where((r) => r.providerid == this.props.provider.id);

          if (Go.getUser().noAdmin())
            users = users.where((r) => r.providerid == Go.getUser().getProvider().id);

          this.setState({ sync: false, data: users.toArray(), edit: -2 });
        }.bind(this)
      )
      .catch(
        function (error) {
          this.setState({ sync: undefined });
          message.error(error["message"]);
        }.bind(this)
      );
  }

  render() {
    const isSyncFail = this.state.sync === undefined;
    const isLoading = this.state.data.length === 0 && this.state.sync;
    const { filters } = this.state;

    const expandedRowRender = (row) => {
      let schedules = this.state.schedules.filter(
        (r) => r.DriverId === row.id || r.AttendantId === row.id
      );

      const columns = [
        {
          title: "Name",
          dataIndex: "Name",
          key: "Name",
          render: (val, schedule) => (
            <Tag size="small" color={schedule.Direction === "To School" ? "#00AF50" : "gray"}>
              <Icon type={schedule.Direction === "To School" ? "arrow-down" : "arrow-up"} /> {val}
            </Tag>
          ),
        },
        { title: "Direction", dataIndex: "Direction", key: "Direction" },
        {
          title: "Vehicle",
          dataIndex: "VehicleId",
          key: "VehicleId",
          render: (val) => <Tag>{keyValue(get("vehicles"))[val].PlateNo}</Tag>,
        },
        {
          title: "Driver",
          dataIndex: "DriverId",
          key: "DriverId",
          render: (val) => (
            <Tag color={val === row.id ? "#00AF50" : null}>{keyValue(get("users"))[val].name}</Tag>
          ),
        },
        {
          title: "Agent",
          dataIndex: "AttendantId",
          key: "AttendantId",
          render: (val) => (
            <Tag color={val === row.id ? "#00AF50" : null}>{keyValue(get("users"))[val].name}</Tag>
          ),
        },
        { title: "Route", dataIndex: "Route", key: "Route", render: (val) => <Tag>{val}</Tag> },
      ];
      return (
        <Table
          className={"shadow"}
          style={{ padding: 20 }}
          size={"middle"}
          columns={columns}
          dataSource={schedules}
          pagination={false}
        />
      );
    };

    let dataFiltered = _.from(this.state.data)
      .where((r) => !this.state.withRoles || r.roles.length > 0)
      .select((r) => {
        r.providerid = r.providerid === null ? "" : r.providerid;
        r.email = r.email || "";
        r.phone = r.phone || "";
        return r;
      });

    dataFiltered = dataFiltered.toArray();
    // this.setState({ roles: get("roles") });
    this.setState.roles = get("roles");

    let columns = [
      {
        title: "",
        dataIndex: "name",
        key: "name",
        render: (val) => (
          <Avatar
            size={"small"}
            style={{ backgroundColor: window.getColor(val + " null", 50, 50) }}
          >
            <Icon type="user" />
          </Avatar>
        ),
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        ...searchCol(this, "name"),
        sorter: (a, b) => SortAlphabetic(a.name, b.name),
        render: (val, obj, idx) =>
          obj.id === this.state.edit ? (
            <span>
              <Input
                onChange={(e) => {
                  idx = this.state.data.findIndex((r) => r.id === obj.id);
                  var val = e.target.value;
                  var newState = this.state.data;
                  newState[idx].name = val;
                  this.setState({ data: newState });
                }}
                value={val}
              />
            </span>
          ) : (
            <span>{val}</span>
          ),
      },
      {
        title: "Provider",
        dataIndex: "providerid",
        key: "providerid",
        visible: !this.props.provider,
        filters: [
          ..._.from(get("providers"))
            .select((pr) => ({ text: pr.Name, value: pr.id }))
            .toArray(),
          { text: "(None)", value: "" },
        ],
        filterMultiple: true,
        onFilter: (value, record) => `${record.providerid}` === `${value}`,
        sorter: (a, b) => SortAlphabetic(a.providerid, b.providerid),
        render: (val, obj, idx) => (
          <span>
            {val === null
              ? "(None)"
              : keyValue(get("providers"))[val]
              ? keyValue(get("providers"))[val].Name
              : "(None)"}
          </span>
        ),
      },
      {
        title: "Phone",
        dataIndex: "phone",
        key: "phone",
        ...searchCol(this, "phone"),
        sorter: (a, b) => SortAlphabetic(a.phone, b.phone),
        render: (val, obj, idx) =>
          obj.id === this.state.edit ? (
            <span>
              <Input
                onChange={(e) => {
                  idx = this.state.data.findIndex((r) => r.id === obj.id);
                  var val = e.target.value;
                  var newState = this.state.data;
                  newState[idx].phone = val;
                  this.setState({ data: newState });
                }}
                value={val}
              />
            </span>
          ) : (
            <span>{val}</span>
          ),
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        ...searchCol(this, "email"),
        sorter: (a, b) => SortAlphabetic(a.email, b.email),
        render: (val, obj, idx) =>
          obj.id === this.state.edit ? (
            <span>
              <Input
                onChange={(e) => {
                  idx = this.state.data.findIndex((r) => r.id === obj.id);
                  var val = e.target.value;
                  var newState = this.state.data;
                  newState[idx].email = val;
                  this.setState({ data: newState });
                }}
                value={val}
              />
            </span>
          ) : (
            <span>{val}</span>
          ),
      },
      {
        title: "Roles",
        dataIndex: "roles",
        key: "roles",
        filters: _.from(get("roles"))
          .select((role) => ({ text: role.name, value: role.id }))
          .toArray(),
        filterMultiple: true,
        onFilter: (value, record) => _.from(record.roles).any((role) => role.id === value),
        sorter: (a, b) => SortNumeric(a.roles.length, b.roles.length),
        render: (tags, obj, idx) => (
          <span>
            <span hidden={this.state.edit !== obj.id}>
              <Select
                mode="multiple"
                style={{ width: 300 }}
                placeholder="None"
                defaultValue={obj.roles.map((data) => {
                  return data.id;
                })}
                onChange={(val) => {
                  obj.roles = val.map((d, i) => {
                    return keyValue(get("roles"))[d];
                  });

                  this.forceUpdate();
                }}
              >
                {this.state.roles.map(function (data, idx) {
                  return (
                    <Select.Option value={data.id} key={data.id}>
                      {data.name}
                    </Select.Option>
                  );
                })}
              </Select>{" "}
            </span>
            <span hidden={this.state.edit === obj.id}>
              {obj.roles.map((data) => {
                return <Tag color="green">{data.name}</Tag>;
              })}
            </span>
          </span>
        ),
      },
      {
        title: "Schedules",
        dataIndex: "id",
        render: (val, obj, idx) => (
          <Tag
            color={
              this.state.schedules.filter((r) => r.DriverId == val || r.AttendantId == val).length >
              0
                ? "#00AF50"
                : "gray"
            }
          >
            {this.state.schedules.filter((r) => r.DriverId == val || r.AttendantId == val).length}
          </Tag>
        ),
      },
      {
        title: "",
        dataIndex: "id",
        key: "id",
        render: (id, obj) => (
          <span>
            <Icon
              size=""
              hidden={this.state.edit !== id}
              type="check"
              style={{
                color: "green",
              }}
              onClick={() => {
                this.update(obj);
                this.setState({
                  edit: -2,
                });
              }}
            />
            {this.state.permissions.get(6) && this.state.permissions.get(6).can_modify == 1 ? (
              <a href={"#/m-user?id=" + id}>
                {" "}
                <Icon
                  size=""
                  type="edit"
                  style={{
                    color: "gray",
                  }}
                />
              </a>
            ) : null}
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item
                    disabled={auth.noView(auth.AgentActivityReport)}
                    hidden={obj.roles.filter((r) => r.name === "Bus Agent").length === 0}
                    onClick={() => window.pop("/wwwroot/?agents&id=" + id)}
                    key="1"
                  >
                    Agent Summary
                  </Menu.Item>
                  <Menu.Item
                    disabled={auth.noView(auth.AccessAttendance)}
                    hidden={obj.roles.filter((r) => r.name === "Gate Agent").length === 0}
                  >
                    Attendance Summary
                  </Menu.Item>
                  {this.state.permissions.get(6) &&
                  this.state.permissions.get(6).can_modify == 1 ? (
                    <Menu.Item
                      disabled={auth.noView(auth.AccessPermissions)}
                      hidden={
                        obj.roles.filter(
                          (r) => r.name === "Administrator" || r.name === "Transporter"
                        ).length === 0
                      }
                      onClick={() => window.pop("#/permissions?id=" + id)}
                    >
                      Access Control
                    </Menu.Item>
                  ) : null}
                </Menu>
              }
            >
              <a onClick={(e) => e.preventDefault()}>
                <Icon style={{ color: "gray" }} type="appstore" theme="filled" />
              </a>
            </Dropdown>
          </span>
        ),
      },
    ];

    const roles = get("roles").map(function (data, idx) {
      return <Select.Option key={data.id}>{data.name}</Select.Option>;
    });

    const operations = (
      <span>
        <Ant.Tooltip
          title={
            this.state.sync
              ? "Updating records..."
              : isSyncFail
              ? "Network error, Records not up-to date."
              : "Records up-to date 👍"
          }
        >
          <Button
            type={isSyncFail ? "danger" : "normal"}
            onClick={() => this.LoadData()}
            shape="circle"
            size={"small"}
          >
            <Icon type="sync" spin={this.state.sync} />
          </Button>
        </Ant.Tooltip>
        &nbsp;
        {this.state.permissions.get(2) && this.state.permissions.get(2).can_modify == 1 ? (
          <Button
            hidden={auth.noView(auth.CanSendSMS)}
            onClick={() => {
              var arr = [];
              dataFiltered.forEach((user) => {
                var obj = {};
                obj["Name"] = user.name;
                obj["Phone"] = user.phone;
                obj["Email"] = user.email;
                arr.push(obj);
              });
              this.setState({ messages: arr, sendMessage: true });
            }}
            type="normal"
            shape="round"
            icon="message"
            size={"small"}
          >
            Send Message
          </Button>
        ) : null}{" "}
        &nbsp;
        {this.state.permissions.get(6) && this.state.permissions.get(6).can_add == 1 ? (
          <a href="#/m-user">
            <Button
              hidden={auth.noInsert(auth.Users)}
              type="primary"
              shape="round"
              icon="plus"
              size="small"
            >
              Add
            </Button>
          </a>
        ) : null}
      </span>
    );
    return (
      <Layout.Content>
        <Message
          onClose={() => this.setState({ sendMessage: false })}
          visible={this.state.sendMessage}
          data={this.state.messages}
        />
        <Card bordered={false} loading={isLoading}>
          <br />
          <br />
          <Tabs defaultActiveKey="1" tabBarExtraContent={operations}>
            <Tabs.TabPane
              tab={
                <span>
                  <Icon type="team" />
                  System Users ({dataFiltered.length})
                </span>
              }
              key="1"
            ></Tabs.TabPane>
          </Tabs>
          <br />
          <Row gutter={5}>
            <Col span={3}>
              <Ant.Switch
                style={{ marginTop: -5, marginRight: 15 }}
                size={"small"}
                defaultChecked={true}
                onChange={(val) => this.setState({ withRoles: val })}
              />
              With roles
            </Col>

            <Col></Col>
            <br />

            <Col span={24}>
              <Table
                bordered={false}
                expandedRowRender={expandedRowRender}
                dataSource={dataFiltered}
                columns={columns}
                title={() =>
                  filters.length > 0 && (
                    <div align={"center"} style={{ width: "100%" }}>
                      <Divider dashed>
                        Filters: <strong style={{ color: "#52C41A" }}>{filters.length}</strong>
                      </Divider>
                      {filters.map((filter, i) => (
                        <>
                          {i > 0 && <i style={{ color: "#676A6F" }}> and &nbsp;&nbsp;</i>}
                          <Ant.Tag style={{ borderRadius: 25, marginRight: 10 }} color="#676A6F">
                            <Icon theme="filled" type="filter" style={{ marginRight: 10 }} />
                            <span>{filter.name}:</span>
                            {_.from(filter.data)
                              .orderBy((r) => r)
                              .toArray()
                              .map((d, ii) => (
                                <>
                                  {ii > 0 && <i style={{ color: "#6AD098" }}> or </i>}
                                  <strong style={{ marginLeft: 5, marginRight: 5 }}>{d}</strong>
                                </>
                              ))}
                          </Ant.Tag>
                        </>
                      ))}
                      <Divider dashed>
                        <strong style={{ color: "#52C41A" }}>
                          {this.state.currentDataSource.length.toLocaleString()} /{" "}
                          {this.state.data.length.toLocaleString()}
                        </strong>{" "}
                        Results found.
                        <strong style={{ color: "#52C41A" }}>
                          {" "}
                          {parseFloat(
                            (this.state.currentDataSource.length / this.state.data.length) * 100
                          ).toFixed(2)}
                          %{" "}
                        </strong>
                      </Divider>
                    </div>
                  )
                }
                onChange={(pagination, filters, sorter, extra) => {
                  let selFilters = [];
                  for (const filter in filters) {
                    if (filters.hasOwnProperty(filter)) {
                      let el = {};
                      el.name = filter;
                      el.data = filters[filter];
                      if (el.data.length > 0) selFilters.push(el);
                    }
                  }

                  this.setState({
                    filters: selFilters,
                    currentDataSource: extra.currentDataSource,
                  });
                }}
                footer={() => (
                  <>
                    <span style={{ paddingRight: 10 }}>
                      Records: <strong>{this.state.data.length.toLocaleString()} </strong>
                    </span>

                    <span style={{ paddingRight: 10 }}>
                      Filtered:{" "}
                      <strong>{this.state.currentDataSource.length.toLocaleString()} </strong>
                    </span>
                  </>
                )}
              />
            </Col>
          </Row>
        </Card>
      </Layout.Content>
    );
  }
}

export default Users;
