import React, {Component} from 'react'; 
import { Steps, Layout, Icon, Divider, Avatar, message, Table, Checkbox, Select, List, Card, Button, Popconfirm, Progress } from 'antd';
import CSVReader from 'react-csv-reader';
import {get,put,exists,remove, keyValue} from "../../utils/Storage";
import Axios from 'axios';

class ImportStudents extends Component {
  state = { 
      current: 0,
      user: get("admin").user.name,
      stations: get("stations"),
      students: get("students"),
      loading: false,
      columns: [],
      columns2: [
        {
           title: 'Adm',
           key: 'Adm',
           dataIndex: 'Adm'
        },
        {
          title: 'Name',
          key: 'Name',
          dataIndex: 'Name'
       },  
       {
        title: 'Class',
        key: 'Class',
        dataIndex: 'Class'
       },
       {
        title: 'Stream',
        key: 'Stream',
        dataIndex: 'Stream'
       } ,
       {
        title: 'Station',
        key: 'Station',
        dataIndex: 'Station'
     }
       
      ],
      datasource: [],
      datasource2: [],
      output: [],
      merge: {},
      existiing :0,
      progress: 0,
      count: 0,
  } 


  uploadStudent=(s)=>{ 
    //find station
    var station = this.state.stations.find(r=>r.Name.toLowerCase().trim()==s.Station.toLowerCase().trim());
    var stationId = station==null?1:station.id;


   var student = {
      id: -1,
      Adm: s.Adm,
      Name: s.Name,
      Class: s.Class,
      Stream: s.Stream,
      Gender: "Male",
      Active: "1",
      Subscription: "Morning & Evening",
      Cost: 0,
      Status: "Active",
      Notes:  "Imported from excel by "+this.state.user,
      Access: "",
      StationId: stationId,
      Guardians: [],
      Schedules: []
   }

    Axios.post('/student',student) 
     .then(d=>{
       this.state.output.push(<span style={{color: "green"}}> student.Adm+" "+student.Name+ " Successfully Added"</span>)
       this.state.progress+=1;
       this.forceUpdate();
     })
    .catch(e=>this.uploadStudent()) 
  }

render() {
  const ButtonGroup = Button.Group; 
return (
  <Layout.Content>
  <Card>
    <Steps size="small"  current={this.state.current}>
      <Steps.Step title="Select CSV File" icon={<Icon type="file-excel" />}  />
      <Steps.Step title="Merging" icon={<Icon type="pull-request" />} />
      <Steps.Step title="Records" icon={<Icon type="table" />} />
      <Steps.Step title={this.state.progress==this.state.count?'Done':'Please Wait...'}  icon={<Icon type={this.state.progress==this.state.count?'smile-o':'loading'}  />}/> 
    </Steps>
    <Divider /> 
    <Layout.Content>
      <div hidden={this.state.current !== 0}>
      <CSVReader
            cssClass="csv-reader-input"
            label="Select CSV with new students "
            onFileLoaded={(data)=>{
                var cols = [{
                  title: '',
                  dataIndex: 'selected',
                  width: 50,
                  render: (val,obj)=> <Checkbox checked={val} onChange={(val)=>{obj.selected=val.target.checked; this.forceUpdate()}}/>
                }];
  
              var colsRaw= data[0];  
              colsRaw.forEach((element,idx) => {
              element = element.toString().trim();
              
              var colName = element.toString().toLowerCase().trim();
              if(element.toString().length>0){
                cols.push({
                    title: element,
                    dataIndex: element,
                    key: element,
                  });
              }


                  
                  
              });
            
              var datasource = []; 
              var raw =[];
              for (let index = 1; index < data.length; index++) {
                const row = data[index];
                var obj = {}
                row.forEach((col,idx) => {
                  obj['key'] = index;
                  obj['selected'] = true;
                  obj[(colsRaw[idx]).toString().trim()] = col.toString().trim();
                });
                raw.push(row);
                datasource.push(obj);
              }
  
            this.setState({
                current: 1,
                columns: cols,
                datasource: datasource
            });
            
          }}
          onError={(e)=>message.error('Upload Failed')}
          inputId={this.state.input}
          inputStyle={{color: 'green'}}
        />
        <div align='center' hidden={this.state.columns.length===0} style={{width: '100%'}}>
          <ButtonGroup>
          
          <Button onClick={()=>{ 
            this.setState({current: 1});
          }}>
            Next
            <Icon type="right" />
          </Button>
        </ButtonGroup>
        </div>
      </div>
      <div hidden={this.state.current !== 1}>
      <List
          grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 4,
          lg: 4,
          xl: 4,
          xxl: 3,
          }}
          dataSource={[
              {
                title: 'Adm',
              },
              {
                title: 'Name',
              },
              {
                title: 'Class',
              },
              {
                title: 'Stream',
              }, 
              {
                  title: 'Phone',
              },
              {
                title: 'Station',
              }
               
              
            ]}
          renderItem={item => (
          <List.Item>
              <Card title={item.title}>
              <Select allowClear style={{ width: '100%' }} 
             
              onChange={(value)=>{
                if(typeof  value === 'undefined'){
                  this.state.merge[item.title]=null; 
                }else{
                  this.state.merge[item.title]=value; 
                }
                this.forceUpdate();

              }}>
                
                  {this.state.columns.map(r=><Select.Option value={r.title}>{r.title}</Select.Option>)}            
              </Select>
              </Card>
          </List.Item>
          )}
      />
      <div align='center' style={{width: '100%'}}>
      <ButtonGroup>
      <Button  onClick={()=>this.setState({current: 0})}>
        <Icon type="left" />
        Go back
      </Button>
      <Button  disabled={ Object.keys(this.state.merge).length<4}  onClick={()=>{
         

           //merge the data to a table 
             var ds2 = []; 
             
           this.state.datasource.forEach(r => {
                var student = {};

                if(r[this.state.merge.Adm]) {
                  
                  student.Adm =  r[this.state.merge.Adm].trim();
                  student.Name = r[this.state.merge.Name] ?  r[this.state.merge.Name].trim(): "";
                  student.Class = r[this.state.merge.Class]? r[this.state.merge.Class].trim(): "";
                  student.Stream = r[this.state.merge.Stream]? r[this.state.merge.Stream].trim(): "";
                  student.Phone = r[this.state.merge.Phone]?  r[this.state.merge.Phone].trim():"";
                  student.Station = r[this.state.merge.Station]? r[this.state.merge.Station].trim(): "";
                   
                  if(this.state.students.filter(r=>r.Adm.toLowerCase().trim()==student.Adm.toLowerCase().trim()).length>0) {
                     this.state.existiing++;
                  }

                  ds2.push(student)
                }

           });
        
        
        this.setState({current: 2,datasource2: ds2})}}>
        Next
        <Icon type="right" />
      </Button>
    </ButtonGroup>
    </div>
      </div>
          <div hidden={this.state.current !== 2}>
          <p>All Records: <b>{this.state.datasource2.length}</b></p>   
          <p>New Records: <b>{this.state.datasource2.length-this.state.existiing}</b></p>   
          <p>Existing Records: <b>{this.state.existiing}</b></p>   
             <Table dataSource={this.state.datasource2} columns={this.state.columns2} />
          <div align='center' style={{width: '100%'}}>
          <ButtonGroup>
          <Button  onClick={()=>this.setState({current: 1})}>
            <Icon type="left" />
            Go back
          </Button>
          <Popconfirm placement="topLeft" title={"Import data?"} 
          onConfirm={()=>{
            this.setState({current: 3,loading: true});
               //looop and upload the data 
               
                var count = 0;
                this.state.datasource2.forEach(d => { 
                  if(this.state.students.filter(r=>r.Adm.toLowerCase().trim()==d.Adm.toLowerCase().trim()).length==0) {
                     count++;
                     this.uploadStudent(d);
                     
                   }else{
                    this.state.output.push(<span style={{color: "red"}}> {d.Adm+" "+d.Name+ " Elready exists"}</span>)
                   } 
                });
               this.state.count = count;

           }} okText="Yes" cancelText="No">
          <Button>
            Next
            <Icon type="right" />
          </Button>
          </Popconfirm>
        </ButtonGroup>
        </div>
      </div>
      <div hidden={this.state.current !== 3}  width='100%'>
             <h4>Processing... Please Wait</h4> 
             <Progress
      strokeColor={{
        '0%': '#0E3541',
        '100%': '#87d068',
      }}
      percent={((this.state.progress/this.state.count)*100).toFixed(3)}
    
    />  <br/> <br/> <br/>
       <List
      size="small"
      header={<div>Upload Output</div>} 
      bordered
      dataSource={this.state.output}
      renderItem={item => <List.Item>{item}</List.Item>}
    />
      </div>
    </Layout.Content>
  </Card>
  </Layout.Content>
)
}
}
export default ImportStudents;