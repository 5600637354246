import React from 'react';
import Ant, {Card, Col, Divider, Icon, Menu, Row, Tag} from "antd";
import Store, {get} from "../../../utils/Storage";
import Routing, {Param, Route, subscribe} from "../../../utils/Routing";
import ProviderAccountForm from "./ProviderAccountForm";
import NotFound from "../NotFound";
import ProviderRoutesForm from "./ProviderRoutesForm";
import ProviderActivity from "./ProviderActivity";
import Students from "../Students/Students";
import Schedules from "../Schedules/Schedules";
import _ from "linq";
import Vehicles from "../Vehicles";
import Users from "../Users/Users";
import Trips from "../Trips";
import kimTools from "../../../utils/KimTools";
import KimTools from "../../../utils/KimTools";

class ProviderProfile extends React.Component {

    constructor(props) {
        super(props);

        let provider = null;
        // If the id is provided in route then we are editing an existing provider
        const id = Routing.Param("id");
        // Detect page too
        if (this.props.provider)
            provider = this.props.provider
        else if (id)
            provider = Store.get("providers", "id")[id];

        if (provider) {
            let words = provider.Name.trim().split(" ");
            if (words.length > 1)
                provider.abbr = (words[0][0] + words[1][0]).toUpperCase();
            else if (words.length > 0)
                provider.abbr = (words[0][0] + words[0][1]).toUpperCase();
            else
                provider.abbrv = id + " X";

            provider.color = window.getColor(provider.abbr[0] + provider.abbr[1], 50, 50);


        }


        this.state = {
            provider: provider,
            providers: _.from(get("providers")),
            page: 'students',
            loading: false,
            vehicles: _.from(get("vehicles")),
            menuCollapsed: false
        }
    }

    onRouteChange = (route) => {
        alert("Yaaaay");
    }

    componentDidMount() {
        subscribe(this);

        // listen((p) => {
        //     if (Route() === "provider")
        //         this.setState({page: p})
        // });
    }


    FilterStudents = student =>
        _.from(student.schedules)
            .any((
                schedule => {
                    let _vehicle = this.state.vehicles.firstOrDefault(v => v.id === schedule.VehicleId);
                    if (!_vehicle) return false;

                    let _provider = this.state.providers.firstOrDefault(p => p.id === _vehicle.ProviderId);
                    if (!_provider) return false;

                    return this.state.provider.id === _provider.id;
                })
                .bind(this)
            )

    FilterSchedules = schedule => {
        let _vehicle =
            this
                .state
                .vehicles
                .firstOrDefault(vehicle => vehicle.id === schedule.VehicleId);

        if (!_vehicle)
            return false;

        let _provider =
            this
                .state
                .providers
                .firstOrDefault(p => p.id === _vehicle.ProviderId);

        if (!_provider)
            return false;

        return this.state.provider.id === _provider.id;
    }


    render() {
        const {loading, provider} = this.state;
        const {Text} = Ant.Typography;
        let page = this.state.page;

        if (Param("page"))
            page = Param("page")


        if (!provider)
            return <NotFound/>

        const color = window.getColor(provider.Name)
        return (
            <Row style={{padding: 30, marginTop: -60}}>
                <Col span={this.state.menuCollapsed ? 0 : 5} style={{paddingRight: 25}}>
                    <Ant.Affix offsetTop={70}>
                        <Col span={24}>
                            <Card
                                className={"shadow"}
                                style={{marginTop: 16, padding: 15}}
                                cover={
                                    <div
                                        align={"center"}
                                        className={"shadow"}
                                        style={{
                                            height: 200,
                                            background: `radial-gradient(circle, ${color}  30%, rgb(0, 175, 80) 130%)`,
                                            backgroundBlendMode: 'multiply',
                                            backgroundColor: color,
                                            borderRadius: 15,

                                        }}
                                    >


                                        <br/>

                                        <h4 style={{marginTop: 30, color: '#00AF50'}}>
                                            {loading ||
                                                <img style={{color: "#00AF50"}} src={"./img/bus.png"}
                                                     width={70}/>}

                                            {loading ?
                                                <Icon style={{color: "#ECEEF1"}} type="loading"/>
                                                :
                                                <>
                                                    <br/>
                                                    <Text
                                                        className={"text-shadow"}
                                                        style={{
                                                            color: '#ECEEF1',
                                                            textShadow: `2px 2px ${provider.color}`
                                                        }}>
                                                        {kimTools.strings.toTitleCase(provider.Name)}
                                                    </Text>

                                                </>
                                            }

                                        </h4>


                                    </div>
                                }

                            >
                                <Card.Meta
                                    avatar={
                                        <Ant.Avatar
                                            style={{backgroundColor: color}}>
                                            {provider.abbr || <Icon type={"plus"}/>}
                                        </Ant.Avatar>
                                    }
                                    title={<h4> {kimTools.strings.toTitleCase(provider.Name)}</h4>}
                                    description={
                                        <>
                                            <Tag color={color}> Transport Provider</Tag>
                                            <hr/>
                                            <span style={{float: "right"}}>

                                                <Tag color={"#3F4248"} style={{float: "right", marginTop: 10}}>
                                    {
                                        _.from(get("students"))
                                            .where(s => this.FilterStudents(s))
                                            .count()
                                    } Students</Tag>
                                             </span>
                                        </>}
                                />
                            </Card>

                        </Col>
                        <Divider/>
                        <Col span={24}>
                            <Menu
                                mode="vertical"
                                selectedKeys={[page]}
                                onSelect={({key}) => Route(`provider?id=${provider.id}&page=${key}`)}
                                // inlineCollapsed={this.state.menuCollapsed}
                            >
                                {/*<Menu.Item key="activity">*/}
                                {/*    <Icon type="sync" spin/> Activity*/}
                                {/*    <Tag color={color} style={{float: "right", marginTop: 10}}>3</Tag>*/}
                                {/*</Menu.Item>*/}
                                <Menu.Item key="students">
                                    <Icon type="usergroup-add"/> Subscribers
                                    <Tag color={color} style={{float: "right", marginTop: 10}}>
                                        {
                                            _.from(get("students"))
                                                .where(s => this.FilterStudents(s))
                                                .count()
                                        }  </Tag>
                                </Menu.Item>
                                <Menu.Item key="trips">
                                    <Icon type="dashboard"/> Trips
                                </Menu.Item>

                                <Menu.Item key="schedules">
                                    <Icon type="calendar"/> Schedules
                                    {/*<Tag style={{float: "right", marginTop: 10}}>53</Tag>*/}
                                </Menu.Item>
                                <Menu.Item key="vehicles">
                                    <Icon type="car"/> Vehicles <Tag style={{float: "right", marginTop: 10}}>
                                    {
                                        _.from(get("vehicles"))
                                            .count(r => r.ProviderId == provider.id)
                                    }
                                </Tag>
                                </Menu.Item>
                                <Menu.Item key="members">
                                    <Icon type="usergroup-add"/> Members <Tag
                                    style={{float: "right", marginTop: 10}}>{
                                    _.from(get("users"))
                                        .count(s => s.providerid == provider.id)
                                }</Tag>
                                </Menu.Item>

                                {/*<Menu.Item key="billing">*/}
                                {/*    <Icon type="dollar"/> Billing & Invoices*/}
                                {/*</Menu.Item>*/}
                                {/*<Menu.Item key="reports">*/}
                                {/*    <Icon type="pie-chart"/> Analytics*/}
                                {/*</Menu.Item>*/}

                                <Menu.SubMenu
                                    key="settings"
                                    title={
                                        <span>
                                      <Icon type="setting"/>  <span>Administration</span>
                                    </span>
                                    }
                                >
                                    {/*<Menu.Item key="account">*/}
                                    {/*    Account*/}
                                    {/*</Menu.Item>*/}
                                    <Menu.Item key="routes">
                                        Routes <Tag style={{float: "right", marginTop: 10}}>5</Tag>
                                    </Menu.Item>
                                    <Menu.Item key="audits">
                                        Audits & Logs
                                    </Menu.Item>
                                </Menu.SubMenu>
                            </Menu>
                        </Col>
                    </Ant.Affix>
                </Col>

                <Col span={this.state.menuCollapsed ? 24 : 19} style={{marginTop: 15}} className={"shadow"}>
                    <Card
                        cover={
                            <Ant.Affix
                                offsetTop={35}
                                // onChange={(affixed) =>  this.setState({affixed})}
                            >
                                <div
                                    //  hidden={this.state.affixed}
                                    style={{
                                        height: 71,
                                    }}>
                                    <Ant.Card
                                        className={"shadow"}
                                        bordered={false}
                                        style={{
                                            height: 64,
                                            background: `linear-gradient(to right,${color}, rgb(0, 175, 80) )`,
                                            backgroundBlendMode: 'multiply',
                                            backgroundColor: color,
                                            color: "whitesmoke!important",
                                            borderRadius: 32,
                                            margin: 15,
                                            marginLeft: 30,


                                        }}
                                    >
                                        <div
                                            style={{color: "whitesmoke"}}
                                        >

                                            <Row>
                                                <Col span={1}>
                                                    <Ant.Tooltip title={"Toggle Menu"}>
                                                        <Ant.Button
                                                            style={{
                                                                backgroundColor: color,
                                                                borderColor: color,
                                                                marginTop: -15,
                                                            }}
                                                            onClick={() => this.setState({menuCollapsed: !this.state.menuCollapsed})}
                                                            type={"primary"}
                                                            // size={"large"}
                                                            shape={"circle"}
                                                            className={"shadow"}
                                                            icon={this.state.menuCollapsed ? "right" : "left"}
                                                        />

                                                    </Ant.Tooltip>
                                                </Col>
                                                <Col span={22}>
                                                    <h4 style={{color: "whitesmoke", marginTop: -2}}>
                                                        Providers &nbsp; ‣ &nbsp; {provider.Name} &nbsp;  ‣ &nbsp; {KimTools.strings.toTitleCase(page)}
                                                    </h4>
                                                </Col>
                                                <Col span={1}>
                                                    <h3
                                                        style={{
                                                            color: "white",
                                                            cursor: "pointer",
                                                            float: "right",
                                                            margin: 10,
                                                            marginTop: -5
                                                        }}

                                                        onClick={() => null}

                                                    >
                                                        <Icon type={"more"}/>

                                                    </h3>
                                                </Col>
                                            </Row>


                                        </div>
                                    </Ant.Card>


                                    <br/>
                                    <br/>

                                </div>
                            </Ant.Affix>
                        }
                    >


                        {page === "activity" && <ProviderActivity provider={this.state.provider}/>}
                        {(page === "students" || page == "") && <Students onFilter={this.FilterStudents}/>}
                        {page === "trips" && <Trips provider={this.state.provider}/>}
                        {page === "vehicles" && <Vehicles provider={this.state.provider}/>}
                        {page === "members" && <Users provider={this.state.provider}/>}
                        {page === "schedules" && <Schedules onFilter={this.FilterSchedules}/>}
                        {page === "info" && <ProviderAccountForm provider={this.state.provider}/>}
                        {page === "routes" && <ProviderRoutesForm provider={this.state.provider}/>}
                        {/*{page === "account" && <ProviderAccountForm provider={this.state.provider}/>}*/}
                        {page === "audits" && <NotFound/>}

                    </Card>
                </Col>
            </Row>
        )
    }
}


export default ProviderProfile;