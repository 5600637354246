import React from 'react';
import {Col, Divider} from 'antd';
import GoBr from "./GoBr";


class GoPrinter extends React.Component {
    state = {}


    componentWillMount() {
        let mediaQueryList = window.matchMedia('print');
        mediaQueryList.addListener((mql) => {
            if (mql.matches) {
                this.props.beforePrint && this.props.beforePrint();
                return;
            }
            this.props.afterPrint && this.props.afterPrint();
        });
    }


    render() {
        return (
            <Col className={"onlyprint"}>
                <img src={"./img/go-green.png"} height={80}/>
                <u>
                    <h3 style={{float: 'right'}}>{
                        this.props.title || "this.props.title❓"
                    }
                    </h3>
                </u>
                <GoBr/>
                {this.props.children || ""}
                <Divider/>
            </Col>
        )
    }
}

export default GoPrinter;