import * as store from "../utils/Storage";
import { select } from "../utils/Storage";
import GoServer from "./GoServer";
import _ from "linq";
import Env from "../utils/Env";

export const Go = {
  tables: _.from(GoServer.EndPoints).select((r) => r.name),

  getUser: () => {
    if (!store.exists("admin")) return;
    const session = store.get("admin");
    const user = session.user;
    user.isAdmin = () => (Env.isDevelopment() ? false : _.from(user.roles).any((r) => r.name === "Administrator"));
    user.noAdmin = () => !user.isAdmin();
    user.getProvider = () => {
      const provider = select("providers").firstOrDefault((pr) => pr.id == user.providerid);

      if (provider) {
        provider.getVehicles = () => select("vehicles").where((ve) => ve.ProviderId === provider.id);

        provider.getSchedules = () => select("schedules").where((sc) => provider.getVehicles().any((ve) => ve.id == sc.VehicleId));
      }

      return provider;
    };
    return user;
  },
  users: (predicate = (val, idx) => true) => {
    return store
      .from("users")
      .where(predicate)
      .select((r) => {
        return r;
      });
  },
  students: (predicate = (val, idx) => true) => {
    return store
      .from("students")
      .where(predicate)
      .select((r) => {
        return r;
      });
  },
  schedules: (predicate = (val, idx) => true) => {
    return store
      .from("schedules")
      .where(predicate)
      .select((r) => {
        return r;
      });
  },
};

export default Go;
