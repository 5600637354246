import React, { Component } from "react";
import { subscribe } from "../../utils/bunifu-redux";
import Ant, {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Dropdown,
  Icon,
  Input,
  Layout,
  Menu,
  message,
  Radio,
  Row,
  Select,
  Table,
  Tabs,
  Tag,
} from "antd";
import axios from "axios";
import auth from "../../Components/Auth";
import { get, get2, keyValue, put } from "../../utils/Storage";
import moment from "moment";
import searchCol from "../../Components/SearchCol";
import SortAlphabetic from "../../Components/SortAlphabetic";
import SortNumeric from "../../Components/SortNumeric";
import _ from "linq";
import kimTools from "../../utils/KimTools";
import Go from "../../Server/Go";

class Vehicles extends Component {
  state = {
    name: "Vehicles",
    schedules: get("schedules"),
    page: "All",
    month: moment().format("MM"),
    year: moment().format("YYYY"),
    date: moment().format("YYYY") + "-" + moment().format("MM") + "-01",
    loadingTrips: false,
    loadingVehicles: true,
    edit: -1,
    //  Added V2
    sync: true,
    filters: [],
    currentDataSource: [],
    data: [],
    tripsData: [],
    providers: _.from(get("providers")),
    permissions: get2("permissions"),
  };

  componentWillMount() {
    subscribe(this);

    let vehicles = _.from(get("vehicles"));

    //  Filter out the props
    if (this.props.provider)
      vehicles = vehicles.where((r) => r.ProviderId == this.props.provider?.id);

    if (Go.getUser().noAdmin())
      vehicles = vehicles.where(
        (r) => r.ProviderId == Go.getUser().getProvider()?.id
      );

    this.setState({ data: vehicles.toArray() });
    this.LoadData();
  }

  LoadData() {
    this.setState({ loadingVehicles: true });
    axios
      .get("/vehicle")
      .then(
        function (response) {
          put("vehicles", response.data);
          put("vehicles-map", keyValue(response.data));

          let vehicles = _.from(response.data);

          //  Filter out the props
          if (this.props.provider)
            vehicles = vehicles.where(
              (r) => r.ProviderId == this.props.provider.id
            );

          if (Go.getUser().noAdmin())
            vehicles = vehicles.where(
              (r) => r.ProviderId == Go.getUser().getProvider().id
            );

          this.setState({ data: vehicles.toArray(), loadingVehicles: false });
        }.bind(this)
      )
      .catch(
        function (error) {
          message.error(error["message"]);
          this.setState({ loadingVehicles: false });
        }.bind(this)
      );
  }

  update(obj) {
    if (obj.id >= 0) {
      axios
        .patch("/vehicle", obj)
        .then(
          function (response) {
            put("vehicles", this.state.data);
            put("vehicles-map", keyValue(this.state.data));
            message.success("Saved");
          }.bind(this)
        )
        .catch(function (error) {
          message.error(error.message);
        });
    } else {
      //post
      axios
        .post("/vehicle", obj)
        .then(
          function (response) {
            this.sync();
            var c = this.state.data;
            c.unshift(response.data);
            this.setState({
              data: c,
              edit: response.data.id,
            });
            put("vehicles", this.state.data);
            put("vehicles-map", keyValue(this.state.data));
            message.success("Added");
          }.bind(this)
        )
        .catch(function (error) {
          message.error(error.message);
        });
    }
  }

  getProviders() {
    var arr = [];
    get("vehicles").forEach((vehicle) => {
      if (!arr.includes(vehicle.Provider)) {
        arr.push(vehicle.Provider);
      }
    });

    return arr;
  }

  render() {
    const { filters, currentDataSource, providers } = this.state;
    const expandedRowRender = (row) => {
      let schedules = this.state.schedules.filter(
        (r) => r.VehicleId === row.id
      );

      const columns = [
        {
          title: "",
          dataIndex: "Name",
          key: "Name",
          render: (val) => (
            <Avatar
              size={"small"}
              style={{
                backgroundColor: window.getColor(val + " null", 50, 50),
              }}
            >
              <Icon type="calendar" />
            </Avatar>
          ),
        },
        { title: "Name", dataIndex: "Name", key: "Name" },
        {
          title: "Driver",
          dataIndex: "DriverId",
          key: "DriverId",
          render: (val) => <Tag>{get("users-map")[val].name}</Tag>,
        },
        {
          title: "Agent",
          dataIndex: "AttendantId",
          key: "AttendantId",
          render: (val) => <Tag>{get("users-map")[val].name}</Tag>,
        },
        { title: "Direction", dataIndex: "Direction", key: "Direction" },
        {
          title: "Route",
          dataIndex: "Route",
          key: "Route",
          render: (val) => <Tag>{val}</Tag>,
        },
      ];

      return (
        <Table
          loading={this.state.loadingTrips}
          className={"shadow"}
          style={{ padding: 20 }}
          size={"middle"}
          columns={columns}
          dataSource={schedules}
          pagination={false}
        />
      );
    };

    const columns = [
      {
        title: "",
        dataIndex: "Status",
        key: "Status",
        width: 125,
        visible: this.props.provider === undefined,
        render: (val) => (
          <Tag color={val === "Scheduled" ? "limegreen" : "red"}> {val}</Tag>
        ),
      },
      {
        title: "PlateNo",
        dataIndex: "PlateNo",
        key: "PlateNo",
        ...searchCol(this, "PlateNo"),
        sorter: (a, b) => SortAlphabetic(a.PlateNo, b.PlateNo),
        render: (val, obj, idx) =>
          obj.id === this.state.edit ? (
            <span>
              <Input
                onChange={(e) => {
                  idx = this.state.data.findIndex((r) => r.id === obj.id);
                  var val = e.target.value;
                  var newState = this.state.data;
                  newState[idx].PlateNo = val;
                  this.setState({ data: newState });
                }}
                value={val.toString().toUpperCase()}
              />
            </span>
          ) : (
            <span>{val}</span>
          ),
      },
      {
        title: "Model",
        dataIndex: "Model",
        key: "Model",
        ...searchCol(this, "Model"),
        sorter: (a, b) => SortAlphabetic(a.Model, b.Model),
        render: (val, obj, idx) => (
          <span>{kimTools.strings.toTitleCase(val)}</span>
        ),
      },
      {
        title: "Provider",
        dataIndex: "ProviderId",
        key: "ProviderId",
        visible: false,
        filters: [
          ...providers
            .select((pr) => ({ text: pr.Name, value: pr.id }))
            .toArray(),
          { text: "(None)", value: "" },
        ],
        filterMultiple: true,
        onFilter: (value, record) => `${record.ProviderId}` === `${value}`,
        sorter: (a, b) => SortAlphabetic(a.ProviderId, b.ProviderId),
        render: (val, obj, idx) => (
          <span>{val > 0 ? get("providers-map")[val].Name : "Unknown"}</span>
        ),
      },
      {
        title: "Color",
        dataIndex: "Color",
        key: "Color",
        sorter: (a, b) => SortAlphabetic(a.Color, b.Color),
        render: (val, obj, idx) =>
          obj.id === this.state.edit ? (
            <span>
              <Input
                onPressEnter={() => {
                  this.update(obj);
                  this.setState({ edit: -2 });
                }}
                onChange={(e) => {
                  idx = this.state.data.findIndex((r) => r.id === obj.id);
                  var val = e.target.value;
                  var newState = this.state.data;
                  newState[idx].Color = val;
                  this.setState({ data: newState });
                }}
                value={val}
              />
            </span>
          ) : (
            <Tag color={val}>
              <Icon type="car" /> {val}
            </Tag>
          ),
      },

      {
        title: "Capacity",
        dataIndex: "Capacity",
        key: "Capacity",
        sorter: (a, b) => SortNumeric(a.Capacity, b.Capacity),
        render: (val, obj, idx) =>
          obj.id === this.state.edit ? (
            <span>
              <Input
                onChange={(e) => {
                  idx = this.state.data.findIndex((r) => r.id === obj.id);
                  var val = e.target.value;
                  var newState = this.state.data;
                  newState[idx].Capacity = val;
                  this.setState({ data: newState });
                }}
                value={val}
              />
            </span>
          ) : (
            <span>{val}</span>
          ),
      },

      {
        title: "Schedules",
        dataIndex: "id",
        sorter: (a, b) =>
          SortNumeric(
            _.from(this.state.schedules).count((r) => r.VehicleId === a.id),
            _.from(this.state.schedules).count((r) => r.VehicleId === b.id)
          ),
        render: (val, obj) => (
          <Tag color={obj["Status"] === "Scheduled" ? "limegreen" : "red"}>
            {this.state.schedules.filter((r) => r.VehicleId === val).length}
          </Tag>
        ),
      },

      {
        title: "",
        dataIndex: "id",
        key: "id",
        render: (id, obj, idx) => (
          <span>
            {this.state.permissions.get(12) &&
            this.state.permissions.get(12).can_modify == 1 ? (
              <a
                hidden={auth.noUpdate(auth.Vehicles)}
                href={"#/m-vehicle?id=" + id}
              >
                {" "}
                <Icon
                  size=""
                  type="edit"
                  style={{
                    color: "gray",
                  }}
                  onClick={() => {
                    this.setState({
                      edit: id,
                    });
                  }}
                />{" "}
              </a>
            ) : null}
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item
                    disabled={auth.noView(auth.AgentActivityReport)}
                    onClick={() => window.pop("/wwwroot/?vehicles&id=" + id)}
                    hidden={!auth.View(auth.AgentActivityReport)}
                    key="1"
                  >
                    Vehicle Summary
                  </Menu.Item>
                  <Menu.Item
                    disabled={auth.noView(auth.ProviderActivityReport)}
                    onClick={() =>
                      window.pop("/wwwroot/?providers&id=" + obj.ProviderId)
                    }
                    hidden={!auth.View(auth.ProviderActivityReport)}
                  >
                    Provider Summary
                  </Menu.Item>
                </Menu>
              }
            >
              <a onClick={(e) => e.preventDefault()}>
                <Icon
                  style={{ color: "gray" }}
                  type="appstore"
                  theme="filled"
                />
              </a>
            </Dropdown>
          </span>
        ),
      },
    ];

    if (
      this.state.prevMonth !=
      this.state.year + "-" + this.state.month + "-01"
    ) {
      this.state.prevMonth = this.state.year + "-" + this.state.month + "-01";
      this.LoadData();
    }

    let dataFiltered = this.state.data;

    var active = dataFiltered.filter(
      (r) =>
        this.state.schedules.filter((rr) => rr.VehicleId === r.id).length > 0
    );
    var inActive = dataFiltered.filter(
      (r) =>
        this.state.schedules.filter((rr) => rr.VehicleId === r.id).length === 0
    );
    let all = dataFiltered;

    let _context = all;

    if (this.state.page === "Active") _context = active;
    else if (this.state.page === "InActive") _context = inActive;

    _context = _.from(_context)
      .select((r, idx) => {
        r.Status =
          this.state.schedules.filter((rr) => rr.VehicleId === r.id).length > 0
            ? "Scheduled"
            : "Pending";
        return r; // since js is not mutable, I have to retun the object.
      })
      .toArray();

    const vehicles = this.getProviders().map((provider) => {
      return <Select.Option value={provider}>{provider}</Select.Option>;
    });

    const operations = (
      <span>
        <Button
          type="normal"
          onClick={() => this.LoadData()}
          shape="circle"
          icon="sync"
          size={"small"}
        />{" "}
        &nbsp;
        {this.state.permissions.get(12) &&
        this.state.permissions.get(12).can_add == 1 ? (
          <a hidden={auth.noInsert(auth.Vehicles)} href={"#/m-vehicle"}>
            {" "}
            <Button type="primary" shape="round" icon="plus" size="small">
              Add Vehicle
            </Button>
          </a>
        ) : null}
      </span>
    );

    return (
      <Layout.Content>
        <Card bordered={false}>
          <Radio.Group
            value={this.state.page}
            buttonStyle="solid"
            onChange={(e) => this.setState({ page: e.target.value })}
          >
            <Radio.Button value="All">All ({all.length})</Radio.Button>
            <Radio.Button value="Active">
              In Service ({active.length})
            </Radio.Button>
            <Radio.Button value="Inactive">
              Out of Service ({inActive.length})
            </Radio.Button>
          </Radio.Group>
          <br /> <br />
          <Tabs defaultActiveKey="1" tabBarExtraContent={operations}>
            <Tabs.TabPane
              tab={
                <span>
                  <Icon type="team" />
                  Vehicles ({dataFiltered.length})
                </span>
              }
              key="1"
            >
              <Row gutter={5}>
                <Col span={24}>
                  <Table
                    size={"middle"}
                    dataSource={_context}
                    columns={columns}
                    expandedRowRender={expandedRowRender}
                    title={() =>
                      filters.length > 0 && (
                        <div align={"center"} style={{ width: "100%" }}>
                          <Divider dashed>
                            Filters:{" "}
                            <strong style={{ color: "#52C41A" }}>
                              {filters.length}
                            </strong>
                          </Divider>
                          {filters.map((filter, i) => (
                            <>
                              {i > 0 && (
                                <i style={{ color: "#676A6F" }}>
                                  {" "}
                                  and &nbsp;&nbsp;
                                </i>
                              )}
                              <Ant.Tag
                                style={{ borderRadius: 25, marginRight: 10 }}
                                color="#676A6F"
                              >
                                <Icon
                                  theme="filled"
                                  type="filter"
                                  style={{ marginRight: 10 }}
                                />
                                <span>{filter.name}:</span>
                                {_.from(filter.data)
                                  .orderBy((r) => r)
                                  .toArray()
                                  .map((d, ii) => (
                                    <>
                                      {ii > 0 && (
                                        <i style={{ color: "#6AD098" }}> or </i>
                                      )}
                                      <strong
                                        style={{
                                          marginLeft: 5,
                                          marginRight: 5,
                                        }}
                                      >
                                        {d}
                                      </strong>
                                    </>
                                  ))}
                              </Ant.Tag>
                            </>
                          ))}
                          <Divider dashed>
                            <strong style={{ color: "#52C41A" }}>
                              {this.state.currentDataSource.length.toLocaleString()}{" "}
                              / {this.state.data.length.toLocaleString()}
                            </strong>{" "}
                            Results found.
                            <strong style={{ color: "#52C41A" }}>
                              {" "}
                              {parseFloat(
                                (this.state.currentDataSource.length /
                                  this.state.data.length) *
                                  100
                              ).toFixed(2)}
                              %{" "}
                            </strong>
                          </Divider>
                        </div>
                      )
                    }
                    onChange={(pagination, filters, sorter, extra) => {
                      let selFilters = [];
                      for (const filter in filters) {
                        if (filters.hasOwnProperty(filter)) {
                          let el = {};
                          el.name = filter;
                          el.data = filters[filter];
                          if (el.data.length > 0) selFilters.push(el);
                        }
                      }

                      this.setState({
                        filters: selFilters,
                        currentDataSource: extra.currentDataSource,
                      });
                    }}
                    footer={() => (
                      <>
                        <span style={{ paddingRight: 10 }}>
                          Records:{" "}
                          <strong>
                            {this.state.data.length.toLocaleString()}{" "}
                          </strong>
                        </span>

                        <span style={{ paddingRight: 10 }}>
                          Filtered:{" "}
                          <strong>
                            {this.state.currentDataSource.length.toLocaleString()}{" "}
                          </strong>
                        </span>
                      </>
                    )}
                  />
                </Col>
              </Row>
            </Tabs.TabPane>
          </Tabs>
        </Card>
      </Layout.Content>
    );
  }
}

export default Vehicles;
