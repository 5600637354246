import React from 'react';
import {Card, Icon, Statistic} from "antd";
import PropTypes from "prop-types";

class GoWidget extends React.Component {


    render() {

        const props = this.props;
        return (
            <Card size={"small"} className={props.className || "shadow"} bordered={false}
                  style={{borderRadius: "15px", marginBottom: "20px"}}>
                <Card size={"small"}
                      bordered={false}
                      style={{
                          height: "65px",
                          width: "60px",
                          borderRadius: "15px",
                          backgroundColor: (props.color || "#00B050")
                      }}>

                    <h1 style={{color: "white"}}>
                        <Icon type={props.icon || "sync"} spin={props.spin || false}/>
                    </h1>
                </Card>
                {/*<span style={{color: ("whitesmoke"), float: "right", fontSize: 50, marginTop: -70}}> <Icon*/}
                {/*    type={props.icon || "sync"}/></span>*/}

                <span
                    style={{
                        float: "left",
                        marginTop: "-60px",
                        marginLeft: "90px"
                    }}>
                        <Statistic title={props.title || props.label || "Widget1"}
                                   value={props.value || 0}/>
                    </span>
            </Card>

        )
    }
}

GoWidget.propTypes = {
    icon: PropTypes.string,
    spin: PropTypes.bool,
    color: PropTypes.string,
    title: PropTypes.string,
    value: PropTypes.number,
    label: PropTypes.string
};

export default GoWidget;


