const SortNumeric = (a, b) => {

    if (!a) a = 0;
    if (!b) b = 0;

    let numericA = parseFloat(a);
    let numericB = parseFloat(b);

    if (isNaN(numericA))
        numericA = 0;

    if (isNaN(numericB))
        numericB = 0;

    return numericA - numericB;
}
export default SortNumeric;