
////// Import From Excel

import React, {Component} from "react";
import {get} from "../../../utils/Storage";
import {subscribe} from "../../../utils/bunifu-redux";
import {Avatar, Button, Col, Divider, Drawer, Input, message, Row, Select, Table, Tabs} from "antd";
import CSVReader from "react-csv-reader";
import { v4 as Guid } from 'uuid';
class Excel extends Component {
    state = {
        name: "Excel",
        input: Guid(),
        data: [],
        rows: [],
        columns: [],
        loading: false,
        adm: null,
        students: get("students"),
        subscribedStudents: this.props.students,
        showFilter: false,
        filter: "",
        filterCol: null,
    };

    componentWillMount() {
        subscribe(this);
    }

    render() {
        var allRecords = [];
        var existingStudents = [];
        var notExistingStudents = [];
        var notExistingStudentsData = [];
        var different = [];
        var unknown = [];

        //filter here

        this.state.rows.forEach((row, index) => {
            try {
                if (this.state.filter.length > 0) {
                    if (
                        !row[this.state.filterCol]
                            .toString()
                            .replace(" ", "")
                            .toLowerCase()
                            .includes(
                                this.state.filter
                                    .toString()
                                    .replace(" ", "")
                                    .toLowerCase()
                                    .trim()
                            )
                    ) {
                        return;
                    }
                }

                allRecords.push(this.state.data[index]);

                var adm = row[this.state.adm];
                var students = this.state.students.filter(
                    (r) =>
                        r.Adm.toString().toLocaleLowerCase().trim() ===
                        adm.toString().toLocaleLowerCase().trim()
                );
                if (students.length > 0) {
                    var student = students[0];
                    //check for schedules of same direction
                    if (
                        this.state.subscribedStudents.filter(
                            (r) =>
                                r.Adm.toString().toLocaleLowerCase().trim() ===
                                adm.toString().toLocaleLowerCase().trim()
                        ).length > 0
                    ) {
                        existingStudents.push(this.state.data[index]);
                    } else {
                        student.index = index;
                        if (student.RouteId === this.props.route.id) {
                            //else pending
                            notExistingStudents.push(this.state.data[index]);
                            notExistingStudentsData.push(student);
                        } else {
                            //else pending
                            different.push(this.state.data[index]);
                            different.push(student);
                        }
                    }
                } else {
                    unknown.push(this.state.data[index]);
                }
            } catch (error) {
                unknown.push(this.state.data[index]);
            }
        });

        return (
            <Drawer
                width={800}
                title="Import From Excel"
                visible={this.props.visible}
                onClose={this.props.onCancel}
                confirmLoading={this.state.loading}
                maskClosable={false}
            >
                <Row>
                    <Col>
                        <CSVReader
                            cssClass="csv-reader-input"
                            label="Select CSV with student admissions."
                            onFileLoaded={(data) => {
                                var cols = [
                                    {
                                        title: "",
                                        dataIndex: "id",
                                        width: 50,
                                        render: () => (
                                            <Avatar
                                                style={{ backgroundColor: "#87d068" }}
                                                icon="user-add"
                                            />
                                        ),
                                    },
                                ];
                                var colsRaw = data[0];
                                var detectReg = null;
                                var detectPlate = null;
                                var plateVal = "";
                                colsRaw.forEach((element, idx) => {
                                    element = element.toString().trim();
                                    cols.push({
                                        title: element,
                                        dataIndex: element,
                                        key: element,
                                    });
                                    var colName = element.toString().toLowerCase().trim();
                                    if (colName.includes("adm") || colName.includes("reg")) {
                                        detectReg = idx;
                                    }
                                    if (
                                        colName.includes("car") ||
                                        colName.includes("bus") ||
                                        colName.includes("vehicle")
                                    ) {
                                        detectPlate = idx;
                                        //load
                                        plateVal =
                                            get("vehicles-map")[this.props.schedule.VehicleId]
                                                .PlateNo;
                                    }
                                });

                                var datasource = [];
                                var raw = [];
                                for (let index = 1; index < data.length; index++) {
                                    const row = data[index];
                                    var obj = {};
                                    row.forEach((col, idx) => {
                                        obj["key"] = index;
                                        obj[colsRaw[idx].toString().trim()] = col.toString().trim();
                                    });
                                    raw.push(row);
                                    datasource.push(obj);
                                }

                                this.setState({
                                    adm: detectReg,
                                    filterCol: detectPlate,
                                    filter: plateVal,
                                    columns: cols,
                                    data: datasource,
                                    rows: raw,
                                    showFilter: detectPlate !== null,
                                });
                            }}
                            onError={(e) => message.error("Upload Failed")}
                            inputId={this.state.input}
                            inputStyle={{ color: "green" }}
                        />
                        <br />
                        <span hidden={this.state.columns.length === 0}>
              <Divider />
              Select ADM/Reg Column &nbsp;
                            <Select
                                style={{ width: 150 }}
                                onChange={(val) => this.setState({ adm: val })}
                                value={this.state.adm}
                            >
                {this.state.columns.map((data, idx) => {
                    return (
                        <Select.Option key={idx} value={idx - 1}>
                            {data.title}
                        </Select.Option>
                    );
                })}
              </Select>
            </span>
                    </Col>
                </Row>
                <Row hidden={this.state.adm === null}>
                    <br />
                    <a
                        href="javascript:;"
                        style={{ color: "#00AF50" }}
                        onClick={() =>
                            this.setState({ showFilter: !this.state.showFilter })
                        }
                    >
                        Toggle Advanced Filter
                    </a>
                    <br /> <br />
                    <span hidden={!this.state.showFilter}>
            <Select
                style={{ width: 150 }}
                onChange={(val) => this.setState({ filterCol: val, filter: "" })}
                value={this.state.filterCol}
            >
              {this.state.columns.map((data, idx) => {
                  return (
                      <Select.Option key={idx} value={idx - 1}>
                          {data.title}
                      </Select.Option>
                  );
              })}
            </Select>
                        &nbsp;
                        <Input.Search
                            hidden={this.state.filterCol === null}
                            style={{ width: 300 }}
                            value={this.state.filter}
                            placeholder="Filter by column"
                            onChange={(val) => this.setState({ filter: val.target.value })}
                        />
            <Divider />
          </span>
                    <Tabs defaultActiveKey="1" size="small">
                        <Tabs.TabPane
                            tab={"All Excel Records (" + allRecords.length + ")"}
                            key="1"
                        >
                            <Table
                                size="small"
                                loading={this.state.loading}
                                columns={this.state.columns}
                                dataSource={allRecords}
                            />
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            tab={"Exisiting on Schedule (" + existingStudents.length + ")"}
                            key="2"
                        >
                            <Table
                                size="small"
                                loading={this.state.loading}
                                columns={this.state.columns}
                                dataSource={existingStudents}
                            />
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            tab={
                                "Students Not Existing on Schedule (" +
                                notExistingStudents.length +
                                ")"
                            }
                            key="3"
                        >
                            <Button
                                style={{ float: "right" }}
                                shape="round"
                                type="primary"
                                loading={this.state.loading}
                                icon="file-excel"
                                onClick={() => {
                                    if (notExistingStudentsData.length === 0) {
                                        message.error("No records to be imported.");
                                        return;
                                    }
                                    this.setState({ loading: true });
                                    //loop all record

                                    notExistingStudentsData.forEach((student) => {
                                        var index = student.index;
                                        this.state.data.splice(index, 1);
                                        this.state.rows.splice(index, 1);
                                    });

                                    this.props.onAdd(notExistingStudentsData);
                                    //reset state
                                    this.setState({
                                        name: "Excel",
                                        input: Guid(),
                                        data: [],
                                        rows: [],
                                        columns: [],
                                        loading: false,
                                        adm: 0,
                                        students: get("students"),
                                        filter: "",
                                    });
                                    this.props.onOk();
                                }}
                            >
                                Import & Assign
                            </Button>
                            <br />
                            <br />
                            <Table
                                size="small"
                                loading={this.state.loading}
                                columns={this.state.columns}
                                dataSource={notExistingStudents}
                            />
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            tab={"Different Route (" + different.length + ")"}
                            key="4"
                        >
                            <Table
                                size="small"
                                loading={this.state.loading}
                                columns={this.state.columns}
                                dataSource={different}
                            />
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            tab={"Unknown Students (" + unknown.length + ")"}
                            key="5"
                        >
                            <Table
                                size="small"
                                loading={this.state.loading}
                                columns={this.state.columns}
                                dataSource={unknown}
                            />
                        </Tabs.TabPane>
                    </Tabs>
                </Row>
            </Drawer>
        );
    }
}

export  default  Excel;