import React from 'react';
import PropTypes from 'prop-types';
import {Card} from "antd";
import Q from "linq";
import {Model} from "survey-core";
import {Survey} from "survey-react-ui";
import Store from "../../../utils/Storage";

class ProviderRoutesForm extends React.Component {
    state = {
        provider: null
    }

    constructor(props) {
        super(props);
        const model = new Model(
            {
                "completedHtml": "<h4>Saving changes...</h4>",
                "completedBeforeHtml": " ",
                "loadingHtml": "<h3>Loading ...</h3>",
                "completeText": "Save",
                "widthMode": "responsive",
                "pages": [
                    {
                        "name": "pageRoutes",
                        "elements": [
                            {
                                "type": "checkbox",
                                "name": "routees",
                                "title": "Select Routes",
                                "titleLocation": "hidden",
                                "hideNumber": true,
                                "isRequired": true,
                                "choices": Q.from(Store.get("routes")).select(x => ({
                                    value: x.id,
                                    text: x.Name
                                })).toArray(),
                                "colCount": 2,
                                "showSelectAllItem": true
                            }
                        ],
                        "title": "Routes",
                        "description": "All the routes operated by the provider"
                    }
                ],

            }
        )


        this.state = {
            provider: props.provider,
            loading: false,
            model: model
        }
    }

    render() {

        const {
            model,
            loading,
            provider
        } = this.state;

        model.data = provider.routes;
        model.completeText = "Save";
        model.onComplete.add((sender, options) => {

            this.setState({provider: {...provider, routes: options.data}})

            // update the provider


            window.location.reload();
        });

        return (
            <Card
                className={"shadow"}
                bordered={false}
            >
                <Survey model={model}/>
            </Card>
        );


    }


}

// PROPS
ProviderRoutesForm.propTypes = {
    provider: PropTypes.object.isRequired
}

export default ProviderRoutesForm
