import React from 'react';
import {Button, Card, Col, Layout, Result, Row} from "antd";
import {Model} from "survey-core";
import {Survey} from "survey-react-ui";
import axios from "axios";

class PasswordResetPage extends React.Component {

    // Constructor
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            loading: false,
            schema: {
                pages: [
                    {
                        name: "options-page",
                        elements: [
                            {
                                type: "radiogroup",
                                name: "option",
                                title: "Choose option",
                                hideNumber: true,
                                isRequired: true,
                                choices: [
                                    {
                                        value: "Email",
                                        text: "Email verification link"
                                    },
                                    {
                                        value: "SMS",
                                        text: "SMS One Time Password (OTP)"
                                    }
                                ]
                            },
                            {
                                type: "text",
                                name: "Email",
                                visible: false,
                                visibleIf: "{option} = 'Email'",
                                title: "Email",
                                hideNumber: true,
                                isRequired: true,
                                inputType: "email"
                            },
                            {
                                type: "text",
                                name: "Phone",
                                visible: false,
                                visibleIf: "{option} = 'SMS'",
                                title: "Phone",
                                hideNumber: true,
                                isRequired: true,
                                inputType: "tel"
                            },
                            {
                                type: "text",
                                name: "code",
                                visible: false,
                                title: "Enter OTP Code",
                                hideNumber: true,
                                isRequired: true,
                                inputType: "number",
                                min: 1000,
                                max: 9999
                            }
                        ],
                        title: "Password Reset",
                        description: "Account recovery self-service",
                    }
                ]
            }
        };
    }

    onSubmit= (data) => {

        //clear local storage
        localStorage.clear();

        let payload = {
             callback_url: window.location.origin+"/#/verify"
        }

        if(data.option=='Email')
           payload.email = data.Email
        else
           payload.phone = data.Phone

        this.setState({loading: true})

        if(!axios.defaults.baseURL.endsWith("/api"))
           axios.defaults.baseURL += "/api";

        axios
            .post("forgot-password", payload)
            .then((response) =>   this.setState({data: data}) )
            .catch((err)=>window.message.error(err.message))
            .finally(()=>this.setState({loading: false}))
    }


    render() {
        const {
            loading,
            data,
            schema,
        } = this.state;

        let form = new Model(schema);
        form.data = data;
        form.completeText = "Reset Password";
        form.showQuestionNumbers = "off";
        form.completedHtml = "<h4>Saving changes...</h4>";
        form.onComplete.add((sender, options) => this.onSubmit(sender.data));

        return (
            <Layout>
                <Layout.Content>
                    <Row>
                        <Col span={8}/>
                        <Col span={8} style={{marginTop: "5%"}}>
                            <Card
                                loading={loading}
                                className={"shadow"}
                                title={<img src={"./img/logo2.png"} style={{float: "right"}} height={35}/>}>
                                {data ?
                                    <Result
                                        status="success"
                                        title="Successful"
                                        subTitle={data.option === "Email" ? 'Please check your email for the password reset link.' : 'Please check your phone for the password reset code.'}
                                        extra={[
                                            <Button
                                                onClick={() => this.setState({data: null})} icon={"arrow-left"}
                                                key="back">
                                                Retry
                                            </Button>,

                                            <Button
                                                type="primary"
                                                icon={"check"}
                                                key="buy"
                                                onClick={()=>  {
                                                    localStorage.clear();

                                                        window.location.href = window.location.origin+ (data.option == "Email" ? "" : "/#/verify?phone="+data.Phone);
                                                        window.location.reload();
                                                }}
                                            >
                                                {data.option == "Email" ? "Go to Login" : "Verify Code"}
                                            </Button>
                                            ,
                                        ]}
                                    />
                                    :
                                    <Survey model={form}/>
                                }
                            </Card>

                        </Col>
                        <Col span={8}/>
                    </Row>
                </Layout.Content>
            </Layout>
        );
    }


}

export default PasswordResetPage;