import React, { Component } from "react";
import "./App.css";
import AppLayout from "./layout/Layout";
import Checkin from "./layout/Reports/Checkin";
import ProviderDay from "./layout/Reports/ProviderDay";
import AgentDay from "./layout/Reports/AgentDay";
import DriverDay from "./layout/Reports/DriverDay";
import StorageExplorer from "./layout/Reports/StorageExplorer";
import Audit from "./layout/Reports/Audit";
import { exists, get, put } from "./utils/Storage";
import { notification } from "antd";
import Invoice from "./layout/Reports/Invoice";
import axios from "axios";
import Route from "./utils/Routing";
import PasswordResetPage from "./layout/pages/Security/PasswordResetPage";
import GoSync from "./layout/pages/Sync/GoSync";
import Verify from "./layout/pages/Security/Verify";
import AuthPermissions from "./layout/pages/Security/AuthPermissions";
import Lock from "./layout/pages/Lock";
import auth from "./Components/Auth";
import GoServer from "./Server/GoServer";
import Go from "./Server/Go";
import Env from "./utils/Env";

//import GoAuthRoles from "./layout/pages/Auth/GoAuthRoles";

class App extends Component {
  state = {
    loggedIn: false,
  };

  componentWillMount() {
    if (exists("admin")) {
      this.setState({ loggedIn: true });
    }
  }

  render() {
    let _route = Route.Route();

    if (_route === "reset") return <PasswordResetPage />;

    if (_route === "verify") return <Verify />;

    if (!this.state.loggedIn) {
      window.location = "/auth/?" + btoa(axios.defaults.baseURL);
      return;
    }

    let admin = get("admin");
    let user = admin.user;

    //todo: delete this
    if (Env.isDevelopment() && user.providerid != "3") {
      user.providerid = "3";
      admin.user = user;
      put("admin", admin);
    }
    //  alert(Go.students().firstOrDefault().getSchedules())

    //todo: Transport Under Development, Beta can access for now
    if (Go.getUser().noAdmin() && auth.no(auth.AccessBeta)) {
      // if not admin , then
      return <Lock message={"Transporter Panel is under maintenance, Contact admin.."} />;
    }

    if (_route === "init" || GoServer.isEndPointsBroken()) return <GoSync />;

    if (_route === "permissions") return <AuthPermissions />;

    const openNotificationWithIcon = (message) => {
      notification["info"]({
        message: "Change Log",
        description: message,
      });
    };

    if (!exists("version")) put("version", 2);

    // if (exists('admin') && get('version') !== version.name) {
    //     if (get('version') < 2) {
    //         localStorage.clear();
    //         window.location.reload();
    //         return;
    //     }
    // }

    if (window.location.href.includes("#?report=") && this.state.loggedIn) {
      document.body.style = "background: white;";
      let report = window.gup("report");
      if (report === "moing") {
        return <StorageExplorer />;
      }
      if (report === "audit") {
        return <Audit />;
      }

      if (report === "checkin") {
        return <Checkin />;
      }
      if (report === "provider-d") {
        return <ProviderDay />;
      }
      if (report === "agent-d") {
        return <AgentDay />;
      }
      if (report === "driver-d") {
        return <DriverDay />;
      }
      if (report === "invoice") {
        return <Invoice />;
      }
    }
    // Main App Layout
    return <AppLayout />;
  }
}

export default App;
