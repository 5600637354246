import React, { Component } from "react";
import { Card, Col, DatePicker, Icon, message, Row } from "antd";

import { get, map } from "../../../utils/Storage";
import moment from "moment";
import axios from "axios";
import Axios from "axios";
import Title from "antd/lib/typography/Title";
import auth from "../../../Components/Auth";
import GoWidget from "../../../Components/GoWidget";
import _ from "linq";

class Dashboard2 extends Component {
  state = {
    date: moment().format("YYYY-MM-DD"),
    prevMonth: moment().format("MM"),
    loading: true,
    trips: [],
    loading2: true,
    attendance: [],
    showStudents: false,
    time: this.UpdateTime(),
  };

  componentDidMount() {
    this.LoadData();
    setInterval(() => this.UpdateTime(), 1000);
  }

  UpdateTime() {
    // get a new date (locale machine date time)
    let date = new Date();
    // get the date as a string
    let n = date.toDateString();
    // get the time as a string
    let time = date.toLocaleTimeString();

    let ret = time + "~" + n;

    this.setState({ time: ret });

    return ret;
  }

  LoadData() {
    // Make a request for a user with a given ID
    this.setState({ loading: true });
    axios
      .get("/trips?Date=" + this.state.date)
      .then((response) => {
        let data = response.data.filter((r) =>
          r.StartedAt.includes(this.state.date)
        );
        if (get("admin").role === "Transporter") {
          let providerId = get("admin").user.providerid;
          data = data.filter((r) => {
            let schedule = map("schedules")[r.ScheduleId];
            let vehicle = map("vehicles")[schedule.VehicleId];
            return vehicle.ProviderId == providerId;
          });
        }
        this.setState({ trips: data, loading: false });
      })
      .catch((error) => {
        message.error(error.message);
        this.setState({ loading: false });
      });
    this.LoadData2();
  }

  LoadData2() {
    this.setState({ loading2: true });
    Axios.get("/attendance/list?date=" + this.state.date)
      .then((r) => {
        this.setState({ attendance: r.data });
      })
      .catch((e) => message.error(e.message))
      .finally(() => this.setState({ loading2: false }));
  }

  render() {
    let students = get("students").filter((r) => r.Status !== "Alumni");
    let schedules = get("schedules").filter((r) => r.Year === window.year);

    if (get("admin").role === "Transporter") {
      let providerId = get("admin").user.providerid;
      //filter schedulles to his
      schedules = schedules.filter((schedule) => {
        let vehicle = map("vehicles")[schedule.VehicleId];

        return vehicle.ProviderId == providerId;
      });

      //filter the students
      students = students.filter((r) => {
        let ans = false;
        r.schedules.forEach((schedule) => {
          let vehicle = map("vehicles")[schedule.VehicleId];
          if (vehicle.ProviderId == providerId) ans = true;
        });

        return ans;
      });
    }

    //filter students for the provider

    let alumni = get("students").filter((r) => r.Status === "Alumni");
    let students_active = students.filter((r) => r.Status === "Active");
    let students_transportation = students.filter(
      (r) => r.Status === "Active" && r.schedules.length > 0
    );
    let students_not_using_transportation = students.filter(
      (r) => r.Status === "Active" && r.schedules.length === 0
    );

    let schedules_active = this.state.trips.filter((r) => r.EndedAt === null);

    let schedules_completed = this.state.trips.filter(
      (r) => r.EndedAt !== null
    );
    let schedules_pending = schedules.length - this.state.trips.length;

    let schedules_morning = schedules.filter(
      (r) => r.Direction === "To School"
    );
    let schedules_active_morning = this.state.trips.filter(
      (r) => r.EndedAt === null && r.Direction === "To School"
    );
    let schedules_completed_morning = this.state.trips.filter(
      (r) => r.EndedAt !== null && r.Direction === "To School"
    );
    let schedules_pending_morning =
      schedules_morning.length -
      this.state.trips.filter((r) => r.Direction === "To School").length;

    let schedules_evening = schedules.filter(
      (r) => r.Direction === "From School"
    );
    let schedules_active_evening = this.state.trips.filter(
      (r) => r.EndedAt === null && r.Direction === "From School"
    );
    let schedules_completed_evening = this.state.trips.filter(
      (r) => r.EndedAt !== null && r.Direction === "From School"
    );
    let schedules_pending_evening =
      schedules_evening.length -
      this.state.trips.filter((r) => r.Direction === "From School").length;

    let checkins = this.state.attendance.filter((r) => r.Direction === "IN");
    let checkouts = this.state.attendance.filter((r) => r.Direction === "OUT");

    let checkins_transport = checkins.filter((r) => r.CheckinMode === "Bus");
    let checkouts_transport = checkouts.filter((r) => r.CheckinMode === "Bus");

    let checkins_gate = checkins.filter((r) => r.CheckinMode === "Gate");
    let checkouts_gate = checkouts.filter((r) => r.CheckinMode === "Gate");

    const datePicker = (
      <DatePicker
        allowClear={false}
        value={moment(this.state.date, "YYYY-MM-DD")}
        onChange={(val) => {
          this.state.date = val.format("YYYY-MM-DD");
          this.LoadData();
        }}
      />
    );

    let today = new Date();
    let curHr = today.getHours();
    let themoment = 0;
    if (curHr < 12) {
      themoment = 0;
    } else if (curHr < 18) {
      themoment = 1;
    } else {
      themoment = 2;
    }

    return (
      <div>
        <div style={{ background: "#F0F2F5", padding: "30px" }}>
          <Row gutter={16}>
            <Col span={24}>
              <Card
                bordered={false}
                style={{
                  title:
                    (get("admin").role === "Administrator" ? "" : "My") +
                    "  Transport Summary",
                  height: "120px",
                  boxShadow:
                    " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                  background:
                    "repeating-linear-gradient(to right, #2F505A, #01C68A)",

                  borderRadius: 30,
                  padding: 30,
                }}
              >
                <span
                  style={{
                    color: "#D2E6DB",
                    marginTop: -25,
                    marginLeft: -20,
                    width: "100%",
                    float: "left",
                  }}
                >
                  <div>
                    <h2 style={{ color: "#E2F8F1" }}>
                      <Icon type="google-circle" theme="filled" spin />
                      &nbsp;&nbsp;{window.school}
                    </h2>
                    <h5
                      style={{
                        color: "#89ABA9",
                        marginTop: -6,
                        marginLeft: 50,
                      }}
                    >
                      {get("admin").role}
                      &nbsp; &nbsp;
                      <Icon type="star" theme="filled" />
                      <Icon type="star" theme="filled" />
                      <Icon type="star" theme="filled" />
                      <Icon type="star" theme="filled" />
                      <Icon type="star" theme="filled" />
                    </h5>
                    {/*  <h6 style={{color: "whitesmoke", marginTop: 40, marginLeft: 50}}>*/}
                    {/*        <Icon type="check-circle"/>*/}
                    {/*      &nbsp; &nbsp;*/}
                    {/*      Hey {get('admin').user.name} have {0} notifications today.*/}
                    {/*</h6>*/}
                    <h5
                      style={{
                        color: "#276764",
                        float: "right",
                        marginTop: -45,
                        marginRight: -30,
                      }}
                    >
                      <Icon spin theme={"filled"} type="clock-circle" />
                      &nbsp;&nbsp;
                      <span>
                        {_.from(this.state.time.split("~"))
                          .select((r, i) => {
                            if (i === 0) return r;

                            return (
                              <span style={{ marginLeft: 30 }}>
                                <Icon type="calendar" theme="filled" />
                                &nbsp;&nbsp;{r}
                              </span>
                            );
                          })
                          .toArray()}
                      </span>
                    </h5>
                  </div>
                </span>
              </Card>
            </Col>
          </Row>
          <br />
          <Row hidden={auth.noView(auth.AccessTrips)} gutter={16}>
            <Card
              extra={datePicker}
              // title={(get('admin').role === "Administrator" ? "" : "My") + "  Transport Summary"}
              bordered={false}
              style={{ background: "#F0F2F5" }}
            >
              <Col span={6}>
                <a href={"#/trips?id=0&date=" + this.state.date}>
                  <GoWidget
                    color={"#7762FA"}
                    title={"Scheduled Trips"}
                    value={schedules.length}
                    icon={"calendar"}
                  />
                </a>
              </Col>
              <Col span={6}>
                <a href={"#/trips?id=1&date=" + this.state.date}>
                  <GoWidget
                    color={
                      moment().format("YYYY-MM-DD") === this.state.date
                        ? "#0383BF"
                        : "tomato"
                    }
                    title={
                      moment().format("YYYY-MM-DD") === this.state.date
                        ? "Pending Trips"
                        : "Trips Not Started"
                    }
                    icon={
                      moment().format("YYYY-MM-DD") === this.state.date
                        ? "hourglass"
                        : "question-circle"
                    }
                    value={schedules_pending}
                  />
                </a>
              </Col>
              <Col span={6}>
                <a href={"#/trips?id=2&date=" + this.state.date}>
                  <GoWidget
                    color={"#3AA4F4"}
                    title={"Active Trips"}
                    value={schedules_active.length}
                    spin={schedules_active.length > 0}
                    icon={"clock-circle"}
                  />
                </a>
              </Col>
              <Col span={6}>
                <a href={"#/trips?id=3&date=" + this.state.date}>
                  <GoWidget
                    color={"#01C68A"}
                    title="Completed Trips"
                    value={schedules_completed.length}
                    icon={"check-circle"}
                  />
                </a>
              </Col>
            </Card>
          </Row>

          <Row hidden={auth.noView(auth.AccessTrips)} gutter={16}>
            <Card bordered={false} style={{ backgroundColor: "#F0F2F5" }}>
              <Col span={12}>
                <Title level={4} disabled>
                  {"To School"}
                </Title>
                <Row gutter={10}>
                  <Col span={12}>
                    <a
                      href={
                        "#/trips?id=0&direction=To School&date=" +
                        this.state.date
                      }
                    >
                      <GoWidget
                        color={"#7762FA"}
                        title={"Scheduled Trips"}
                        value={schedules_morning.length}
                        icon={"calendar"}
                      />
                    </a>
                  </Col>
                  <Col span={12}>
                    <a
                      href={
                        "#/trips?id=1&direction=To School&date=" +
                        this.state.date
                      }
                    >
                      <GoWidget
                        color={
                          moment().format("YYYY-MM-DD") === this.state.date
                            ? "#0383BF"
                            : "tomato"
                        }
                        title={
                          moment().format("YYYY-MM-DD") === this.state.date
                            ? "Pending Trips"
                            : "Trips Not Started"
                        }
                        icon={
                          moment().format("YYYY-MM-DD") === this.state.date
                            ? "hourglass"
                            : "question-circle"
                        }
                        value={schedules_pending_morning}
                      />
                    </a>
                  </Col>
                </Row>

                <Row gutter={10} style={{ marginTop: 10 }}>
                  <Col span={12}>
                    <a
                      href={
                        "#/trips?id=2&direction=To School&date=" +
                        this.state.date
                      }
                    >
                      <GoWidget
                        color={"#3AA4F4"}
                        title="Active Trips"
                        value={schedules_active_morning.length}
                        spin={schedules_active_morning.length > 0}
                        icon={"clock-circle"}
                      />
                    </a>
                  </Col>
                  <Col span={12}>
                    <a
                      href={
                        "#/trips?id=3&direction=To School&date=" +
                        this.state.date
                      }
                    >
                      <GoWidget
                        color={"#01C68A"}
                        title="Completed Trips"
                        value={schedules_completed_morning.length}
                        icon={"check-circle"}
                      />
                    </a>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Title level={4} disabled>
                  {"From School"}
                </Title>
                <Row gutter={10}>
                  <Col span={12}>
                    <a
                      href={
                        "#/trips?id=0&direction=From School&date=" +
                        this.state.date
                      }
                    >
                      <GoWidget
                        color={"#7762FA"}
                        title={"Scheduled Trips"}
                        value={schedules_evening.length}
                        icon={"calendar"}
                      />
                    </a>
                  </Col>
                  <Col span={12}>
                    <a
                      href={
                        "#/trips?id=1&direction=From School&date=" +
                        this.state.date
                      }
                    >
                      <GoWidget
                        color={
                          moment().format("YYYY-MM-DD") === this.state.date
                            ? "#0383BF"
                            : "tomato"
                        }
                        title={
                          moment().format("YYYY-MM-DD") === this.state.date
                            ? "Pending Trips"
                            : "Trips Not Started"
                        }
                        icon={
                          moment().format("YYYY-MM-DD") === this.state.date
                            ? "hourglass"
                            : "question-circle"
                        }
                        value={schedules_pending_evening}
                      />
                    </a>
                  </Col>
                </Row>

                <Row
                  hidden={auth.noView(auth.AccessTrips)}
                  gutter={10}
                  style={{ marginTop: 10 }}
                >
                  <Col span={12}>
                    <a
                      href={
                        "#/trips?id=2&direction=From School&date=" +
                        this.state.date
                      }
                    >
                      <GoWidget
                        color={"#3AA4F4"}
                        title="Active Trips"
                        value={schedules_active_evening.length}
                        spin={schedules_active_evening.length > 0}
                        icon={"clock-circle"}
                      />
                    </a>
                  </Col>
                  <Col span={12}>
                    <a
                      href={
                        "#/trips?id=3&direction=From School&date=" +
                        this.state.date
                      }
                    >
                      <GoWidget
                        color={"#01C68A"}
                        title="Completed Trips"
                        value={schedules_completed_evening.length}
                        icon={"check-circle"}
                      />
                    </a>
                  </Col>
                </Row>
              </Col>
            </Card>
          </Row>

          {/* <Title level={4} disabled> Students Summary</Title> */}
          <Row hidden={auth.noView(auth.Students)} gutter={16}>
            <Card
              title={
                (get("admin").role === "Administrator" ? "" : "My ") +
                "Students Summary"
              }
              bordered={false}
              style={{ background: "#F0F2F5" }}
            >
              <Col span={6}>
                <a href="#/students">
                  <GoWidget
                    color={"#7762FA"}
                    title={
                      "All Students" +
                      (get("admin").role === "Administrator"
                        ? ""
                        : " (Assigned to me) ")
                    }
                    value={students.length}
                    icon={"team"}
                  />
                </a>
              </Col>
              <Col span={6}>
                <a href="#/students">
                  <GoWidget
                    color={"#3AA4F4"}
                    title="Active Students"
                    value={students_active.length}
                    icon={"team"}
                  />
                </a>
              </Col>
              <Col span={6}>
                <a href="#/students?page=Active">
                  <GoWidget
                    color={"#01C68A"}
                    title="Students Using Transportation"
                    value={students_transportation.length}
                    icon={"check-circle"}
                  />
                </a>
              </Col>
              <Col span={6}>
                <a href="#/students?page=Inactive">
                  <GoWidget
                    color={"#0383BF"}
                    title="Students Not Using Transportation"
                    value={students_not_using_transportation.length}
                    icon={"question-circle"}
                  />
                </a>
              </Col>
            </Card>
          </Row>

          {/* <Title level={4} disabled> Schedules Summary</Title>
                <Row gutter={16} hidden={get('admin').role !== "Administrator"}>
                <Card  title={(get('admin').role === "Administrator"? "":"My ")+"Attendance Summary"} bordered={false} style={{background: '#ECECEC'}}> 
                  <Col span={8}>
                  <Card loading={this.state.loading2}>
                  <a href="#/students"> <Statistic
                        title="Checked In Students"
                        value={checkins.length}
                        precision={0}
                        valueStyle={{ color: '#00AF50' }}
                        prefix={<Icon type="team" />} 
                      /></a>
                    </Card>
                  </Col>
                  <Col span={8}>
                  <Card loading={this.state.loading2}>
                  <a href="#/students"> <Statistic
                        title="Checked Out Students"
                        value={checkouts.length}
                        precision={0}
                        valueStyle={{ color: '#00AF50' }}
                        prefix={<Icon type="team" />}
                       
                      /></a>
                    </Card>
                  </Col>
                 
                  <Col span={8}>
                  <Card loading={this.state.loading2}>
                  <a href="#/students"><Statistic
                        title="On Premise Population"
                        value={(checkins.length - checkouts.length)}
                        precision={0}
                        valueStyle={{ color: '#00AF50' }}
                        prefix={<Icon type="bar-chart" />} 
                      /></a>
                    </Card>
                  </Col>
                  </Card>
                </Row> */}

          {/* <Row gutter={16}>
                <Card  bordered={false} style={{background: '#ECECEC'}}> 
                  <Col span={12}>
                  <Title level={4} disabled> Transport</Title>
                    <Row gutter={1}>
                    <Col span={12}>
                    <Card loading={this.state.loading}>
                    <a href="#/attendance">    <Statistic
                        title="Checked In"
                        value={checkins_transport.length}
                        precision={0}
                       
                        valueStyle={{ color: '#00AF50' }}
                        prefix={<Icon type="team" />}
                       
                      /></a>
                      
                    </Card>
                  </Col>
                  <Col span={12}>
                    <Card>
                    <a href="#/attendance">   <Statistic
                        title="Checked Out"
                        value={checkouts_transport.length}
                        precision={0}
                       
                        valueStyle={{ color: 'darkorange' }}
                        prefix={<Icon type="team" />} 
                      /></a>
                      
                    </Card>
                    </Col>
                    </Row>
                     
                   </Col>
                  <Col span={12} hidden={get('admin').role !== "Administrator"}>
                  <Title level={4} disabled>Gate</Title>
                  <Row gutter={1}>
                    <Col span={12}>
                    <Card>
                    <a href="#/attendance">      <Statistic
                        title="Checked In"
                        value={checkins_gate.length}
                        precision={0}
                        valueStyle={{ color: '#00AF50' }}
                        prefix={<Icon type="team" />}
                  
                      /></a>
                      
                    </Card>
                  </Col>
                  <Col span={12}>
                    <Card>
                    <a href="#/attendance">    <Statistic
                        title="Checked Out"
                        value={checkouts_gate.length}
                        precision={0}
                       
                        valueStyle={{ color: 'darkorange' }}
                        prefix={<Icon type="team" />} 
                      /></a>
                      
                    </Card>
                    </Col>
                    </Row>
                   </Col>
                  </Card>
                </Row> */}
        </div>
      </div>
    );
  }
}

export default Dashboard2;
