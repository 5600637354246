import React, {Component} from 'react';
import {setState, subscribe, getState, getInstance} from '../../utils/bunifu-redux';
import Bootsrap from './BootSrap';
import { Row, Col, Menu, Divider, Layout, Icon, PageHeader, Button, Tag, message, Radio, Popconfirm, Card, DatePicker, Select, Table, Avatar, Badge } from 'antd';  
import { get, remove, put } from '../../utils/Storage';
import JSONFormatter from 'json-formatter-js'
import copy from 'copy-to-clipboard';
import Search from 'antd/lib/input/Search';
class Audit extends Component {
state = {
   loading: false, 
   menu: true,
   search: '',
   user: null,
   users: [],
   
}
 componentWillMount() { 
    this.setState({
      users: get('users').filter(r=>r.roles.length>0)
    });
 }

 componentDidMount() {
   
 }
 componentDidUpdate() {
   
 }

 
 render() {
  var dataSource = [];
  for (let index = 0; index < 100; index++) {
     
    dataSource.push( {
      key: index,
      user: 'Kim Too Flex',
      action: 'insert',
      operation: 'Table `students` studentId: '+index,
      time: '19/1/2020 12:56:00'
    } )
    dataSource.push( {
      key: index,
      user: 'Kim Too Flex',
      action: 'delete',
      operation: 'Table `students` studentId: '+index,
      time: '19/1/2020 12:56:00'
    } )
    dataSource.push( {
      key: index,
      user: 'Kim Too Flex',
      action: 'update',
      operation: 'Table `students` studentId: '+index,
      time: '19/1/2020 12:56:00'
    } )
  }

  
  var colors = {
    insert: 'success',
    update: 'warning',
    delete: 'error',
  }
   
   
  const { Header, Footer, Sider, Content } = Layout;

return ( 
  <Layout style={{backgroundColor: 'white'}}>
   <Header style={{backgroundColor: '#00AF50', position: 'fixed', zIndex: 1, width: '100%' }}>
     <font style={{color: 'white',marginLeft: -30}} size = {4}><Icon type="security-scan" />&nbsp;&nbsp;<b>Bunifu Go</b> <span style={{color: '#E2F6EB'}}>| Audit Trail</span></font>
    </Header>
    <Layout style={{marginTop: 64 }}   >
     <Sider hidden={!this.state.menu}>
      <Menu
       onSelect={(val)=>{
          this.setState({
              user: get('users-map')[val.key] 
          });
       }}
       style={{ width: '100%' }}
       defaultSelectedKeys={[this.state.user]} 
       mode="inline"
       theme='dark'
     >
 <Search
     placeholder="input search text"
     onChange={value => this.setState({search: value.target.value})}
     style={{ width: '94%',marginTop: 5,marginLeft: 5 }}
   />
     <Menu.Item key={null}>{"All Users"}</Menu.Item> 
     {this.state.users.filter(r=>r.name.toLowerCase().includes(this.state.search)).map(r=>{
         return <Menu.Item key={r.id}>{r.name}</Menu.Item> 
        
     })}
        
        
     </Menu>
      </Sider>
       <Content>
       
    <PageHeader
     style={{
      border: '1px solid rgb(235, 237, 240)',
      borderStyle: 'none none solid none'
      
    }}
     
      tags={this.state.user && this.state.user.roles.map(r=><Tag>{r.name}</Tag>)}
      onBack={()=>this.setState({menu: !this.state.menu})}
      title={this.state.user? this.state.user.name: 'All Users'}
      backIcon={<Icon type={this.state.menu? "menu-fold": "menu-unfold" } />}
      extra={[ <Button icon='download' shape="round" key="1"  type="primary" onClick={()=>{
         
         message.loading("Exporting");
     }}>
        Export Excel
     </Button>]}
      
    >
      
    </PageHeader> 
     <Card bordered={false}>
        
       <DatePicker.RangePicker onChange={()=>{}} />&nbsp;
       <Select defaultValue="0" style={{ width: 120 }} onChange={()=>{}}>
       <Select.Option value="0">All Actions</Select.Option>
       <Select.Option value="1">Insert</Select.Option>
       <Select.Option value="2">Update</Select.Option>
       <Select.Option value="3">Delete</Select.Option>
       <Select.Option value="4">Login</Select.Option>
       <Select.Option value="5">Logout</Select.Option>
       <Select.Option value="5">Job</Select.Option>
       </Select>&nbsp;
       <Search
          placeholder="Filter Operation."
          onChange={value => this.setState({search: value.target.value})}
          style={{ width: 500,float: 'right' }} 
        />
        
        <br/>
        <br/> 
      <Row  type="flex">
        <Col span={24}>
         <Table dataSource={dataSource} columns={[
                                                {
                                                  title: '',
                                                  dataIndex: 'icon',
                                                  key: 'icon',
                                                  width: 50, 
                                                  render: val => <Avatar style={{ backgroundColor: '#00AF50'}}> <Icon type='user'/></Avatar>
                                                },
                                                {
                                                  title: 'User',
                                                  dataIndex: 'user',
                                                  key: 'user',
                                                  
                                                },
                                                {
                                                  title: 'Action',
                                                  dataIndex: 'action',
                                                  key: 'action',
                                                  render: val => <span><Badge dot   status={colors[val]}/>{val}</span>
                                                },
                                                {
                                                  title: 'Operation',
                                                  dataIndex: 'operation',
                                                  key: 'operation',
                                                },
                                                {
                                                  title: 'Time',
                                                  dataIndex: 'time',
                                                  key: 'time',
                                                },
                                                {
                                                  title: '',
                                                  dataIndex: 'id',
                                                  key: 'view',
                                                  width: 200,
                                                   render: val => <a style={{color: '#00AF50'}} onClick='javascript;'>View Payload</a>
                                                },
                                              ]} />
        </Col>
      </Row>
      </Card>
       </Content>
       </Layout>
     
</Layout>)
}
}
export default Audit;