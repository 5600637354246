import React from 'react';
import {Card, Col, Row} from "antd";
import GoWidget from "../../../Components/GoWidget";

class ProviderActivity extends React.Component {
    state = {
        provider: null
    }

    constructor(props) {
        super(props);

        this.state = {
            provider: props.provider,
            loading: false,
        };
    }

    render() {


        return (
            <>
                <Card
                    size={"small"}
                    // className={"shadow"}
                    bordered={true}
                    style={{width: "100%"}}>
                    <Row gutter={16 } style={{width: "100%"}}>
                        {/*<Col span={4}>*/}
                        {/*    <GoWidget title={"Active Trips"} value={0} spin={true}/>*/}
                        {/*</Col>*/}
                        <Col span={6} >
                            <GoWidget  title={"Subscribers"} icon={"dollar"} value={0}/>
                        </Col>
                        <Col span={6}>
                            <GoWidget title={"Schedules"} icon={"calendar"} value={10}/>
                        </Col>
                        <Col span={6}>
                            <GoWidget title={"Vehicles"} icon={"car"} value={10}/>
                        </Col>
                        <Col span={6}>
                            <GoWidget title={"Members"} icon={"usergroup-add"} value={10}/>
                        </Col>

                    </Row>
                </Card>
            </>
        )
    }


}

export default ProviderActivity;