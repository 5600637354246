///////////////////////////////////
//     DEV ENVIROMNT SETUP
//////////////////////////////////

export default {
    setEnvironment: (devdomains, dev, prod) => {
        let url = window.location.toString();
        let str = devdomains.toString().toLowerCase();
        let isDev = false;
        if (str.indexOf(",") > 0) {
            var domains = str.split(',');
            for (var curDomain in domains) {
                if (url.indexOf(domains[curDomain].trim().toLowerCase()) > 0) {
                    dev();
                    return;
                }
            }
        } else {
            if (url.indexOf(str.trim().toLowerCase()) > 0) {
                dev();
                return;
            }
        }

        prod();
    },
    isIframed: () => {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    },
    isDevelopment: (devDomains = ["localhost", "127.0.0.1"]) => {
        let url = window.location.toString();
        let str = devDomains.toString().toLowerCase();
        let isDev = false;
        if (str.indexOf(",") > 0) {
            var domains = str.split(',');
            for (var curDomain in domains) {
                if (url.indexOf(domains[curDomain].trim().toLowerCase()) > 0) {
                    return true;

                }
            }
        } else {
            if (url.indexOf(str.trim().toLowerCase()) > 0) {
                return true;
            }
        }

        return false;
    },

    isProduction: (devdomains) => {
        let url = window.location.toString();
        let str = devdomains.toString().toLowerCase();
        let isDev = false;
        if (str.indexOf(",") > 0) {
            var domains = str.split(',');
            for (var curDomain in domains) {
                if (url.indexOf(domains[curDomain].trim().toLowerCase()) > 0) {
                    return false;

                }
            }
        } else {
            if (url.indexOf(str.trim().toLowerCase()) > 0) {
                return false;
            }
        }

        return true;
    },
};


