import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  Button,
  Input,
  Alert,
  AutoComplete,
  Select,
  Empty,
  Spin,
  Col,
  Row,
} from "antd";
import {
  exportToExcel,
  exportToCSV,
  exportToPDF,
} from "../../utils/generateReports";
import "antd/dist/antd.css";

const { Option } = Select;

const columns = [
  {
    title: "ADM",
    dataIndex: "Adm",
    key: "adm",
    fixed: "left",
    width: 100,
  },
  {
    title: "STUDENT",
    dataIndex: "Student",
    key: "student",
    width: 150,
  },
  {
    title: "CLASS",
    dataIndex: "Class",
    key: "class",
    width: 100,
  },
  {
    title: "ROUTE",
    dataIndex: "Route",
    key: "route",
    width: 100,
  },
  {
    title: "STREAM",
    dataIndex: "Stream",
    key: "stream",
    width: 100,
  },
  {
    title: "SUBSCRIPTION",
    dataIndex: "Subscription",
    key: "subscription",
    width: 150,
    render: (text) => (Number.isFinite(text) ? text.toLocaleString() : text),
  },
  {
    title: "MODE",
    dataIndex: "Mode",
    key: "mode",
    width: 150,
  },
  {
    title: "DIRECTION",
    dataIndex: "Direction",
    key: "direction",
    width: 150,
  },
];

const StudentAttendance = () => {
  const [busy, setBusy] = useState(false);
  const [text, setText] = useState("Generate Report");
  const [results, setResults] = useState([]);
  const [error, setError] = useState("");
  const [token, setToken] = useState("");
  const [students, setStudents] = useState([]);
  const [providers, setProviders] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState(students);
  const [selectedStudent, setSelectedStudent] = useState("");
  const [direction, setDirection] = useState([]);
  const [startDate, setStartDate] = useState(
    new Date().toLocaleDateString("en-CA") // Ensures 'YYYY-MM-DD' format in local time
  );
  const [endDate, setEndDate] = useState(
    new Date().toLocaleDateString("en-CA")
  );

  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [routes, setRoutes] = useState([]);
  const [baseUrl, setBaseUrl] = useState("");
  const [selectedRoute, setSelectedRoute] = useState("");
  const [selectedProvider, setSelectedProvider] = useState("");
  const [adm, setAdm] = useState("");

  useEffect(() => {
    const account = JSON.parse(localStorage.getItem("account"));
    const BaseUrl = localStorage.getItem("api");

    if (account && account.access_token) {
      setToken(account.access_token);
    } else {
      setError("Missing authentication token. Please log in again.");
    }

    if (BaseUrl) {
      setBaseUrl(BaseUrl);
    } else {
      setError("Missing base URL. Please configure your settings.");
    }

    // Fetch filter data from local storage
    const storedStudents = JSON.parse(localStorage.getItem("students")) || [];
    const storedClasses = JSON.parse(localStorage.getItem("classes")) || [];
    const storedRoutes = JSON.parse(localStorage.getItem("routes")) || [];
    const storedProviders = JSON.parse(localStorage.getItem("providers")) || [];
    setStudents(storedStudents);
    setClasses(storedClasses);
    setRoutes(storedRoutes);
    setProviders(storedProviders);
  }, []);

  useEffect(() => {
    setFilteredStudents(students); // Set initial filtered students to all students
  }, [students]);

  const handleStudentSearch = (value) => {
    if (!value) {
      setFilteredStudents(students); // Display all students when the search field is empty
    } else {
      const lowercasedValue = value.toLowerCase();
      const filtered = students.filter((student) =>
        student.Name.toLowerCase().includes(lowercasedValue)
      );
      setFilteredStudents(filtered);
    }
  };

  const changeHandler = (text) => {
    setSelectedStudent(text);
  };

  const calculateTotals = (data) => {
    const uniqueAdmissions = new Set();
    const uniqueNames = new Set();
    const uniqueClasses = new Set();
    const uniqueRoutes = new Set();
    const uniqueStreams = new Set();
    const uniqueSubscriptions = new Set();
    const uniqueModes = new Set();
    const uniqueDirections = new Set();

    data.forEach((item) => {
      uniqueAdmissions.add(item.Adm);
      uniqueNames.add(item.Student);
      uniqueClasses.add(item.Class);
      uniqueRoutes.add(item.Route);
      uniqueStreams.add(item.Stream);
      uniqueSubscriptions.add(item.Subscription);
      uniqueModes.add(item.Mode);
      uniqueDirections.add(item.Direction);
    });

    return {
      Adm: `${uniqueAdmissions.size} Unique Admission Numbers`,
      Student: `${uniqueNames.size} Unique Students`,
      Class: `${uniqueClasses.size} Unique Classes`,
      Route: `${uniqueRoutes.size} Unique Routes`,
      Stream: `${uniqueStreams.size} Unique Streams`,
      Subscription: `${uniqueSubscriptions.size} Unique Subscriptions`,
      Mode: `${uniqueModes.size} Unique Modes`,
      Direction: `${uniqueDirections.size} Unique Directions`,
    };
  };

  const generateReport = async () => {
    setResults([]);
    setBusy(true);
    setText("Fetching data...");
    try {
      const url = `${baseUrl}/reports/student-attendance-activity?Student=${selectedStudent}&Provider=${selectedProvider}&Route=${selectedRoute}&Direction=${direction}&Class=${selectedClass}&StartDate=${startDate}&EndDate=${endDate}`;

      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const { data } = await axios.get(url, { headers });

      if (data.data.length === 0) {
        setResults([]);
      } else {
        const totalRow = calculateTotals(data.data);
        setResults([...data.data, totalRow]); // Append the total row at the end
      }
    } catch (error) {
      setError(
        `Error fetching data: ${error.response?.data?.message || error.message}`
      );
    } finally {
      setBusy(false);
      setText("Generate Report");
    }
  };

  return (
    <div style={{ marginTop: 30 }}>
      <h4 style={{ textAlign: "center", marginBottom: 20 }}>
        Student Attendance Activity
      </h4>{" "}
      <div style={{ width: "50%", margin: "0 auto", marginTop: 30 }}>
        <Row gutter={[16, 16]} style={{ marginBottom: "40px" }}>
          <Col span={12}>
            <label htmlFor="datePicker" style={{ paddingRight: 10 }}>
              Start Date
            </label>
            <Input
              type="date"
              cursor="pointer"
              size="large"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              style={{ width: "100%" }}
            />
          </Col>

          <Col span={12}>
            <label htmlFor="datePicker" style={{ paddingRight: 10 }}>
              End Date
            </label>
            <Input
              type="date"
              cursor="pointer"
              size="large"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              style={{ width: "100%" }}
            />
          </Col>

          <Col span={12}>
            <label htmlFor="students" style={{ paddingRight: 10 }}>
              Students
            </label>
            <AutoComplete
              placeholder="Search for a student..."
              style={{ width: "100%" }}
              onSearch={handleStudentSearch}
              onChange={changeHandler}
              value={selectedStudent}
              size="large"
            >
              {filteredStudents.map((student) => (
                <AutoComplete.Option key={student.id} value={student.Adm}>
                  {student.Name}
                </AutoComplete.Option>
              ))}
            </AutoComplete>
          </Col>

          <Col span={12}>
            <label htmlFor="provider" style={{ paddingRight: 10 }}>
              Provider
            </label>
            <Select
              placeholder="Select Provider"
              size="large"
              style={{ width: "100%" }}
              defaultValue={"All"}
              onChange={(value) => setSelectedProvider(value)}
            >
              {providers.map((provider) => (
                <Option key={provider.id} value={provider.Name}>
                  {provider.Name}
                </Option>
              ))}
            </Select>
          </Col>

          <Col span={12}>
            <label htmlFor="class" style={{ paddingRight: 10 }}>
              Class
            </label>
            <Select
              placeholder="Select Class"
              defaultValue="" // Empty default value for All classes
              size="large"
              style={{ width: "100%" }}
              onChange={(value) => setSelectedClass(value)}
              allowClear
            >
              {/* Option for All classes */}
              <Option value="">All</Option>
              {classes.map((classItem) => (
                <Option key={classItem} value={classItem}>
                  {classItem}
                </Option>
              ))}
            </Select>
          </Col>

          <Col span={12}>
            <label htmlFor="routes" style={{ paddingRight: 10 }}>
              Routes
            </label>
            <Select
              placeholder="Select Route"
              size="large"
              style={{ width: "100%" }}
              defaultValue={"All"}
              onChange={(value) => setSelectedRoute(value)}
            >
              {routes.map((route) => (
                <Option key={route.id} value={route.Name}>
                  {route.Name}
                </Option>
              ))}
            </Select>
          </Col>

          <Col span={12}>
            <label htmlFor="direction" style={{ paddingRight: 10 }}>
              Direction
            </label>
            <Select
              placeholder="Select Direction"
              size="large"
              style={{ width: "100%" }}
              defaultValue={"All"}
              onChange={(value) => setDirection(value)}
            >
              <Option value="All">ALL</Option>
              <Option value="IN">IN</Option>
              <Option value="OUT">OUT</Option>
            </Select>
          </Col>

          <Col span={12}>
            <Button
              type="primary"
              size="large"
              onClick={generateReport}
              loading={busy}
              disabled={!token || busy}
              style={{ width: "100%", marginTop: "26px" }}
            >
              {text}
            </Button>
          </Col>
        </Row>
      </div>
      <hr />
      <Spin spinning={busy}>
        {results.length > 0 ? (
          <div
            style={{
              width: "80%",
              margin: "auto",
              marginTop: "40px",
              overflowX: "auto",
            }}
          >
            <Row justify="end" style={{ marginBottom: 20 }}>
              <Col>
                <Button
                  type="primary"
                  onClick={() =>
                    exportToExcel(results, "StudentAttendanceActivity")
                  }
                  style={{ marginRight: 10 }}
                >
                  Export to Excel
                </Button>
                <Button
                  type="primary"
                  onClick={() =>
                    exportToCSV(results, "StudentAttendanceActivity")
                  }
                  style={{ marginRight: 10 }}
                >
                  Export to CSV
                </Button>
                <Button
                  type="primary"
                  onClick={() =>
                    exportToPDF(
                      columns,
                      results,
                      "StudentAttendanceActivity",
                      "Student Attendance Activity Summary",
                      [0, 175, 80],
                      "/img/go-green.png",
                      startDate,
                      endDate
                    )
                  }
                >
                  Export to PDF
                </Button>
              </Col>
            </Row>
            <Table
              dataSource={results}
              columns={columns}
              bordered
              scroll={{ x: 1300 }}
              style={{ marginTop: 20 }}
              pagination={{ pageSize: 50 }}
            />
          </div>
        ) : (
          <div style={{ textAlign: "center", marginTop: 40 }}>
            <Empty
              description="No data available. Please generate a report."
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          </div>
        )}
      </Spin>
    </div>
  );
};

export default StudentAttendance;
