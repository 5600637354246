window.setState = function (targetModuleName, state, forceUpdate = false) {
    if (window.mutex[targetModuleName] == null) {
        console.error("BUNIFU REDUX ERROR: [" + targetModuleName + "] not subscribed to global state");
        return;
    }

    if (forceUpdate) {
        var ostate = window.mutex[targetModuleName].state;
        window.mutex[targetModuleName].state = {...ostate, ...state}
    }
    window.mutex[targetModuleName].setStateOld(state);
}

window.getState = function (targetModuleName) {
    //get the state and add to array
    if (window.mutex[targetModuleName] != null) {
        return window.mutex[targetModuleName].state;
    }
    console.error("BUNIFU REDUX ERROR: [" + targetModuleName + "] not subscribed to global state");
};


window.mutex = [];

export function subscribe(targetModule) {

    let targetModuleName = "";

    try {
        targetModuleName = targetModule.state.name;
    } catch (error) {
        console.error("BUNIFU REDUX ERROR: [" + this.__proto__.constructor.name + "] does not have u [state.name]");
        return;
    }


    //get the state and add to array
    if (window.mutex[targetModuleName] == null && typeof targetModule != "string") {
        window.mutex[targetModuleName] = targetModule;
        targetModule.state.name = targetModuleName;
        targetModule.setStateOld = targetModule.setState;
        targetModule.setState = function (state, forceUpdate = false) {
            if (window.mutex[this.state.name] == null) {
                console.error("BUNIFU REDUX ERROR: [" + this.state.name + "] not subscribed to global state");
                return;
            }

            if (forceUpdate) {
                var ostate = window.mutex[this.state.name].state;
                window.mutex[this.state.name].state = {...ostate, ...state}
            }
            window.mutex[this.state.name].setStateOld(state);
        }

    }

}


export function setState(targetModuleName, newState = null) {
    //get the state and add to array
    if (window.mutex[targetModuleName] != null) {
        window.mutex[targetModuleName].setState(newState);
        return;
    }
    console.error("BUNIFU REDUX ERROR: [" + targetModuleName + "] not subscribed to global state");
}

export function getState(targetModuleName) {
    //get the state and add to array
    if (window.mutex[targetModuleName] != null) {
        return window.mutex[targetModuleName].state;
    }
    console.error("BUNIFU REDUX ERROR: [" + targetModuleName + "] not subscribed to global state");
}


export function getInstance(targetModuleName) {
    //get the state and add to array
    if (window.mutex[targetModuleName] != null) {
        return window.mutex[targetModuleName];
    }
    console.error("BUNIFU REDUX ERROR: [" + targetModuleName + "] not subscribed to global state");
}


export function dumb(targetModuleName = "") {
    if (!targetModuleName) return;

    //get the state and add to array
    if (targetModuleName == "") {
        return;
    }

    if (window.mutex[targetModuleName + "state"] != null) {
        return;
    }
    console.error("BUNIFU REDUX ERROR: [" + targetModuleName + "] not subscribed to global state");
}
  