import React from 'react';
import Ant from 'antd';

const SearchCol = (sender, dataIndex) => {
    return {
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
            <div style={{padding: 8}}>
                <Ant.Input.Search
                    ref={node => {
                        sender.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex} ...`}
                    value={selectedKeys[0]}
                    onChange={e => {
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                        if (e.target.value.length === 0) {
                            clearFilters()
                        }
                    }}
                    onSearch={() => confirm()}
                    enterButton
                    style={{width: 188, marginBottom: 8, display: 'block'}}
                />
            </div>
        ),
        filterIcon: filtered => (
            <Ant.Icon type="search" style={{color: filtered ? '#20B965' : undefined}}/>
        ),
        onFilter: (value, record) => {
            if(!record[dataIndex])
                return false;
            return record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase())
        }
    };
}

export default SearchCol;
