import React, {Component} from 'react';
import {setState, subscribe, getState, getInstance} from '../../utils/bunifu-redux';
import { DatePicker, Select, message, Rate, Badge } from 'antd';

import {get,put,exists,remove, keyValue} from "../../utils/Storage";
import moment from 'moment';
import Axios from 'axios';
import Bootsrap from './BootSrap';

class AgentDay extends Component {
state = {
   name: 'ProviderDay',
    view: 0,
   loading: false,
   date:   moment().format('YYYY-MM-DD'),//,
   data: [],
   provider: get('admin').role === "Administrator" ? '' : get('providers-map')[get('admin').user.providerid].Name
}
 componentWillMount() {
  subscribe(this);
}
componentDidMount() {
  this.LoadData();
}
LoadData() {
  // Make a request for a user with a given ID
  this.setState({loading: true});
      Axios.get('/trips?Date='+this.state.date+"&showStudents=true")
      .then(function (response) {
         this.setState({data: response.data,loading: false});
      }.bind(this))
      .catch(function (error) {
        message.error(error.message);
        this.setState({loading: false});
      }.bind(this));

}
 render() {
   if(this.state.loading){
            return(<div align='center' style={{width: '100%'}} class="container" >
              <Bootsrap/>
            <br/><br/>  <br/><br/>
            <div class="spinner-grow text-success" role="status">
              <span class="sr-only"></span>
            </div><div class="spinner-grow text-success" role="status">
              <span class="sr-only"></span>
            </div><div class="spinner-grow text-success" role="status">
              <span class="sr-only">Please Wait...</span>
            </div>
            </div>);
   }



   //do filtering here
var data = this.state.data;

    data = data.filter(r=>r.StartedAt.includes(this.state.date));
 

var morning  = data.filter((trip)=> trip.Direction==='To School');
var evening  = data.filter((trip)=> trip.Direction==='From School');
 
var morningSchedulles  = get('schedules').filter((trip)=> trip.Direction==='To School').filter(s=> {
  let A = moment(this.state.date);
  let B = moment(s.created_at);
    return  B.diff(A, 'days') <= 0
 });;
var eveningSchedulles  = get('schedules').filter((trip)=> trip.Direction==='From School').filter(s=> {
  let A = moment(this.state.date);
  let B = moment(s.created_at);
    return  B.diff(A, 'days') <= 0
 });;
  
 
if(this.state.provider.length>0) {
  morning = morning.filter(r => get('vehicles-map')[ get('schedules-map')[r.ScheduleId].VehicleId].Provider === this.state.provider );
  evening = evening.filter(r => get('vehicles-map')[ get('schedules-map')[r.ScheduleId].VehicleId].Provider === this.state.provider );
    
    var morningSchedulles  = morningSchedulles.filter(r => get('vehicles-map')[ r.VehicleId].Provider === this.state.provider );
    var eveningSchedulles  = eveningSchedulles.filter(r => get('vehicles-map')[ r.VehicleId].Provider === this.state.provider );
} 

return (
<div  class="container">
<Bootsrap/>
  <nav class="navbar navbar-light bg-light hidden-print">
      <a class="navbar-brand"> </a>
      <form class="form-inline">
      <DatePicker size={"large"} defaultValue={moment(this.state.date, 'YYYY-MM-DD')} format={'YYYY-MM-DD'}  onChange={(val)=>{ this.state.date = val.format('YYYY-MM-DD');}} />&nbsp;
      <Select
          showSearch
          defaultValue={this.state.view}
          size={"large"}
          style={{ width: 200 }}
          placeholder="Select Provider"
          optionFilterProp="children"
          onChange={(val)=>{ this.state.view=val;}}  
        >
          <Select.Option value={0}>Day</Select.Option>
          <Select.Option value={1}>Morning (To School)</Select.Option>
          <Select.Option value={2}>Evening (From School)</Select.Option>
      </Select> &nbsp;
       <span hidden={get('admin').role !== "Administrator"}> 
       <Select
          showSearch
          defaultValue={this.state.provider}
          size={"large"}
          style={{ width: 200 }}
          placeholder="Select Provider"
          optionFilterProp="children"
          onChange={(val)=>{ this.state.provider=val;}}  
        >
          <Select.Option value={''}>All Providers</Select.Option>
          {
             get('providers').map(r=>{
                return <Select.Option value={r.Name}>{r.Name}</Select.Option>
             })
          }
        </Select></span>&nbsp;
        <button class="btn btn-outline-success my-2 my-sm-0" type="submit" onClick={()=>this.LoadData()}>Generate Report</button>
      </form>
    </nav>
    <br/><br/>
    <img src="../img/logo2.png"  width="150"/>
    <hr/>
    <h5><b>Agent Monitor Daily Report<span style={{float: 'right'}}>{moment(this.state.date, 'YYYY-MM-DD').format('LL')}</span></b> </h5>  
         
      <hr/> 
      <span hidden={this.state.view===2}>
     <h6><b>Morning Schedule (To School)</b></h6> 
    <table class="table table-striped table-sm">
  <thead  style={{backgroundColor: '#00AF50'}}>
    <tr>
      <th scope="col">TripNo</th>
      <th scope="col">Agent</th>
      <th scope="col">Vehicle</th>   
      <th scope="col">Provider</th>
      <th scope="col">Route</th>  
      <th scope="col">Trip</th> 
      <th scope="col">StartedTrip</th>
      <th scope="col">Expected</th>
      <th scope="col">Absent</th>
      <th scope="col">Excess</th>
      <th scope="col">CheckedIn</th>
      <th scope="col">Onboard</th>
      <th scope="col">Alighted</th>
      <th scope="col">EndedTrip</th> 
      
    </tr>
  </thead>
  <tbody>
     
   {

      
      
     morning.map((trip,idx)=>{
       
       var schedulle  = get('schedules-map')[trip.ScheduleId];
       var Vehicle = get('vehicles-map')[schedulle.VehicleId];
       var route = get("routes-map")[trip.RouteId];
       var stations = route.stations;
       var students =  trip.Students; 

       var estudents =  get('students').filter(r=>r.schedules.filter(rr=>rr.id===schedulle.id).length>0);
 

       var expected =students;
       var present =students.filter(r=>r.BoardedAt !== null);
       var onboard =students.filter(r=>r.BoardedAt !== null && r.AlightedAt===null);
       var alighted =students.filter(r=>r.BoardedAt !== null && r.AlightedAt!==null);
       var absent =estudents.length - students.length;//students.filter(r=>r.BoardedAt === null);
       
       var Agent = get('users-map')[schedulle.AttendantId]; 
        morningSchedulles.splice(morningSchedulles.findIndex(e => e.id === schedulle.id),1);
        
        
       return   <tr>
                  <th scope="row">  
                   <Badge
                       overflowCount = {9999}
                      count={trip.TripId}
                      style={{backgroundColor: trip.StartedAt!==null && trip.EndedAt!==null  ?  'green' : trip.StartedAt === null ?  'red' : 'darkorange' }}
                  /> </th>
                  <td style={{color: trip.StartedAt!==null && trip.EndedAt!==null  ?  'green' : 'red' }}>{Agent.name}</td>
                  <td>{Vehicle.PlateNo}</td>
                  <td>{Vehicle.Provider}</td>
                  <td>{trip.Route}</td>   
                  <td>{schedulle.Name}</td>   
                  <td style={{color: trip.StartedAt!==null ?  'green' : 'red' }}>{ trip.StartedAt!==null ? window.getTimeFromDate(trip.StartedAt): 'Not Started'}</td>
                  <td>{estudents.length}</td> 
                  <td>{absent>0 ? absent: 0}</td> 
                  <td>{absent <0 ? "+"+(absent*-1): 0}</td> 
                  <td  style={{color: present.length === students.length ?  'green' : 'darkorange'}}>{present.length+"/"+students.length}</td> 
                  <td>{onboard.length+"/"+present.length}</td> 
                  <td  style={{color: alighted.length === present.length ?  'green' : 'darkorange'}}>{alighted.length+"/"+present.length}</td> 
                  <td style={{color: trip.EndedAt!==null ?  'green' : 'red' }}>{trip.EndedAt !== null ? window.getTimeFromDate(trip.EndedAt): 'Not Ended'}</td> 
              </tr>
       })
   }   
     {
       morningSchedulles.map((schedulle,idx)=>{ 

        var Vehicle = get('vehicles-map')[schedulle.VehicleId];
        var route = get("routes-map")[schedulle.RouteId];
        var stations = route.stations;
        var students =  get('students').filter(r=>r.schedules.filter(rr=>rr.id===schedulle.id).length>0);
 
 
 
        var expected =students;
        
        
        var Agent = get('users-map')[schedulle.AttendantId]; 
       
      return  <tr>
        <th scope="row">  
         <Badge
             count={"X"}
            style={{backgroundColor:  'red' }}
        /> </th>
        <td style={{color: 'red'}}>{Agent.name}</td>
        <td  style={{color:  'red'}}>{Vehicle.PlateNo}</td>
        <td  style={{color:  'red'}}>{Vehicle.Provider}</td>
        <td  style={{color:  'red'}}>{route.Name}</td>   
        <td  style={{color:  'red'}}>{schedulle.Name}</td>   
        <td style={{color: 'red' }}>{'Not Started'}</td>
        <td  style={{color:  'red'}}>{students.length}</td> 
        <td  style={{color:  'red'}}>{students.length}</td> 
        <td  style={{color: 'red'}}>{0}</td> 
        <td  style={{color: 'red'}}>{0}</td> 
        <td  style={{color:  'red'}}>{0}</td> 
        <td  style={{color:  'red'}}>{0}</td> 
        <td style={{color:  'red' }}>{'Not Ended'}</td> 
    </tr>
       })
     }

  </tbody>
  
</table>
</span>
 <span hidden={this.state.view===1}>
<br/>
<hr/>   
     <h6><b>Evening Schedule (From School)</b></h6> 
     <table class="table table-striped table-sm">
     <thead  style={{backgroundColor: '#00AF50'}}>
    <tr>
    <th scope="col">TripNo</th>
      <th scope="col">Agent</th>
      <th scope="col">Vehicle</th>   
      <th scope="col">Provider</th>
      <th scope="col">Route</th>  
      <th scope="col">Trip</th> 
      <th scope="col">StartedTrip</th>
      <th scope="col">Expected</th>
      <th scope="col">Absent</th>
      <th scope="col">Excess</th>
      <th scope="col">CheckedIn</th>
      <th scope="col">Onboard</th>
      <th scope="col">Alighted</th>
      <th scope="col">EndedTrip</th> 
    </tr>
  </thead>
  <tbody>
     
  {
evening.map((trip,idx)=>{

  var schedulle  = get('schedules-map')[trip.ScheduleId];
  var Vehicle = get('vehicles-map')[schedulle.VehicleId];
  var route = get("routes-map")[trip.RouteId];
  var stations = route.stations;
  var students =  trip.Students; 
  var estudents =  get('students').filter(r=>r.schedules.filter(rr=>rr.id===schedulle.id).length>0);
 


  var expected =students;
  var present =students.filter(r=>r.BoardedAt !== null);
  var onboard =students.filter(r=>r.BoardedAt !== null && r.AlightedAt===null);
  var alighted =students.filter(r=>r.BoardedAt !== null && r.AlightedAt!==null);
  var absent =estudents.length - students.length;//students.filter(r=>r.BoardedAt === null);
       
   var Agent = get('users-map')[schedulle.AttendantId];
   eveningSchedulles.splice(eveningSchedulles.findIndex(e => e.id === schedulle.id),1);
   
  return   <tr>
             <th scope="row">  
              <Badge
                  overflowCount = {9999}
                 count={trip.TripId}
                 style={{backgroundColor: trip.StartedAt!==null && trip.EndedAt!==null  ?  'green' : trip.StartedAt === null ?  'red' : 'darkorange' }}
             /> </th>
             <td style={{color: trip.StartedAt!==null && trip.EndedAt!==null  ?  'green' : 'red' }}>{Agent.name}</td>
             <td>{Vehicle.PlateNo}</td>
             <td>{Vehicle.Provider}</td>
             <td>{trip.Route}</td>   
             <td>{schedulle.Name}</td>   
             <td style={{color: trip.StartedAt!==null ?  'green' : 'red' }}>{ trip.StartedAt!==null ? window.getTimeFromDate(trip.StartedAt): 'Not Started'}</td>
             <td>{estudents.length}</td> 
             <td>{absent>0 ? absent: 0}</td> 
             <td>{absent <0 ? "+"+(absent*-1): 0}</td>
             <td  style={{color: present.length === students.length ?  'green' : 'darkorange'}}>{present.length+"/"+students.length}</td> 
             <td>{onboard.length+"/"+present.length}</td> 
             <td  style={{color: alighted.length === present.length ?  'green' : 'darkorange'}}>{alighted.length+"/"+present.length}</td> 
             <td style={{color: trip.EndedAt!==null ?  'green' : 'red' }}>{trip.EndedAt !==null ? window.getTimeFromDate(trip.EndedAt): 'Not Ended'}</td> 
          
            
           </tr>
  })
}   

{
       eveningSchedulles.map((schedulle,idx)=>{ 

        var Vehicle = get('vehicles-map')[schedulle.VehicleId];
        var route = get("routes-map")[schedulle.RouteId];
        var stations = route.stations;
        var students =  get('students').filter(r=>r.schedules.filter(rr=>rr.id===schedulle.id).length>0);
 
 
 
        var expected =students;
        var present =students.filter(r=>r.BoardedAt !== null);
        var onboard =students.filter(r=>r.BoardedAt !== null && r.AlightedAt===null);
        var alighted =students.filter(r=>r.BoardedAt !== null && r.AlightedAt!==null);
        var absent =students.filter(r=>r.BoardedAt === null);
        
        var Agent = get('users-map')[schedulle.AttendantId]; 
       
        return  <tr>
        <th scope="row">  
         <Badge
            count={"X"}
            style={{backgroundColor:  'red' }}
        /> </th>
        <td style={{color: 'red'}}>{Agent.name}</td>
        <td  style={{color:  'red'}}>{Vehicle.PlateNo}</td>
        <td  style={{color:  'red'}}>{Vehicle.Provider}</td>
        <td  style={{color:  'red'}}>{route.Name}</td>   
        <td  style={{color:  'red'}}>{schedulle.Name}</td>   
        <td style={{color: 'red' }}>{'Not Started'}</td>
        <td  style={{color:  'red'}}>{students.length}</td> 
        <td  style={{color:  'red'}}>{students.length}</td> 
        <td  style={{color: 'red'}}>{0}</td> 
        <td  style={{color: 'red'}}>{0}</td> 
        <td  style={{color:  'red'}}>{0}</td> 
        <td  style={{color:  'red'}}>{0}</td> 
        <td style={{color:  'red' }}>{'Not Ended'}</td> 
    </tr>
       })
     }

  </tbody>
    
</table> 
</span>
</div>)
}
}
export default AgentDay;