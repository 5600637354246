import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Button, Input, Select, Empty, Spin, Col, Row } from "antd";
import {
  exportToExcel,
  exportToCSV,
  exportToPDF,
} from "../../utils/generateReports";
const { Option } = Select;

const VehicleSummary = ({
  onComplete,
  vehicleName: initialVehicleName,
  metric: initialMetric,
  minTrips: initialMinTrips,
  percentage: initialPercentage,
}) => {
  const columns = [
    {
      title: "Adm",
      dataIndex: "Adm",
      key: "adm",
    },
    {
      title: "Name",
      dataIndex: "Name",
      key: "name",
    },
    {
      title: "Class",
      dataIndex: "Class",
      key: "class",
    },
    {
      title: "Stream",
      dataIndex: "Stream",
      key: "stream",
    },
    {
      title: "Route",
      dataIndex: "RouteName",
      key: "route",
    },
    {
      title: "Station",
      dataIndex: "StationName",
      key: "station",
    },
    {
      title: "To School Schedules",
      dataIndex: "VehicleScheduledToSchool",
      key: "toSchoolSchedules",
      render: (text) => (Number.isFinite(text) ? text.toLocaleString() : "0"), // Comma delimiter
    },
    {
      title: "From School Schedules",
      dataIndex: "VehicleScheduledFromSchool",
      key: "fromSchoolSchedules",
      render: (text) => (Number.isFinite(text) ? text.toLocaleString() : "0"), // Comma delimiter
    },
    {
      title: "All Direction Vehicle Count",
      dataIndex: "NoOfVehicles",
      key: "allDirectionVehicleCount",
      render: (text) => (Number.isFinite(text) ? text.toLocaleString() : "0"), // Comma delimiter
    },
    {
      title: "Subscription",
      dataIndex: "Subscription",
      key: "subscription",
    },
    {
      title: "To School Count",
      dataIndex: "NoOfTripsToSchool",
      key: "toSchoolCount",
      render: (text) => (Number.isFinite(text) ? text.toLocaleString() : "0"), // Comma delimiter
    },
    {
      title: "From School Count",
      dataIndex: "NoOfTripsFromSchool",
      key: "fromSchoolCount",
      render: (text) => (Number.isFinite(text) ? text.toLocaleString() : "0"), // Comma delimiter
    },
    {
      title: "Total Trips",
      key: "totalTrips",
      render: (text, record) => {
        const totalTrips =
          (record.NoOfTripsToSchool || 0) + (record.NoOfTripsFromSchool || 0);
        return totalTrips.toLocaleString(); // Comma delimiter for total trips
      },
    },
    {
      title: "Cost (KES)",
      dataIndex: "Cost",
      key: "costKES",
      render: (text) => (Number.isFinite(text) ? text.toLocaleString() : "0"), // Comma delimiter
    },
    {
      title: "Per Vehicle Cost (KES)",
      dataIndex: "PerVehicleCost",
      key: "perVehicleCost",
      render: (text) => (Number.isFinite(text) ? text.toLocaleString() : "0"), // Comma delimiter
    },
  ];

  const [busy, setBusy] = useState(false);
  const [text, setText] = useState("Generate Report");
  const [results, setResults] = useState([]);
  const [token, setToken] = useState("");
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState(
    new Date().toLocaleDateString("en-CA") // Ensures 'YYYY-MM-DD' format in local time
  );
  const [endDate, setEndDate] = useState(
    new Date().toLocaleDateString("en-CA")
  );

  const [baseUrl, setBaseUrl] = useState("");
  const [vehicleName, setVehicleName] = useState(initialVehicleName || "");
  const [vehicles, setVehicles] = useState([]);
  const [metric, setMetric] = useState("Schedules");
  const [minTrips, setMinTrips] = useState(initialMinTrips || 5);
  const [percentage, setPercentage] = useState(initialPercentage || 12);
  const [tranche, setTranche] = useState(3);

  useEffect(() => {
    const account = JSON.parse(localStorage.getItem("account"));
    const BaseUrl = localStorage.getItem("api");
    const storedVehicles = JSON.parse(localStorage.getItem("vehicles"));
    if (account && account.access_token) {
      setToken(account.access_token);
    } else {
      setError("Missing authentication token. Please log in again.");
    }
    if (storedVehicles) {
      setVehicles(storedVehicles);
    }
    if (storedVehicles.length > 0) {
      setVehicleName(storedVehicles[0].PlateNo);
    }
    if (BaseUrl) {
      setBaseUrl(BaseUrl); // Fixed the case-sensitive issue
    } else {
      setError("Missing base URL. Please configure your settings.");
    }
  }, []);

  const calculateTotals = (data) => {
    let totalToSchoolCount = 0;
    let totalFromSchoolCount = 0;
    let totalCost = 0;
    let totalPerVehicleCost = 0;

    const uniqueAdms = new Set();
    const uniqueNames = new Set();
    const uniqueClasses = new Set();
    const uniqueStreams = new Set();
    const uniqueRoutes = new Set();
    const uniqueStations = new Set();
    const uniqueSubscriptions = new Set();
    const uniqueToSchoolSchedule = new Set();
    const uniqueFromSchoolSchedule = new Set();
    const uniqueNoOfVehicles = new Set();

    data.forEach((item) => {
      totalToSchoolCount += item.NoOfTripsToSchool || 0;
      totalFromSchoolCount += item.NoOfTripsFromSchool || 0;
      totalCost += item.Cost || 0;
      totalPerVehicleCost += item.PerVehicleCost || 0;

      // Add to the sets to track unique values
      uniqueAdms.add(item.Adm);
      uniqueNames.add(item.Name);
      uniqueClasses.add(item.Class);
      uniqueStreams.add(item.Stream);
      uniqueRoutes.add(item.RouteName);
      uniqueStations.add(item.StationName);
      uniqueSubscriptions.add(item.Subscription);
      uniqueToSchoolSchedule.add(item.VehicleScheduledToSchool);
      uniqueFromSchoolSchedule.add(item.VehicleScheduledFromSchool);
      uniqueNoOfVehicles.add(item.NoOfVehicles);
    });

    return {
      // Adm: `Total`,
      Name: `${uniqueNames.size} Unique Students`,
      Class: `${uniqueClasses.size} Unique Classes`,
      Stream: `${uniqueStreams.size} Unique Streams`,
      RouteName: `${uniqueRoutes.size} Unique Routes`,
      StationName: `${uniqueStations.size} Unique Stations`,
      VehicleScheduledToSchool: `${uniqueToSchoolSchedule.size} unique ToSchool Schedule`,
      VehicleScheduledFromSchool: `${uniqueFromSchoolSchedule.size} unique FromSchool Schedule`,
      NoOfVehicles: `${uniqueNoOfVehicles.size} unique unique No Of Vehicles`,
      Subscription: `${uniqueSubscriptions.size} Unique Subscriptions`,
      NoOfTripsToSchool: totalToSchoolCount,
      NoOfTripsFromSchool: totalFromSchoolCount,
      Cost: totalCost,
      PerVehicleCost: totalPerVehicleCost,
      totalTrips: totalToSchoolCount + totalFromSchoolCount,
    };
  };

  const generateReport = async () => {
    setResults([]);
    setBusy(true);
    setText("Fetching data...");

    try {
      const url = `${baseUrl}/reports/summaries/vehicles?Vehicle=${vehicleName}&Metric=${metric}&MinTrips=${minTrips}&Percentage=${percentage}&Tranche=${tranche}&StartDate=${startDate}&EndDate=${endDate}`;

      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const { data } = await axios.get(url, { headers });

      const totalRow = calculateTotals(data);
      setResults([...data, totalRow]); // Append the total row at the end
    } catch (e) {
      console.error(e.message);
    }

    setBusy(false);
    setText("Generate Report");
  };

  return (
    <div>
      <h4 style={{ textAlign: "center", marginBottom: 20, marginTop: 20 }}>
        Vehicle Summary
      </h4>
      <div style={{ width: "50%", margin: "0 auto", marginTop: 30 }}>
        <Row gutter={[16, 16]} style={{ marginBottom: "10px" }}>
          <Col span={12}>
            <label for="datePicker" style={{ paddingRight: 10 }}>
              StartDate
            </label>
            <Input
              type="date"
              cursor="pointer"
              size="large"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              style={{ width: "100%" }}
            />
          </Col>

          <Col span={12}>
            <label for="datePicker" style={{ paddingRight: 10 }}>
              EndDate
            </label>
            <Input
              type="date"
              cursor="pointer"
              size="large"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              style={{ width: "100%" }}
            />
          </Col>
          <Col span={12}>
            <label htmlFor="vehicleSelect" style={{ paddingRight: 10 }}>
              Vehicle
            </label>
            <Select
              id="vehicleSelect"
              value={vehicleName}
              onChange={(value) => setVehicleName(value)}
              placeholder="Select a Vehicle"
              size="large"
              style={{ width: "100%" }}
            >
              {vehicles.map((vehicle) => (
                <Option key={vehicle.id} value={vehicle.PlateNo}>
                  {vehicle.PlateNo}
                </Option>
              ))}
            </Select>
          </Col>

          <Col span={12}>
            <label htmlFor="metricInput" style={{ paddingRight: 10 }}>
              Metric
            </label>
            <Select
              id="metricInput"
              value={metric}
              onChange={(value) => setMetric(value)}
              size="large"
              style={{ width: "100%" }}
            >
              <Option value="Schedules">Schedules</Option>
              <Option value="Trips">Trips</Option>
            </Select>
          </Col>
          <Col span={12}>
            <label htmlFor="minTripsInput" style={{ paddingRight: 10 }}>
              Minimum Trips
            </label>
            <Input
              id="minTripsInput"
              type="number"
              value={minTrips}
              onChange={(e) => setMinTrips(e.target.value)}
              size="large"
              style={{ width: "100%" }}
            />
          </Col>

          <Col span={12}>
            <label htmlFor="percentageInput" style={{ paddingRight: 10 }}>
              Percentage
            </label>
            <Input
              id="percentageInput"
              type="number"
              value={percentage}
              onChange={(e) => setPercentage(e.target.value)}
              size="large"
              style={{ width: "100%" }}
            />
          </Col>

          <Col span={12}>
            <label htmlFor="trancheInput" style={{ paddingRight: 10 }}>
              Tranche
            </label>
            <Input
              id="trancheInput"
              type="number"
              value={tranche}
              onChange={(e) => setTranche(e.target.value)}
              size="large"
              style={{ width: "100%" }}
            />
          </Col>

          <Col span={12}>
            <Button
              type="primary"
              size="large"
              onClick={generateReport}
              loading={busy}
              disabled={!token || busy}
              style={{ width: "100%", marginTop: "26px" }}
            >
              Generate Report
            </Button>
          </Col>
        </Row>
        {/* <Row justify="end">
          <Col>
            <Button
              type="primary"
              size="large"
              onClick={generateReport}
              loading={busy}
              disabled={!token || busy}
            >
              Generate Report
            </Button>
          </Col>
        </Row> */}
      </div>

      {/* Table to display results */}
      <Spin spinning={busy}>
        {results.length > 0 ? (
          <div
            style={{
              width: "80%",
              margin: "auto",
              marginTop: "40px",
              overflowX: "auto",
            }}
          >
            <Row justify="end" style={{ marginBottom: 20 }}>
              <Col>
                <Button
                  type="primary"
                  onClick={() =>
                    exportToExcel(results, "MonthlyVehicleEarnings")
                  }
                  style={{ marginRight: 10 }}
                >
                  Export to Excel
                </Button>
                <Button
                  type="primary"
                  onClick={() => exportToCSV(results, "MonthlyVehicleEarnings")}
                  style={{ marginRight: 10 }}
                >
                  Export to CSV
                </Button>
                <Button
                  type="primary"
                  onClick={() =>
                    exportToPDF(
                      columns,
                      results,
                      "MonthlyVehicleEarnings",
                      `Monthly Vehicle Earnings Report - Metric: ${
                        metric || "All"
                      }`,
                      [0, 175, 80],
                      "/img/go-green.png",
                      startDate,
                      endDate
                    )
                  }
                >
                  Export to PDF
                </Button>
              </Col>
            </Row>

            <Table
              columns={columns}
              dataSource={results}
              rowKey={(record) =>
                `${record.VehicleID}-${record.RouteName}-${record.Stream}`
              }
              scroll={{ x: "max-content" }}
              pagination={false}
              bordered
              style={{ marginTop: 16, minWidth: 1200 }}
            />
          </div>
        ) : (
          <div style={{ textAlign: "center", marginTop: 40 }}>
            <Empty
              description="No data available. Please generate a report."
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          </div>
        )}
      </Spin>
    </div>
  );
};

export default VehicleSummary;
