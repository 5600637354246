import React, {Component} from 'react';

class Lock extends Component {
    state = {}

    render() {
        return (
            <div style={{textAlign: "center", backgroundColor: '#F0F2F5'}}>
                <a href="#/"><img onClick={() => {
                    localStorage.clear();
                    window.location.reload();
                }} src="img/lock.png" width="600px"/></a>
                <h1 style={{color: "#5C8B71"}}>{this.props.message || this.props.children || false}</h1>
            </div>)
    }
}

export default Lock;