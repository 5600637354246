import React from 'react';
import Ant from 'antd';
import  _ from 'linq';
class GoBr extends React.Component {
    state = {
    }
    constructor(props) {
        super();
        this.state = {}
    }



    render() {

        let rows = this.props.rows;
        if(!rows) rows = 2;



        return (
            <>
                {  _.range(0,rows)
                    .select(()=><br/>)
                    .toArray()
                }
            </>
        )
    }
}
export default GoBr;