import React, {Component} from 'react';
import {subscribe} from '../../../utils/bunifu-redux';
import {
    Affix,
    Button,
    Calendar,
    Card,
    Col,
    Divider,
    Icon,
    Input,
    Layout,
    message,
    Radio,
    Row,
    Select,
    Slider,
    Table,
    Tag,
    Tree
} from 'antd';
import Axios from 'axios';
import moment from 'moment';
import {get, linq} from '../../../utils/Storage';
import Linq from 'linq';
import _ from 'linq';
import GoWidget from "../../../Components/GoWidget";
import GoSp from "../../../Components/GoSp";
import GoPrinter from "../../../Components/GoPrinter";
import GoBr from "../../../Components/GoBr";
import SortAlphabetic from "../../../Components/SortAlphabetic";
import SortNumeric from "../../../Components/SortNumeric";
import goAPI from "../../../Server/Go";
import Lock from "../Lock";


class Attendance extends Component {
    state = {
        name: 'Attendance',
        studentAttendaces: [],
        date: moment().format('YYYY-MM-DD'),
        searchText: '',
        direction: '',
        mode: '',
        class: '',
        stream: '',
        loading: true,
        MData: [],
        YData: [],
        pagination: true,
        timeRange: [0, 24],
        filterModes: val => true,
        page: "clocking",
        pageReg: "all",

    }

    componentWillMount() {
        subscribe(this);
    }

    componentDidMount() {
        this.LoadData();
    }

    LoadData() {
        Axios.get('/attendance/list?date=' + this.state.date)
            .then((r) => {
                this.setState({studentAttendaces: r.data});
            })
            .catch((e) => message.error(e.message))
            .finally(() => this.setState({loading: false}))


        //load data month attendace data
        Axios.get('/attendance/month?date=' + this.state.date)
            .then((r) => {
                this.setState({MData: r.data});
            })

        Axios.get('/attendance/year?date=' + this.state.date)
            .then((r) => {
                this.setState({YData: r.data});
            })


    }


    render() {

        if (!goAPI.getUser().isAdmin())
            return <Lock message={"Access Denied"}/>

        let vehicles = linq("vehicles");
        let providers = linq("providers")
            .select(r => {
                r.Vehicles = vehicles.where(rr => rr.ProviderId === r.id);
                return r;
            });
        let allStudents = linq("students")
            .where(r => r.Status === "Active");

        // Todo - attendance bug- Move to one instance
        let studentTraffic =
            Linq.from(this.state.studentAttendaces)
                .where(r => this.state.filterModes(r))
                .where(r => r.gate || r.vehicle_id) //todo:  migrate to server
                .where(r => moment(r.created_at).hours() >= moment(this.state.timeRange[0] + ":00:00", 'hh:mm:ss').hours())
                .where(r => moment(r.created_at).hours() <= moment((this.state.timeRange[1] - 1) + ":59:59", 'hh:mm:ss').hours())
                .select(r => {
                    r.Color = r.Direction === 'IN' ? '#1EBD60' : '#7762FA'
                    // load the gateway
                    r.Gateway = r.gate || vehicles.firstOrDefault(v => v.id === r.vehicle_id) || false;
                    return r;
                })
                .toArray();

        if (this.state.direction.length > 0) {
            studentTraffic = studentTraffic.filter(r => r.Direction === this.state.direction);
        }


        if (this.state.class.length > 0) {
            studentTraffic = studentTraffic.filter(r => r.Class === this.state.class);
            allStudents = allStudents.where(r => r.Class === this.state.class);
        }

        if (this.state.stream.length > 0) {
            studentTraffic = studentTraffic.filter(r => r.Stream === this.state.stream);
            allStudents = allStudents.where(r => r.Stream === this.state.stream);
        }


        studentTraffic = studentTraffic.filter(r =>
            r.Name.toLowerCase().includes(this.state.searchText.toLowerCase()) ||
            r.Adm.toLowerCase().includes(this.state.searchText.toLowerCase())
        );


        var MCols = [{
            title: "Name",
            dataIndex: 'name',
            fixed: 'left'
        }];
        for (let i = 1; i <= moment(this.state.date, 'YYYY-MM-DD').daysInMonth(); i++) {
            MCols.push({
                title: i,//+" "+moment(this.state.date,'YYYY-MM-DD').format('dddd').substr(0,1),
                dataIndex: i,
                render: (val) => <Icon type="check" style={{color: '#52c41a'}}/>
            })
        }
        MCols.push({
            title: "%",
            dataIndex: '100%',
            fixed: 'right',
            render: () => <span style={{color: '#52c41a'}}> 100%</span>
        });

        let MData = [];
        for (let index = 0; index < 100; index++) {
            MData.push({
                    name: 'Name here'
                }
            );
        }

        /// filter absent students

        const allTraffic = _.from(studentTraffic);

        allStudents = allStudents.where(r => {
            r.IN = allTraffic.where(t => t.Direction === "IN" && t.StudentId === r.id).firstOrDefault()
            r.OUT = allTraffic.where(t => t.Direction === "OUT" && t.StudentId === r.id).lastOrDefault()
            return r;
        })

        const studentsAbsent = allStudents
            .where(r => r.Adm.toLowerCase().includes(this.state.searchText.toLowerCase()) || r.Name.toLowerCase().includes(this.state.searchText.toLowerCase()))
            .where(s => !allTraffic.any(s1 => s1.StudentId == s.id));


        const studentsPresent = allStudents
            .where(s => allTraffic.any(s1 => s1.StudentId == s.id));

        const studentsCheckIns = studentsPresent
            .where(s => allTraffic.where(r => r.Direction == 'IN').any(s1 => s1.StudentId == s.id));

        const studentsCheckOuts = studentsPresent
            .where(s => allTraffic.where(r => r.Direction == 'OUT').any(s1 => s1.StudentId == s.id));


        const columns = [
            {
                title: '',
                dataIndex: 'created_at',
                key: 'created_at',
                sorter: (a, b) => SortNumeric(a.created_at && moment(a.created_at).unix(), b.created_at && moment(b.created_at).unix()),
                render: (val, obj, idx) => <Row>
                    <Col span={4}>
                        <Tag style={{height: 44, textAlign: "center", minWidth: 44}}
                             className={"shadow"}
                             color={obj.Color}>
                            <strong>
                                {idx + 1}
                                <br/>
                                {obj.Direction}
                            </strong>
                        </Tag>
                    </Col>
                    <Col offset={3} span={17}>
                        <b style={{color: obj.Color}}>{moment(val).format('LTS') || '⚠️'}</b>
                        <br/>
                        <span style={{fontSize: 12}}>{moment(val).fromNow() || '⚠️'}</span>
                    </Col>
                </Row>
            },


            {
                title: 'Adm',
                dataIndex: 'Adm',
                key: 'Adm',
                sorter: (a, b) => SortAlphabetic(a.Adm, b.Adm),
            },
            {
                title: 'Name',
                dataIndex: 'Name',
                key: 'Name',
                render: (val, obj) => <a
                    target={"_blank"}
                    href={"#/students?id=" + obj.StudentId}>
                    <Button type={"link"}>
                        <b>{window.TitleCase(obj.Name)}</b> <Icon className={"noprint"}
                                                                  type={"link"}/>
                    </Button>
                </a>
            }
            ,
            {
                title: 'Class',
                dataIndex: 'Class',
                key: 'Class',
            },
            {
                title: 'Stream',
                dataIndex: 'Stream',
                key: 'Stream',

                render: (val) => <Tag>{val}</Tag>
            }, {
                title: 'Mode',
                dataIndex: 'Gateway',
                key: 'Gateway',
                csv: (val, obj) => val.name,
                render: (val, obj) => <>
                    <strong>{window.TitleCase(obj.CheckinMode)}</strong>
                    <GoSp/>•<GoSp/>
                    <strong style={{color: obj.Color}}> {val.name || val.PlateNo} </strong>
                </>
            }
        ];

        let dateExpr = moment(this.state.date).calendar();

        if (dateExpr.includes(" at ")) {
            dateExpr = _
                    .from(dateExpr.split(' at '))
                    .firstOrDefault()
                + " - "
                + moment(this.state.date).format("MMM Do YY");
        } else {
            dateExpr = moment(this.state.date).format("MMM Do YY");
        }


        // (moment(this.state.date).date() === moment().date() ? " Today - " + moment(this.state.date).format("MMM Do YY") : moment(this.state.date).calendar())

        return (
            <Layout.Content>
                <Card bordered={false} style={{marginTop: -15}}>
                    <GoPrinter
                        title={"⌚ Student Attendance Timeline"}
                        //beforePrint={() => this.setState({pagination: false})} //todo: Trigger before print
                        //afterPrint={() => this.setState({pagination: false})}
                    >
                        {/*Hello World*/}
                    </GoPrinter>
                    <br/>
                    <Row gutter={16} style={{padding: 19}}>

                        <Col span={6}>
                            <GoWidget
                                color={"#0383BF"}
                                title="Clockings"
                                value={studentTraffic.length}
                                icon="swap"
                            />
                        </Col>
                        <Col span={6} hidden={this.state.direction === 'OUT'}>
                            <GoWidget
                                color={"#7762FA"}
                                title="Check-Ins"
                                value={studentTraffic.filter(r => r.Direction === 'IN').length}
                                icon="arrow-down"/>
                        </Col>
                        <Col span={6} hidden={this.state.direction === 'IN'}>
                            <GoWidget color={"#3AA4F4"} title="Check-Outs" value={
                                studentTraffic.filter(r => r.Direction === 'OUT').length
                            } icon="arrow-up"/>
                        </Col>
                        <Col span={6} hidden={this.state.direction !== ''}>
                            <GoWidget color={"#01C68A"} title="Population" value={
                                studentsPresent
                                    .where(r => !r.OUT)
                                    .count()
                                + "/" +
                                studentsPresent
                                    .count()} icon="usergroup-add"/>
                        </Col>
                    </Row>

                    <Row gutter={16} style={{padding: 19}}>
                        <Col span={6}>
                            <GoWidget
                                color={"#0383BF"}
                                title={"Present Students" + (moment(this.state.date).date() === moment().date() && " Today")}
                                value={studentsPresent.count()}
                                icon="user"
                            />
                        </Col>
                        <Col span={6} hidden={this.state.direction === 'OUT'}>
                            <GoWidget
                                color={"#7762FA"}
                                title="Distinct Check-Ins"
                                value={studentsCheckIns.count()}
                                icon="arrow-down"/>
                        </Col>
                        <Col span={6} hidden={this.state.direction === 'IN'}>
                            <GoWidget color={"#3AA4F4"}
                                      title="Distinct Check-Outs"
                                      value={studentsCheckOuts.count()}
                                      icon="arrow-up"/>
                        </Col>
                        <Col span={6}>
                            <GoWidget color={"#F03B52"}
                                      title={"Absent Students" + (moment(this.state.date).date() === moment().date() && " Today")}
                                      value={(studentsAbsent.count())}
                                      icon="usergroup-delete"/>
                        </Col>
                    </Row>
                    <Divider dashed/>
                    <Row gutter={16}>

                        {/*FILTERS*/}
                        <Col span={5}>


                            <Row gutter={[16, 16]}
                            >
                                <Col span={24}
                                     className={"shadow"}
                                     style={{padding: 14, borderRadius: 17}}>
                                    {/*<Col span={24}>*/}
                                    {/*    <Radio.Group defaultValue="" */}
                                    {/*                 style={{width: '100%'}}*/}
                                    {/*                 onChange={(val) => this.setState({mode: val})}>*/}
                                    {/*        <Radio.Button value="">All Modes</Radio.Button>*/}
                                    {/*        <Radio.Button value="Gate">Gate</Radio.Button>*/}
                                    {/*        <Radio.Button value="Bus">Vehicle</Radio.Button>*/}
                                    {/*    </Radio.Group>*/}
                                    {/*</Col>*/}
                                    <Col span={24} align={"center"}>
                                        <Radio.Group defaultValue=""
                                                     buttonStyle="solid"
                                                     style={{width: '100%'}}
                                                     onChange={(val) => this.setState({direction: val.target.value})}>
                                            <Radio.Button value="">All Directions</Radio.Button>
                                            <Radio.Button value="IN">IN</Radio.Button>
                                            <Radio.Button value="OUT">OUT</Radio.Button>
                                        </Radio.Group>
                                    </Col>
                                    <GoBr/>
                                    <Divider dashed/>
                                    <Col span={24}>
                                        <Calendar mode={'month'}
                                                  fullscreen={false}
                                                  defaultValue={moment(this.state.date, 'YYYY-MM-DD')}
                                                  onChange={(d) => {
                                                      this.state.date = d.format('YYYY-MM-DD');
                                                      this.state.loading = true;
                                                      this.forceUpdate();
                                                      this.LoadData();
                                                  }}

                                        />
                                    </Col>
                                </Col>


                            </Row>
                            <GoBr/>
                            <Affix offsetTop={120}>
                                <Row
                                    className={"shadow"}
                                    type={"flex"}
                                    justify={"center"}
                                    align={"middle"}
                                    style={{padding: 14, borderRadius: 17}}>
                                    <Col span={12}>
                                        <Tag><Icon type={"clock-circle"}/> {this.state.timeRange[0]}00</Tag>
                                    </Col>
                                    <Col span={12} align={"right"}>
                                        <Tag><Icon type={"clock-circle"}/> {this.state.timeRange[1]}:00</Tag>
                                    </Col>
                                    <Col span={18} al>
                                        <Slider
                                            range
                                            style={{width: '100%'}}
                                            step={1}
                                            defaultValue={this.state.timeRange}
                                            max={24}
                                            tipFormatter={value => `${((value < 10 ? '0' : '') + (value === 24 ? '00' : value))}:00`}
                                            marks={{
                                                0: 'Midnignt',
                                                12: 'Noon',
                                                24: 'Midnignt'
                                            }}
                                            // onChange={onChange}
                                            onAfterChange={(val) => this.setState({timeRange: _.from(val).orderBy(r => r).toArray()})}
                                        />
                                    </Col>


                                </Row>
                                <GoBr/>

                                <Row gutter={[16, 16]}
                                     className={"shadow"}
                                     style={{padding: 14, borderRadius: 17}}>
                                    <Col span={24}>
                                        <Select defaultValue="" style={{width: '100%'}}
                                                onChange={(val) => this.setState({class: val})}>
                                            <Select.Option value="">All Classes</Select.Option>
                                            {
                                                get('classes')
                                                    .map((obj) => {
                                                            return <Select.Option value={obj}>{obj}</Select.Option>
                                                        }
                                                    )
                                            }
                                        </Select>
                                    </Col>

                                    {this.state.class !== "" && <Col span={24}> <Select defaultValue=""
                                                                                        style={{width: '100%'}}
                                                                                        onChange={(val) => this.setState({stream: val})}>
                                        <Select.Option value="">All Streams</Select.Option>
                                        {get('streams').map((obj) => {
                                            return <Select.Option value={obj}>{obj}</Select.Option>
                                        })}
                                    </Select>
                                    </Col>
                                    }
                                </Row>
                                <GoBr/>

                                <Row gutter={[16, 16]}
                                     className={"shadow"}
                                     style={{padding: 14, borderRadius: 17}}>
                                    <Tree
                                        style={{width: "100%"}}
                                        showLine
                                        defaultExpandedKeys={["", "Gate", "Bus"]}
                                    >
                                        <Tree.TreeNode
                                            title={<span onClick={val => this.setState({filterModes: val => true})}>
                                                Modes (All)</span>}
                                            key={""}>
                                            <Tree.TreeNode
                                                title={<div>
                                                    <span
                                                        onClick={() => this.setState({filterModes: val => val.CheckinMode === 'Gate'})}>
                                                        Gates (All)
                                                    </span>
                                                </div>}
                                                key="0-0-0">
                                                <Tree.TreeNode disabled={true} title="Gate A"
                                                               key="0-0-0-0"/>
                                                <Tree.TreeNode disabled={true} title="Gate B" key="0-0-0-1"/>
                                            </Tree.TreeNode>

                                            <Tree.TreeNode title={<span
                                                onClick={() => this.setState({filterModes: val => val.CheckinMode === 'Bus'})}>Transport (All Providers)</span>}
                                                           key="0-0-2"
                                            >
                                                {
                                                    providers
                                                        .select(p => <>
                                                            <Tree.TreeNode title={<span
                                                                onClick={() => this.setState({filterModes: val => p.Vehicles.any(v => v.id === val.vehicle_id)})}>{p.Name} (All Vehicles)</span>}
                                                                           key={p.id}>
                                                                {
                                                                    p.Vehicles
                                                                        .select(v => <Tree.TreeNode
                                                                            title={<span
                                                                                onClick={() => this.setState({filterModes: val => v.id === val.vehicle_id})}
                                                                            >{v.PlateNo}</span>}
                                                                            key={v.id}/>)
                                                                        .toArray()
                                                                }

                                                            </Tree.TreeNode>
                                                        </>)
                                                        .toArray()
                                                }


                                            </Tree.TreeNode>
                                        </Tree.TreeNode>
                                    </Tree>
                                </Row>

                            </Affix>
                        </Col>
                        {/*RECORDS*/}

                        <Col span={18} offset={1}>
                            <Col span={24}>
                                <Col span={18} hidden={this.state.loading}>
                                    <u><h4> {dateExpr.toUpperCase()} </h4></u>
                                    <GoBr/>
                                    <Radio.Group defaultValue="clocking"
                                                 buttonStyle="solid"
                                                 style={{width: '100%'}}
                                                 onChange={(val) => this.setState({page: val.target.value})}>
                                        <Radio.Button
                                            value="clocking">{"CLOCKINGS [" + studentTraffic.length + "]"}</Radio.Button>
                                        <Radio.Button value="register">{"REGISTER [" + studentsPresent
                                            .orderBy(r => r.IN && moment(r.IN.created_at))
                                            .count() + "]"}</Radio.Button>
                                        <Radio.Button
                                            value="absent">{"ABSENTEES [" + studentsAbsent.count() + "]"}</Radio.Button>
                                    </Radio.Group>

                                </Col>
                                <Col span={6} align={"right"}>
                                    <Input.Search
                                        disabled={this.state.loading}
                                        className={"noprint"}
                                        enterButton
                                        placeholder="Search Student"
                                        defaultValue={this.state.searchText}
                                        onSearch={(val) => this.setState({searchText: val})}
                                        style={{width: '100%'}}
                                    />
                                </Col>
                            </Col>
                            <br/>
                            <Divider/>
                            <Col span={24}>
                                {
                                    this.state.page === "clocking"

                                    &&

                                    <Table
                                        loading={this.state.loading}
                                        pagination={{pageSize: 25}}
                                        // title={() => "Records: " + studentTraffic.length}
                                        // footer={() => <span>
                                        // <span className={"noprint"} style={{float: "right"}}>
                                        //        <strong>Disable Pagination for Print</strong>
                                        //         <GoSp s={4}/>
                                        //         <Switch
                                        //             defaultChecked={false}
                                        //             onChange={val => this.setState({pagination: !val})}/>
                                        //             </span>
                                        // </span>}
                                        bordered={false}
                                        size={"middle"}
                                        columns={columns}
                                        dataSource={studentTraffic}/>
                                }

                                {
                                    this.state.page === "register"
                                    && <Table
                                        loading={this.state.loading}
                                        title={() => <Radio.Group defaultValue="all"
                                                                  buttonStyle="solid"
                                                                  style={{width: '100%'}}
                                                                  onChange={(val) => this.setState({pageReg: val.target.value})}>
                                            <Radio.Button
                                                value="all">{"ALL [" + studentsPresent.count() + "]"}</Radio.Button>
                                            <Radio.Button value="in">{"ON PREMISE [" + studentsPresent
                                                .where(r => !r.OUT)
                                                .count() + "]"}</Radio.Button>
                                            <Radio.Button
                                                value="out">{"LEFT PREMISE [" + studentsPresent
                                                .where(r => r.OUT)
                                                .count() + "]"}</Radio.Button>
                                        </Radio.Group>
                                        }
                                        columns={[
                                            {
                                                title: '',
                                                dataIndex: 'Adm',
                                                key: 'Adm',
                                                render: (val, obj, idx) => idx + 1
                                            }, {
                                                title: 'Adm',
                                                dataIndex: 'Adm',
                                                key: 'Adm',
                                                sorter: (a, b) => SortAlphabetic(a.Adm, b.Adm),
                                            }, {
                                                title: 'Name',
                                                dataIndex: 'Name',
                                                key: 'Name',
                                                render: (val, obj) => <a
                                                    target={"_blank"}
                                                    href={"#/students?id=" + obj.id}>
                                                    <Button type={"link"}>
                                                        <b>{window.TitleCase(obj.Name)}</b> <Icon className={"noprint"}
                                                                                                  type={"link"}/>
                                                    </Button>
                                                </a>
                                            },
                                            {
                                                title: 'IN',
                                                dataIndex: 'IN',
                                                key: 'IN',
                                                sorter: (a, b) => SortNumeric(a.IN && moment(a.IN.created_at).unix(), b.IN && moment(b.IN.created_at).unix()),
                                                render: (val, obj, idx) => <Row>
                                                    <Col span={17}>
                                                        <b style={{color: '#7762FA'}}>{obj.IN && moment(obj.IN.created_at).format('LTS')}</b>
                                                        <br/>
                                                        <span
                                                            style={{fontSize: 12}}>{obj.IN && moment(obj.IN.created_at).fromNow()}</span>
                                                    </Col>
                                                </Row>
                                            },
                                            {
                                                title: 'OUT',
                                                dataIndex: 'OUT',
                                                key: 'OUT',
                                                sorter: (a, b) => SortNumeric(a.OUT && moment(a.OUT.created_at).unix(), b.OUT && moment(b.OUT.created_at).unix()),
                                                render: (val, obj, idx) => <Row>
                                                    <Col span={17}>
                                                        <b style={{color: '#0383BF'}}>{obj.OUT && moment(obj.OUT.created_at).format('LTS')}</b>
                                                        <br/>
                                                        <span
                                                            style={{fontSize: 12}}>{obj.OUT && moment(obj.OUT.created_at).fromNow()}</span>
                                                    </Col>
                                                </Row>
                                            },
                                            {
                                                title: 'Class',
                                                dataIndex: 'Class',
                                                key: 'Class',
                                            },
                                            {
                                                title: 'Stream',
                                                dataIndex: 'Stream',
                                                key: 'Stream',
                                                render: (val) => <Tag>{val}</Tag>
                                            },


                                        ]}
                                        pagination={{pageSize: 25}}
                                        dataSource={studentsPresent
                                            .where(r => {
                                                if (this.state.pageReg === "all")
                                                    return r;

                                                if (this.state.pageReg === "in")
                                                    return !r.OUT;

                                                return !(!r.OUT);
                                            })
                                            .orderBy(r => r.IN && moment(r.IN.created_at))
                                            .toArray()
                                        }

                                    />}

                                {
                                    this.state.page === "absent"
                                    &&
                                    <Table
                                        loading={this.state.loading}
                                        //title={() => "ABSENTEES " + dateExpr.toUpperCase() + " [" + (studentsAbsent.count()) + "]"}
                                        //     title={() => <span>
                                        //     {/*<GoToCSV>*/}
                                        //     {/*    <Button shape={'round'} icon={"download"}>*/}
                                        //     {/*       Download CSV*/}
                                        //     {/*    </Button>*/}
                                        //     {/*</GoToCSV>*/}
                                        // </span>}
                                        columns={[
                                            {
                                                title: '',
                                                dataIndex: 'Adm',
                                                key: 'Adm',
                                                render: (val, obj, idx) => idx + 1,
                                                csv: (val, obj, idx) => idx + 1,
                                            }, {
                                                title: 'Adm',
                                                dataIndex: 'Adm',
                                                key: 'Adm',
                                                csv: 'Adm'
                                            }, {
                                                title: 'Name',
                                                dataIndex: 'Name',
                                                key: 'Name',
                                                csv: val => window.TitleCase(val),
                                                render: (val, obj) => <a
                                                    target={"_blank"}
                                                    href={"#/students?id=" + obj.id}>
                                                    <Button type={"link"} style={{color: "crimson"}}>
                                                        <b>{window.TitleCase(obj.Name)}</b> <Icon className={"noprint"}
                                                                                                  type={"link"}/>
                                                    </Button>
                                                </a>
                                            },
                                            {
                                                title: 'Class',
                                                dataIndex: 'Class',
                                                key: 'Class',
                                                csv: 'Class'
                                            },
                                            {
                                                title: 'Stream',
                                                dataIndex: 'Stream',
                                                key: 'Stream',
                                                csv: 'Stream',
                                                render: (val) => <Tag>{val}</Tag>
                                            },


                                        ]}
                                        pagination={{pageSize: 25}}
                                        dataSource={studentsAbsent.toArray()
                                        }

                                    />
                                }
                            </Col>

                        </Col>
                    </Row>
                    <br/>
                </Card>
            </Layout.Content>
        )
    }


}

export default Attendance;