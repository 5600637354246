import React, { Component } from "react";
import { subscribe } from "../../../utils/bunifu-redux";
import Ant, {
  AutoComplete,
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Drawer,
  Dropdown,
  Empty,
  Icon,
  Input,
  Layout,
  Menu,
  message,
  Popconfirm,
  Popover,
  Row,
  Select,
  Switch,
  Table,
  Tabs,
  Tag,
  Tooltip,
  TreeSelect,
} from "antd";

import { setTimeout } from "timers";
import { exists, get, get2, keyValue, linq, map, put, remove } from "../../../utils/Storage";
import axios from "axios";
import Message from "../Message";
import { Params, Route } from "../../../utils/Routing";
import auth from "../../../Components/Auth";
import moment from "moment";
import _ from "linq";
import SearchCol from "../../../Components/SearchCol";
import SortAlphabetic from "../../../Components/SortAlphabetic";
import SortNumeric from "../../../Components/SortNumeric";
import kimTools from "../../../utils/KimTools";
import GoBr from "../../../Components/GoBr";
import GoWidget from "../../../Components/GoWidget";
import Studentmessages from "./StudentMessages";
import Student_Add_Schedules from "./Student_Add_Schedules";
import GoSp from "../../../Components/GoSp";

class Students extends Component {
  state = {
    name: "Students",
    current: "Active",
    guardiansFileter: "",
    scheduleFilter: 0,
    student: {},
    sendMessage: false,
    searchText: "",
    searchedColumn: "",
    selClasses: [],
    selStreams: [],
    selStations: [],
    selectedRowKeys: [],
    showStudent: false,
    messages: [],
    sync: {
      loading: false,
      error: null,
      time: null,
    },
    route: [],
    //  Added V2
    filters: [],
    currentDataSource: [],

    // eslint-disable-next-line no-sparse-arrays

    data: [],
    status: "Active",
    permissions: get2("permissions"),
  };

  LoadData() {
    this.setState({ sync: { loading: true } });
    axios
      .get("/student")
      .then((response) => {
        // response.data
        // message.success("Students sync successful");
        put("students", response.data);
        let stus = response.data;

        if (this.props.onFilter)
          stus = _.from(stus)
            .where((s) => this.props.onFilter(s))
            .toArray();

        this.setState({
          data: stus,
          dataFiltered: stus,
          sync: {
            loading: false,
            error: null,
            time: moment(),
          },
        });
      })
      .catch((error) => {
        //message.error(error["message"]);
        this.setState({
          sync: {
            loading: false,
            error: error["message"],
          },
        });
      });
  }

  componentWillMount() {
    subscribe(this);

    this.LoadData();

    // add a timer to load data every 5 mins
    setInterval(() => {
      this.LoadData();
    }, 300000);
  }

  componentDidMount() {
    if (Params().page) this.state.current = Params("page").toString();

    // Update last sync date here

    let stus = get("students");

    if (this.props.onFilter)
      stus = _.from(stus)
        .where((s) => this.props.onFilter(s))
        .toArray();

    this.setState({
      data: stus,
      dataFiltered: stus,
    });
  }

  ContextMenu = (props) => (
    <>
      <Menu className={"shadow"}>
        {this.state.permissions.get(3) && this.state.permissions.get(3).can_modify == 1 ? (
          <Menu.Item>
            <a href={"#/students?id=" + props.student.id}>👤 View Profile</a>
          </Menu.Item>
        ) : null}

        <Divider />
        <Menu.Item
          onClick={() => window.pop("/wwwroot/?students-daily&id=" + props.student.id)}
          key="1"
        >
          📅 Daily Activity Report
        </Menu.Item>
        <Menu.Item
          onClick={() => window.pop("/wwwroot/?s-a-calendar&id=" + props.student.Adm)}
          key="1"
        >
          📅 Attendance Activity Report
        </Menu.Item>
        <Menu.Item onClick={() => window.pop("/wwwroot/?students&id=" + props.student.id)} key="2">
          💰 Transport Billing Report
        </Menu.Item>
      </Menu>
    </>
  );

  GetDataContext = () => {
    if (this.state.currentDataSource.length > 0) return this.state.currentDataSource;

    return this.state.data;
  };

  MenuBulkActions = (props) => (
    <>
      <Menu className={"shadow"}>
        <Menu.Item
          key="select"
          onClick={() => {
            this.setState({
              selectedRowKeys: _.from(this.GetDataContext())
                .select((d) => d.id)
                .toArray(),
            });
          }}
        >
          ✅ Select All{" "}
          <Ant.Badge
            count={this.GetDataContext().length.toLocaleString()}
            style={{ backgroundColor: "#52c41a" }}
          />
        </Menu.Item>
        <Menu.Item key="clear" onClick={() => this.setState({ selectedRowKeys: [] })}>
          🟩 Clear Selection
        </Menu.Item>
        <Divider />
        <Menu.Item
          key="sms"
          onClick={() => {
            message.loading("Fetching Contacts...", 0);
            let messages = [];

            let selectedRows = _.from(this.state.selectedRowKeys)
              .select((r) => _.from(this.state.data).first((s) => s.id == r))
              .toArray();

            selectedRows.forEach((student) => {
              student.guardians.forEach((guardian) => {
                let obj = JSON.parse(JSON.stringify(student));

                if (guardian != null) {
                  obj["GuardianName"] = guardian.name;
                  obj["Phone"] = guardian.phone == null ? "" : guardian.phone;
                  obj["Email"] = guardian.email;
                  obj["HimHer"] = guardian.Gender == "Male" ? "him" : "her";
                  obj["HisHer"] = guardian.Gender == "Male" ? "his" : "her";
                  obj["HisHers"] = guardian.Gender == "Male" ? "his" : "hers";
                  obj["SonDaughter"] = guardian.Gender == "Male" ? "son" : "daughter";
                }
              
                if (student.RouteId === null) {
                  obj["RouteName"] = "";
                  obj["StationName"] = "";
                } else {
                  obj["RouteName"] = keyValue(get("routes"))[student.RouteId].Name;
                  obj["StationName"] = keyValue(get("stations"))[student.StationId].Name;
                }
                
                messages.push(obj);
              });
            });

            this.setState({ messages: messages, sendMessage: true });

            message.destroy();
          }}
        >
          📞 Send SMS
        </Menu.Item>
        <Menu.Item
          key="csv"
          onClick={() => {
            message.loading("Generating CSV...", 0);

            let vehicles = keyValue(get("vehicles"));
            let stations = keyValue(get("stations"));

            let content = [
              [
                "Adm",
                "Name",
                "Gender",
                "Guardian1",
                "Guardian2",
                "Class",
                "Stream",
                "Route",
                "Station",
                "MorningSchedule",
                "MorningVehicle",
                "EveningSchedule",
                "EveningVehicle",
              ],
            ];

            let selectedRows = _.from(this.state.selectedRowKeys)
              .select((r) => _.from(this.state.data).first((s) => s.id == r))
              .toArray();

            selectedRows.forEach((data) => {
              let mSchedule = data.schedules.filter((r) => r.Direction == "To School");
              mSchedule = mSchedule.length > 0 ? mSchedule[0] : null;

              let eSchedule = data.schedules.filter((r) => r.Direction == "From School");
              eSchedule = eSchedule.length > 0 ? eSchedule[0] : null;

              let guardiansList = [];

              if (data.guardians != null) {
                  if (data.guardians[0] != null) {
                      guardiansList[0] = data.guardians[0].phone == null ? "" : data.guardians[0].phone;
                  }
                  if (data.guardians[1] != null) {
                      guardiansList[1] = data.guardians[1].phone == null ? "" : data.guardians[1].phone;
                  }
              }
              
              if (data.Status == "Active") {
                content.push([
                    data.Adm,
                    data.Name,
                    data.Gender,
                    guardiansList[0] != null ? guardiansList[0] : "",
                    guardiansList[1] != null ? guardiansList[1] : "",
                    data.Class,
                    data.Stream,
                    data.route === null ? "" : data.route.Name,
                    data.StationId === null ? "" : stations[data.StationId] && stations[data.StationId].Name,
                    mSchedule && mSchedule.Name,
                    mSchedule && vehicles[mSchedule.VehicleId] && vehicles[mSchedule.VehicleId].PlateNo,
                    eSchedule && eSchedule.Name,
                    eSchedule && vehicles[eSchedule.VehicleId] && vehicles[eSchedule.VehicleId].PlateNo,
                ]);
              }
            });

            window.exportToCsv("students.csv", content);
            message.destroy();
            
            Ant.message.success("Students exported successfully.");
          }}
        >
          ⬇️ Export (CSV)
        </Menu.Item>
        <Divider />
        <Menu.Item
          key="schedule"
          onClick={() => {
            // show notificatuon that the feature will available soon
            Ant.message.info("This feature will be available soon");
          }}
        >
          📅 Add To Existing Schedule
        </Menu.Item>
        <Menu.Item
          key="schedule-new"
          onClick={() => {
            // show notificatuon that the feature will available soon
            Ant.message.info("This feature will be available soon");
          }}
        >
          📅 Add To New Schedule
        </Menu.Item>
        <Divider />
        <Menu.Item key="delete" style={{ color: "red" }}>
          ⚠️ Delete Selected ?{" "}
        </Menu.Item>
      </Menu>
    </>
  );

  render() {
    const { Text } = Ant.Typography;
    const { filters } = this.state;

    const columns = [
      {
        title: "",
        dataIndex: "Class",
        key: "icon",
        width: 50,
        fixed: "left",

        render: (val, obj) => (
          <Avatar
            size={"small"}
            style={{
              backgroundColor:
                obj.Status == "Active"
                  ? _.from(this.state.selectedRowKeys).any((key) => key === obj.id)
                    ? "#00AF50"
                    : window.getColor(val + " null", 50, 50)
                  : "crimson",
            }}
          >
            {" "}
            <Icon
              type={
                _.from(this.state.selectedRowKeys).any((key) => key === obj.id) ? "check" : "user"
              }
            />
          </Avatar>
        ),
      },
      {
        title: "Adm",
        dataIndex: "Adm",
        fixed: "left",
        ...SearchCol(this, "Adm"),
        sorter: (a, b) => SortAlphabetic(a.Adm, b.Adm),
        render: (adm, obj) => (
          <Dropdown
            overlay={
              this.state.selectedRowKeys.length > 0 ? (
                <this.MenuBulkActions data={dataFiltered2} />
              ) : (
                <this.ContextMenu student={obj} />
              )
            }
            trigger={["contextMenu"]}
          >
            <Text mark={_.from(this.state.selectedRowKeys).any((key) => key === obj.id)}>
              {adm}
            </Text>
          </Dropdown>
        ),
      },
      {
        title: "Name",
        dataIndex: "Name",
        fixed: "left",
        width: 200,
        ...SearchCol(this, "Name"),
        sorter: (a, b) => SortAlphabetic(a.Name, b.Name),
        render: (name, obj) => (
          <Dropdown
            overlay={
              this.state.selectedRowKeys.length > 0 ? (
                <this.MenuBulkActions data={dataFiltered2} />
              ) : (
                <this.ContextMenu student={obj} />
              )
            }
            trigger={["contextMenu"]}
          >
            <Button
              onClick={() => {
                window.location.href = "#/students?id=" + obj.id;
              }}
              type="link"
              style={{ color: obj.Status == "Active" ? "green" : "crimson" }}
            >
              <strong>{kimTools.strings.toTitleCase(name)}</strong>
            </Button>
          </Dropdown>
        ),
      },
      {
        title: "Gender",
        dataIndex: "Gender",
        filters: [
          {
            text: "Male",
            value: "Male",
          },
          {
            text: "Female",
            value: "Female",
          },
        ],
        filterMultiple: true,
        sorter: (a, b) => SortAlphabetic(a.Gender || "", b.Gender || ""),
        onFilter: (value, record) => record.Gender === value,
        render: (val) => val && <Ant.Badge color={val === "Male" ? "blue" : "pink"} text={val} />,
      },
      {
        title: "Status",
        dataIndex: "Status",
        filters: [
          { value: "Active", text: "Active" },
          { value: "Inactive", text: "Inactive" },
          { value: "Suspended", text: "Suspended" },
          { value: "Expelled", text: "Expelled" },
          { value: "Dormant", text: "Dormant" },
          { value: "Alumni", text: "Alumni" },
          { value: "Graduated", text: "Graduated" },
          { value: "Deceased", text: "Deceased" },
        ],
        filterMultiple: true,
        onFilter: (value, record) => record.Status === value,
        sorter: (a, b) => SortAlphabetic(a.Status, b.Status),
        render: (text) => <Ant.Badge color={text === "Active" ? "green" : "orange"} text={text} />,
      },
      {
        title: "Class",
        dataIndex: "Class",
        width: 150,
        filters: _.from(get("classes"))
          .select((val, i) => ({ text: kimTools.strings.toTitleCase(val), value: val }))
          .orderBy((r) => r.text)
          .toArray(),
        filterMultiple: true,
        onFilter: (value, record) => record.Class === value,
        sorter: (a, b) => SortAlphabetic(a.Class, b.Class),
        render: (txt) => <Text>{kimTools.strings.toTitleCase(txt)}</Text>,
      },
      {
        title: "Stream",
        dataIndex: "Stream",
        filters: _.from(get("streams"))
          .select((val, i) => ({ text: kimTools.strings.toTitleCase(val), value: val }))
          .orderBy((r) => r.text)
          .toArray(),
        filterMultiple: true,
        onFilter: (value, record) => record.Stream === value,
        sorter: (a, b) => SortAlphabetic(a.Stream, b.Stream),
      },

      {
        title: "Guardians",
        dataIndex: "guardians",
        ...SearchCol(this, "guardians"),
        onFilter: (value, record) => {
          return record.guardians.some((guardian) =>
            (
              guardian.name.toString().toLowerCase() + guardian.phone.toString().toLowerCase()
            ).includes(value.toString().toLowerCase())
          );
        },
        sorter: (a, b) => SortNumeric(a.guardians.length, b.guardians.length),
        render: (guardians) => (
          <span>
            {guardians.map((guardian) => {
              return (
                <Popover content={guardian.phone} title={guardian.name}>
                  {" "}
                  <Tag size="small" color={"green"} key={guardian}>
                    <Icon type="phone" /> {guardian.name}{" "}
                    {guardian.phone.length > 3 && (
                      <strong>**{guardian.phone.substr(guardian.phone.length - 3)}</strong>
                    )}
                  </Tag>
                </Popover>
              );
            })}
          </span>
        ),
      },
      ,
      {
        title: "Route",
        dataIndex: "RouteId",
        filters: _.from(get("routes"))
          .select((val, i) => ({ text: kimTools.strings.toTitleCase(val.Name), value: val.id }))
          .orderBy((r) => r.text)
          .toArray(),
        filterMultiple: true,
        onFilter: (value, record) => record.RouteId === value,
        sorter: (a, b) => SortAlphabetic(a.RouteId, b.RouteId),
        render: (text, obj) => (
          <span>
            <Tag size="small" hidden={text === null}>
              {" "}
              <Icon type="branches" />{" "}
              {get("routes")[text] && window.TitleCase(keyValue(get("routes"))[text].Name)}
            </Tag>
          </span>
        ),
      },
      {
        title: "Station",
        dataIndex: "StationId",
        filters: _.from(get("stations"))
          .where((r) => {
            const { filters } = this.state;

            const routeFilters = _.from(filters).where((f) => f.name === "RouteId");

            if (!routeFilters.any()) return true;

            return _.from(routeFilters.first().data)
              .where((id) => id === r.RouteId)
              .any();
          })
          .select((val, i) => ({
            text:
              kimTools.strings.toTitleCase(keyValue(get("routes"), "id")[val.RouteId].Name) +
              " ‣ " +
              kimTools.strings.toTitleCase(val.Name),
            value: val.id,
          }))
          .orderBy((r) => r.text)
          .toArray(),
        filterMultiple: true,
        onFilter: (value, record) => record.StationId === value,
        sorter: (a, b) => SortAlphabetic(a.StationId, b.StationId),
        render: (text, obj) => (
          <span>
            <Tag size="small" disabled hidden={text === null}>
              {" "}
              <Icon type="environment" />{" "}
              {get("stations")[text] && window.TitleCase(keyValue(get("stations"))[text].Name)}
            </Tag>
          </span>
        ),
      },
      {
        title: "Cost",
        dataIndex: "Cost",
        sorter: (a, b) => SortNumeric(a.Cost, b.Cost),
        render: (text) => (
          <span>
            <Tag size="small" disabled hidden={text === null}>
              {" "}
              Ksh {text}{" "}
            </Tag>
          </span>
        ),
      },
      {
        dataIndex: "schedules",
        title: "Schedules",
        filters: [
          { value: "none", text: "No Schedules" },
          { value: "any", text: "Any Schedule" },
          { value: "to-school", text: "To School" },
          { value: "from-school", text: "From School" },
          { value: "only-to-school", text: "Only To School" },
          { value: "only-from-school", text: "Only From School" },
          { value: "both", text: "Both To and From School" },
        ],
        filterMultiple: false,
        sorter: (a, b) => SortNumeric(a.schedules.length, b.schedules.length),
        onFilter: (value, student) => {
          const studentSchedules = _.from(student.schedules);

          if (value === "none") return !studentSchedules.any();

          if (value === "any") return studentSchedules.any();

          if (value === "to-school")
            return studentSchedules.any((s) => s.Direction === "To School");

          if (value === "only-to-school")
            return (
              studentSchedules.any((s) => s.Direction === "To School") &&
              !studentSchedules.any((s) => s.Direction === "From School")
            );

          if (value === "from-school")
            return studentSchedules.any((s) => s.Direction === "From School");

          if (value === "only-from-school")
            return (
              !studentSchedules.any((s) => s.Direction === "To School") &&
              studentSchedules.any((s) => s.Direction === "From School")
            );

          if (value === "both")
            return (
              studentSchedules.any((s) => s.Direction === "To School") &&
              studentSchedules.any((s) => s.Direction === "From School")
            );

          return true; // no filters
        },
        render: (text, obj) => (
          <Tag
            size="small"
            color={
              obj.schedules.length > 0
                ? obj.schedules.length > 1
                  ? "green"
                  : "#0E3541"
                : "#FC0101"
            }
          >
            {obj.schedules.length}
          </Tag>
        ),
      },
      {
        dataIndex: "",
        title: "",
        render: (adm, obj) => (
          <Dropdown
            overlay={
              this.state.selectedRowKeys.length > 0 ? (
                <this.MenuBulkActions data={dataFiltered2} />
              ) : (
                <this.ContextMenu student={obj} />
              )
            }
          >
            <Ant.Button icon={"more"} size={"small"} type={"primary"} shape={"circle"} />
          </Dropdown>
        ),
      },
    ];

    // Used for editing
    if (exists("selStudent")) {
      try {
        let sel = get("selStudent");
        put("wasStudent", true);
        remove("selStudent");

        this.setState({
          student: sel,
          showStudent: true,
          searchText: sel.Name,
        });
      } catch (e) {
        //console.log(e);
      }
    }

    let dataFiltered = [];

    for (let index = 0; index < this.state.data.length; index++) {
      const student = this.state.data[index];
      student.key = student.id;
      if (student.Name === null) continue;
      let match = true;

      if (match) {
        dataFiltered.push(student);
      }
    }

    if (this.state.status.length > 0) {
      dataFiltered = _.from(dataFiltered)
        .where((s) => s.Status === this.state.status)
        .toArray();
    }

    let dataFiltered2 = dataFiltered;

    var syncTitle = "Syncing... Please wait";
    var lastSync = "";

    if (this.state.sync.time != null) {
      lastSync = "";
      syncTitle = "Successfully synced " + moment(this.state.sync.time).fromNow();
    } else if (this.state.sync.error != null) {
      lastSync = "";
      syncTitle = "Offline Mode  (" + this.state.sync.error + ") Click to sync";
    }

    // if sync is in progress
    if (this.state.sync.loading) {
      lastSync = "Syncing students...";
    }

    const operations = (
      <span>
        <Ant.Radio.Group
          value={this.state.status}
          onChange={(e) => this.setState({ status: e.target.value })}
          buttonStyle="outline"
        >
          {_.from(get("students"))
            .select((s) => s.Status)
            .distinct()
            .orderBy((status) => status)
            .select((status) => <Ant.Radio.Button value={status}>{status}</Ant.Radio.Button>)
            .toArray()}
          <Ant.Radio.Button value={""}>All</Ant.Radio.Button>
        </Ant.Radio.Group>
        {this.state.permissions.get(3) && this.state.permissions.get(3).can_add == 1 ? (
          <Ant.Button.Group>
            &nbsp;&nbsp;&nbsp;
            <Ant.Tooltip title={"Add Student"}>
              <a
                className={"ant-btn ant-btn-primary ant-btn-icon-only"}
                type={"button"}
                href="#/students?id=-1"
              >
                <Icon type="plus" />
              </a>
            </Ant.Tooltip>
          </Ant.Button.Group>
        ) : null}

        <br />
        <br />
      </span>
    );

    let id = Params().id;

    if (id && Route() === "students") {
      let student = {};
      if (id == -1) {
        student = {
          id: -1,
          Adm: "",
          Name: "",
          Class: "",
          Stream: "",
          guardians: [],
          Route: "",
          Cost: 0,
          Subscription: "Morning & Evening",
          Station: "",
          RouteId: null,
          StationId: null,
          Gender: "Male",
          Active: "1",
          schedules: [],
          Status: "Active",
          Notes: "",
          Access: "",
        };
      } else {
        student = map("students")[id];
      }

      return (
        <StudentEditor
          data={student}
          onClose={() => {
            Route("students");
            this.setState({ data: get("students") });
          }}
        />
      );
    }

    const expandedRowRender = (row) => {
      let schedules = row.schedules;

      const columns = [
        {
          title: "Name",
          dataIndex: "Name",
          key: "Name",
          render: (val, schedule) => (
            <Tag size="small" color={schedule.Direction === "To School" ? "#00AF50" : "gray"}>
              <Icon type={schedule.Direction === "To School" ? "arrow-down" : "arrow-up"} /> {val}
            </Tag>
          ),
        },
        { title: "Direction", dataIndex: "Direction", key: "Direction" },
        {
          title: "Vehicle",
          dataIndex: "VehicleId",
          key: "VehicleId",
          render: (val) => <Tag>{keyValue(get("vehicles"))[val].PlateNo}</Tag>,
        },
        {
          title: "Driver",
          dataIndex: "DriverId",
          key: "DriverId",
          render: (val) => <Tag>{keyValue(get("users"))[val]?.name}</Tag>,
        },
        {
          title: "Agent",
          dataIndex: "AttendantId",
          key: "AttendantId",
          render: (val) => <Tag>{keyValue(get("users"))[val].name}</Tag>,
        },
      ];

      return (
        // Schedules Table
        <Table className={"shadow"} columns={columns} dataSource={schedules} pagination={false} />
      );
    };

    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: (selectedRowKeys) => this.setState({ selectedRowKeys }),
    };

    return (
      <Layout.Content>
        <Message
          onClose={() => this.setState({ sendMessage: false })}
          visible={this.state.sendMessage}
          data={this.state.messages}
        />
        <br />

        <Card bordered={false} loading={this.state.data.length === 0} style={{ marginTop: -15 }}>
          <Tooltip onVisibleChange={() => this.forceUpdate()} placement="topLeft" title={syncTitle}>
            <Button
              shape={this.state.sync.loading ? "round" : undefined}
              style={{ marginRight: 10 }}
              loading={this.state.sync.loading}
              type={
                this.state.sync.error == null
                  ? this.state.sync.loading
                    ? "primary"
                    : "dashed"
                  : "danger"
              }
              onClick={() => this.LoadData()}
            >
              {this.state.sync.loading || (
                <Icon
                  style={{ marginLeft: this.state.sync.loading || 10 }}
                  type={this.state.sync.error == null ? "sync" : "warning"}
                />
              )}{" "}
              {this.state.sync.loading && lastSync}
            </Button>
          </Tooltip>
          <Dropdown
            overlay={<this.MenuBulkActions data={dataFiltered2} />}
            disabled={!(this.state.selectedRowKeys.length > 0)}
          >
            <a
              type="button"
              className={"ant-btn ant-btn-primary"}
              onClick={(e) => e.preventDefault()}
              style={{ marginRight: 10 }}
            >
              <Icon type="check-circle" theme={this.state.selectedRowKeys.length > 0 && "filled"} />{" "}
              {this.state.selectedRowKeys.length > 0 ? "Selected" : "Bulk Actions"}
              {this.state.selectedRowKeys.length > 0 && (
                <Ant.Tag style={{ color: "black", marginLeft: 15, backgroundColor: "whitesmoke" }}>
                  {this.state.selectedRowKeys.length}
                </Ant.Tag>
              )}
            </a>
          </Dropdown>
          <Button
            hidden={
              !(
                this.state.selectedRowKeys.length > 0 &&
                this.state.selectedRowKeys.length < dataFiltered2.length
              )
            }
            type={"link"}
            onClick={() =>
              this.setState({
                selectedRowKeys: _.from(this.GetDataContext())
                  .select((d) => d.id)
                  .toArray(),
              })
            }
          >
            <strong> Select All ?</strong>
          </Button>
          <span style={{ float: "right" }}>{operations}</span>
          <br />
          {dataFiltered2.length > 0 && (
            <Table
              rowSelection={rowSelection}
              expandedRowRender={expandedRowRender}
              columns={columns}
              dataSource={dataFiltered2}
              size={"middle"}
              scroll={{ x: 2000 }}
              onRow={(record) => ({ onContextMenu: (event) => event.preventDefault() })}
              title={() =>
                filters.length > 0 && (
                  <div align={"center"} style={{ width: "100%" }}>
                    <Divider dashed>
                      Filters: <strong style={{ color: "#52C41A" }}>{filters.length}</strong>
                    </Divider>
                    {filters.map((filter, i) => (
                      <>
                        {i > 0 && <i style={{ color: "#676A6F" }}> and &nbsp;&nbsp;</i>}
                        <Ant.Tag style={{ borderRadius: 25, marginRight: 10 }} color="#676A6F">
                          <Icon theme="filled" type="filter" style={{ marginRight: 10 }} />
                          <span>{filter.name}:</span>
                          {_.from(filter.data)
                            .orderBy((r) => r)
                            .toArray()
                            .map((d, ii) => (
                              <>
                                {ii > 0 && <i style={{ color: "#6AD098" }}> or </i>}
                                <strong style={{ marginLeft: 5, marginRight: 5 }}>{d}</strong>
                              </>
                            ))}
                        </Ant.Tag>
                      </>
                    ))}
                    <Divider dashed>
                      <strong style={{ color: "#52C41A" }}>
                        {this.state.currentDataSource.length.toLocaleString()} /{" "}
                        {this.state.data.length.toLocaleString()}
                      </strong>{" "}
                      Results found.
                      <strong style={{ color: "#52C41A" }}>
                        {" "}
                        {parseFloat(
                          (this.state.currentDataSource.length / this.state.data.length) * 100
                        ).toFixed(2)}
                        %{" "}
                      </strong>
                    </Divider>
                  </div>
                )
              }
              footer={() => (
                <>
                  <span style={{ paddingRight: 10 }}>
                    Records: <strong>{this.state.data.length.toLocaleString()} </strong>
                  </span>

                  <span style={{ paddingRight: 10 }}>
                    Filtered:{" "}
                    <strong>{this.state.currentDataSource.length.toLocaleString()} </strong>
                  </span>
                  <span style={{ paddingRight: 10 }}>
                    Selected: <strong>{this.state.selectedRowKeys.length.toLocaleString()} </strong>
                  </span>
                </>
              )}
              onChange={(pagination, filters, sorter, extra) => {
                let selFilters = [];
                for (const filter in filters) {
                  if (filters.hasOwnProperty(filter)) {
                    let el = {};
                    el.name = filter;
                    el.data = filters[filter];
                    if (el.data.length > 0) selFilters.push(el);
                  }
                }

                this.setState({
                  filters: selFilters,
                  currentDataSource: extra.currentDataSource,
                });
              }}
            />
          )}
          {dataFiltered2.length === 0 && <Empty />}
        </Card>
      </Layout.Content>
    );
  }
}

//////////////////////////////////////Student

class StudentEditor extends Component {
  state = {
    name: "StudentEditor",
    loading: false,
    student: {
      id: -1,
      Adm: "",
      Name: "",
      Class: "",
      Stream: "",
      guardians: [],
      Route: "",
      Station: "",
      RouteId: -1,
      StationId: -1,
      Gender: "Male",
      Active: true,
      Status: "Active",
      Notes: "",
      Access: "",
      schedules: [],
      AddSchedules: false,
    },
    IsToSchool: true,
    station: [],
    guardians: [],
    permissions: get2("permissions"),
  };

  componentWillMount() {
    subscribe(this, true);
  }

  validate = (isButton = true) => {
    var ret = true;
    if (this.state.student.Adm.trim().length === 0) {
      message.error("ADM/Reg cannot be empty");
      ret = false;
    }
    var stus = get("students").filter(
      (r) => r.Adm.trim().toLocaleLowerCase() === this.state.student.Adm.trim().toLocaleLowerCase()
    );
    if (this.state.student.id === -1 && stus != null && stus.length > 0) {
      if (isButton) {
        window.Swal.fire({
          title: "Adm Taken",
          showCloseButton: true,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonColor: "#00B050",
          confirmButtonText:
            '<a style="color:white" href=' +
            stus[0].id +
            '"/public#/students?id="><i class="fa fa-user"></i> Open ' +
            stus[0].Name +
            "'s Profile! </a>",
          text: "ADM/Reg " + this.state.student.Adm + " is assigned to  " + stus[0].Name,
        });
      }

      ret = false;
    } else {
      if (isButton) message.success("Available");
    }

    return ret;
  };

  saveStudent = () => {
    if (this.state.student.Adm.trim().length === 0) {
      message.error("ADM/Reg cannot be empty");
      return;
    }
    if (this.state.student.Name.trim().length === 0) {
      message.error("The Name field is empty");
      return;
    }

    if (this.state.student.Class.trim().length === 0) {
      message.error("Select a Class");
      return;
    }

    if (this.state.student.Stream.trim().length === 0) {
      message.error("Select a Stream");
      return;
    }

    let stus = get("students").filter(
      (r) => r.Adm.trim().toLocaleLowerCase() === this.state.student.Adm.trim().toLocaleLowerCase()
    );
    if (this.state.student.id === -1 && stus != null && stus.length > 0) {
      message.warning("ADM/Reg is taken");
      return;
    }

    let stations = this.state.station.toString().split("-");

    if (
      this.state.guardians.filter((r) => !(r.phone.trim().length >= 10 && r.phone.trim() * 1 > 0))
        .length > 0
    ) {
      message.error("Invalid Guardian's Phone number.");
      return;
    }

    // if (
    //     this.state.guardians.filter(
    //         (r) => r.name.trim().length === 0
    //     ).length > 0
    // ) {
    //     message.error("Invalid Guardian's Name.");
    //     return;
    // }

    this.setState({ loading: true });
    let station_id = stations[stations.length - 1] || get("stations")[0].id;
    let _data = {
      id: this.state.student.id,
      Adm: this.state.student.Adm,
      Name: this.state.student.Name,
      Class: this.state.student.Class,
      Stream: this.state.student.Stream,
      Gender: this.state.student.Gender,
      Active: this.state.student.Active,
      Subscription: this.state.student.Subscription,
      Cost: this.state.student.Cost,
      Status: this.state.student.Status,
      Notes: this.state.student.Notes,
      Access: this.state.student.Access,
      StationId: station_id,
      Guardians: this.state.guardians,
      Schedules: this.state.student.schedules,
      AllowTransport: 0,
      AllowAttendance: 0,
    };

    if (this.state.student.id >= 0) {
      axios
        .patch("/student", _data)
        .then(
          function (response) {
            // response.data
            this.setState({ loading: false });
            let stu = map("students")[this.state.student.id];
            let newRec = { ...stu, ..._data };
            newRec.StationId = parseInt(newRec.StationId);
            newRec.Cost = null;
            let all = get("students");
            let idx = all.findIndex((r) => r.id == _data.id);

            all[idx] = newRec;
            put("students", all);
            message.success("Student Updated");
            this.setState({ loading: false });
          }.bind(this)
        )
        .catch(
          function (error) {
            message.error(error["message"]);
            this.setState({ loading: false });
          }.bind(this)
        );
    } else {
      axios
        .post("/student", _data)
        .then(
          function (response) {
            // response.data
            var stu = response.data;
            var newRec = { ..._data, ...stu };

            var route = map("routes")[newRec.RouteId];
            route["stations"] = null;
            newRec["route"] = route;
            newRec["guardians"] = newRec["Guardians"];
            newRec["schedules"] = newRec["Schedules"];

            var all = get("students");
            all.push(newRec);
            put("students", all);

            this.setState({ loading: false });
            message.success("Student Added");

            setTimeout(() => {
              window.location.href = "#/students?id=" + newRec.id;
            }, 2000);
          }.bind(this)
        )
        .catch(
          function (error) {
            message.error(error["message"]);
            this.setState({ loading: false });
          }.bind(this)
        );
    }
  };

  BlockStudent = () => {
    this.state.student.Status = "Blocked";
    this.saveStudent();
  };

  Handle_Remove_Schedule_Student = (scheduleId) => {
    let schedules = linq("schedules");
    let schedule = schedules.firstOrDefault((r) => r.id == scheduleId);

    if (!schedule) return;

    message.loading("Removing Student from Schedule", 0);
    schedule.students = linq("students")
      .where((s) => _.from(s.schedules).any((ss) => ss.id.toString() === scheduleId.toString()))
      .where((r) => r.id !== this.state.student.id)
      .toArray();

    // schedule.students = _.from(schedule.students)
    //     .where(r => r.id !== this.state.student.id)
    //     .toArray()

    axios
      .patch("/schedule", schedule)
      .then((response) => {
        message.destroy();
        // response.data
        this.state.student.schedules = _.from(this.state.student.schedules)
          .where((r) => r.id.toString() !== scheduleId.toString())
          .toArray();

        put(
          "schedules",
          schedules
            .select((r) => {
              if (r.id.toString() !== scheduleId.toString()) return r;
              return response.data;
            })
            .toArray()
        );

        this.forceUpdate();
        message.success("Student Removed from Schedule");
      })
      .catch((error) => {
        message.error(error["message"]);
      });
  };

  render() {
    let IsToSchool = this.state.IsToSchool;
    let users = linq("users");
    let vehicles = linq("vehicles");
    let providers = linq("providers");

    const { Option } = AutoComplete;

    if (this.state.student !== this.props.data) {
      var gs = this.props.data.guardians || [];
      gs.forEach((element) => {
        element.sms = true;
      });
      this.setState({
        student: this.props.data,
        guardians: this.props.data.guardians,
        station:
          this.props.data.StationId === null
            ? []
            : ["0-" + this.props.data.RouteId + "-" + this.props.data.StationId],
      });
    }

    let routes = get("routes");

    const treeData = [];
    for (let i = 0; i < get("routes").length; i++) {
      var route = get("routes")[i];
      var stations = [];
      for (let j = 0; j < route.stations.length; j++) {
        var station = route.stations[j];
        stations.push({
          title: station.Name,
          value: "0-" + route.id + "-" + station.id,
          key: "0-" + route.id + "-" + station.id,
        });
      }
      treeData.push({
        title: route.Name,
        value: "0-" + route.id,
        key: "0-" + route.id,
        children: stations,
      });
    }
    var monringS = null;
    if (
      this.state.student.schedules.filter(
        (r) => r.Year === window.year && r.Direction.toLowerCase() === "to school"
      ).length > 0
    ) {
      monringS = this.state.student.schedules.filter(
        (r) => r.Direction.toLowerCase() === "to school"
      )[0];
    }

    let eveningS = null;

    if (
      this.state.student.schedules.filter(
        (r) => r.Year === window.year && r.Direction.toLowerCase() === "from school"
      ).length > 0
    ) {
      eveningS = this.state.student.schedules.filter(
        (r) => r.Direction.toLowerCase() === "from school"
      )[0];
    }

    return (
      <Layout.Content>
        <Drawer
          visible={this.state.AddSchedules}
          onClose={() =>
            this.setState({
              AddSchedules: false,
              student: linq("students").firstOrDefault(
                (r) => r.id.toString() === this.state.student.id.toString()
              ),
            })
          }
          width={"80%"}
        >
          <Student_Add_Schedules student={this.state.student} />
        </Drawer>
        <Card bordered={false} style={{ marginTop: "50px" }}>
          <Ant.Row gutter={16}>
            <Ant.Col
              span={5}
              className={"shadow"}
              style={{ height: "88vh", marginTop: -23, borderRadius: 15 }}
            >
              <Ant.Col
                span={24}
                align={"center"}
                className={"shadow"}
                style={{
                  color: "white",
                  background: "rgba(0, 0, 0, 0.5)",
                  marginTop: -28,
                  paddingTop: 28,
                  borderRadius: 15,
                  backgroundImage: 'url("img/profile-banner.jpg")',
                }}
              >
                <img
                  style={{ borderRadius: 50 }}
                  src={
                    "./img/go-avatar.png"
                    // this.state.student.Gender == "Male"
                    //     ? "./img/male.png"
                    //     : "./img/female.png"
                  }
                  width={100}
                />
                <GoBr rows={1} />
                <h4
                  style={{
                    color: "white",
                    textShadow: `2px 2px green`,
                    fontsize: "50px!important",
                  }}
                >
                  {window.TitleCase(this.state.student.Name)}
                </h4>
                <h5
                  style={{
                    color: "white",
                    textShadow: `2px 2px green`,
                    fontsize: "50px!important",
                  }}
                >
                  {window.TitleCase(this.state.student.Class)},{" "}
                  {window.TitleCase(this.state.student.Stream)}
                </h5>
                <Tag
                  style={{ marginTop: 10 }}
                  color={this.state.student.Status == "Active" ? "#36B729" : "#FF5C5D"}
                >
                  {this.state.student.Status}
                </Tag>
                <GoBr rows={3} />
              </Ant.Col>
              <GoBr rows={4} />

              <Ant.Col span={24} style={{ padding: 50 }} align={"center"}>
                {this.state.permissions.get(3) && this.state.permissions.get(3).can_modify == 1 ? (
                  <Ant.Button
                    className={"shadow"}
                    type={"primary"}
                    size={"large"}
                    shape={"round"}
                    icon={"check"}
                    style={{ width: "100%" }}
                    loading={this.state.loading}
                    hidden={
                      this.state.student.id > 0
                        ? auth.noUpdate(auth.Students)
                        : auth.noInsert(auth.Students)
                    }
                    onClick={() => this.saveStudent()}
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;Save Changes &nbsp;&nbsp;&nbsp;
                  </Ant.Button>
                ) : null}

                <GoBr rows={2} />
                {this.state.permissions.get(3) && this.state.permissions.get(3).can_add == 1 ? (
                  <a href="/#/students?id=-1">
                    <Ant.Button
                      className={"shadow"}
                      type={"default"}
                      size={"large"}
                      shape={"round"}
                      icon={"plus"}
                      style={{ width: "100%" }}
                      disabled={this.state.loading}
                      hidden={this.state.student.id === -1}
                    >
                      &nbsp;&nbsp;&nbsp;&nbsp;Add Student
                    </Ant.Button>
                  </a>
                ) : null}

                <GoBr rows={2} />
                <Divider dashed />
                {this.state.permissions.get(3) && this.state.permissions.get(3).can_modify == 1 ? (
                  <Popconfirm
                    placement="top"
                    title={"Block student access?"}
                    onConfirm={() => this.BlockStudent()}
                  >
                    <Ant.Button
                      hidden={
                        this.state.loading ||
                        this.state.student.id === -1 ||
                        this.state.student.Status != "Active"
                      }
                      className={"shadow"}
                      type={"danger"}
                      size={"large"}
                      shape={"round"}
                      icon={"lock"}
                      style={{ width: "100%" }}
                    >
                      &nbsp;&nbsp;&nbsp;&nbsp;Revoke Access &nbsp;&nbsp;&nbsp;
                    </Ant.Button>
                  </Popconfirm>
                ) : null}

                <GoBr rows={2} />
                {this.state.permissions.get(3) && this.state.permissions.get(3).can_delete == 1 ? (
                  <Popconfirm
                    placement="bottom"
                    title={"Delete student?"}
                    onConfirm={() => {
                      axios
                        .delete("/student/" + this.state.student.id)
                        .then((response) => {
                          message.success("Successfully deleted.");

                          setTimeout(() => {
                            this.props.onClose();
                          }, 3000);
                        })
                        .catch((error) => {
                          message.error(error["message"]);
                        });
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Ant.Button
                      type={"link"}
                      size={"large"}
                      style={{ width: "100%", color: "crimson" }}
                      hidden={this.state.loading || this.state.student.id === -1}
                    >
                      Delete Student permanently?
                    </Ant.Button>
                  </Popconfirm>
                ) : null}
              </Ant.Col>
            </Ant.Col>
            <Ant.Col span={1} style={{ height: "100%" }}>
              {/*<Divider dashed type={"vertical"} className={"shadow"} style={{height: "60vh"}}/>*/}
            </Ant.Col>
            <Ant.Col span={17}>
              <Ant.Row gutter={20}>
                <Ant.Col span={6}>
                  <GoWidget
                    color={"#01C68A"}
                    title={"Guardians"}
                    value={this.state.student.guardians.length}
                    icon={"user"}
                  />
                </Ant.Col>
                <Ant.Col span={6}>
                  <GoWidget
                    color={"#3AA4F4"}
                    title={"Schedules"}
                    value={this.state.student.schedules.length}
                    icon={"calendar"}
                  />
                </Ant.Col>
                <Ant.Col span={6}>
                  <GoWidget
                    color={"#7762FA"}
                    title={"Morning"}
                    value={_.from(this.state.student.schedules)
                      .where((r) => r.Direction == "To School")
                      .count()}
                    icon={"caret-up"}
                  />
                </Ant.Col>
                <Ant.Col span={6}>
                  <GoWidget
                    value={_.from(this.state.student.schedules)
                      .where((r) => r.Direction == "From School")
                      .count()}
                    color={"#0383BF"}
                    title={"Evening"}
                    // value={0}
                    icon={"caret-down"}
                  />
                </Ant.Col>
              </Ant.Row>
              <Divider dashed className={"shadow"} />
              <Tabs size={"small"} type="card" style={{ marginLeft: -20 }} defaultActiveKey="1">
                <Tabs.TabPane
                  tab={
                    <span>
                      <Icon type="user" />
                      &nbsp;&nbsp;
                      {this.state.student.id === -1 ? "Add Student" : "Edit Student"}{" "}
                    </span>
                  }
                  key="1"
                  style={{ padding: 28 }}
                >
                  <Divider style={{ marginTop: -3 }} orientation={"left"}>
                    <Ant.Typography.Text type={"secondary"}>STUDENT INFO</Ant.Typography.Text>
                  </Divider>
                  <br />
                  <Row gutter={10}>
                    <Col span={16}>
                      <Input
                        placeholder="Registration Number"
                        value={this.state.student.Adm}
                        onChange={(val) => {
                          let cState = this.state.student;
                          cState.Adm = val.target.value;
                          this.setState({ student: cState });
                        }}
                        className={"input-shadow"}
                        addonBefore={"Admission Number"}
                        size={"large"}
                      />
                      {/* { (this.state.student.id===-1 && get('students').filter(r=>r.Adm.trim().toLocaleLowerCase()===this.state.student.Adm.trim().toLocaleLowerCase())!=null && get('students').filter(r=>r.Adm.trim().toLocaleLowerCase()===this.state.student.Adm.trim().toLocaleLowerCase()).length>0 ) && <Alert   message="Adm/Reg/UID alredy taken." type="warning" showIcon />} */}
                    </Col>
                    <Col span={2}>
                      <Button
                        onClick={() => this.validate(true)}
                        disabled={this.state.student.id != -1}
                        type="primary"
                        icon="search"
                        className={"input-shadow"}
                        size={"large"}
                      />
                    </Col>{" "}
                  </Row>
                  <Divider dashed />
                  <Row gutter={10}>
                    <Col span={12}>
                      <Input
                        addonBefore={"Full Name"}
                        placeholder="First + Middle + Last Name"
                        value={window.TitleCase(this.state.student.Name)}
                        onChange={(val) => {
                          var cState = this.state.student;
                          cState.Name = val.target.value;
                          this.setState({ student: cState });
                        }}
                        className={"input-shadow"}
                      />
                    </Col>
                    <GoBr />
                    <Col span={3}></Col>
                    <Col span={9}>
                      <Select
                        placeholder={"Select Gender"}
                        value={this.state.student.Gender}
                        style={{ width: "100%" }}
                        onChange={(val) => {
                          this.state.student.Gender = val;
                          this.forceUpdate();
                        }}
                        className={"input-shadow"}
                      >
                        <Select.Option value="Male">Male</Select.Option>
                        <Select.Option value="Female">Female</Select.Option>
                      </Select>
                    </Col>
                  </Row>

                  <Divider dashed />
                  <Row gutter={10}>
                    <Col span={3}></Col>
                    <Col span={9}>
                      <AutoComplete
                        style={{ width: "100%" }}
                        dataSource={get("classes")}
                        placeholder="Select Class"
                        value={this.state.student.Class}
                        onChange={(val) => {
                          var cState = this.state.student;
                          cState.Class = val;
                          this.setState({ student: cState });
                        }}
                        className={"input-shadow"}
                      />
                    </Col>
                    <GoBr />
                    <Col span={3}></Col>
                    <Col span={9}>
                      <AutoComplete
                        mode={"tags"}
                        style={{ width: "100%" }}
                        dataSource={get("streams")}
                        placeholder="Select Stream"
                        value={this.state.student.Stream}
                        onChange={(val) => {
                          let cState = this.state.student;
                          cState.Stream = val;
                          this.setState({ student: cState });
                        }}
                        className={"input-shadow"}
                      />
                    </Col>
                  </Row>
                  <br />

                  <Divider dashed />
                </Tabs.TabPane>

                <Tabs.TabPane
                  tab={
                    <span>
                      <Icon type="team" />
                      &nbsp;&nbsp;Guardians&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  }
                  disabled={this.state.student.id === -1}
                  key="2"
                >
                  {/*<StudentGuardians data={this.state.guardians}  />*/}
                  <Divider style={{ marginTop: -3 }} orientation={"left"}>
                    <Ant.Typography.Text type={"secondary"}>MANAGE GUARDIANS</Ant.Typography.Text>
                  </Divider>
                  <Ant.Row gutter={19}>
                    <GuardianEditor
                      data={this.state.guardians}
                      onChange={(g) => this.setState({ guardians: g })}
                    />
                  </Ant.Row>
                </Tabs.TabPane>

                <Tabs.TabPane
                  tab={
                    <span>
                      <Icon type="calendar" />
                      &nbsp;&nbsp;Scheduling&nbsp;&nbsp;&nbsp;
                    </span>
                  }
                  disabled={this.state.student.id === -1}
                  style={{ padding: 28 }}
                  key="3"
                >
                  <Ant.Tabs tabPosition={"left"}>
                    <Ant.Tabs.TabPane key={"schedules"} tab="Scheduled Trips">
                      <Row gutter={10} style={{ padding: "10px" }}>
                        <Col span={12}>
                          <Switch
                            // Css
                            style={{
                              height: 33,
                              borderRadius: 6,
                              backgroundColor: IsToSchool ? "#01C589" : "#32535E",
                            }}
                            // Props
                            defaultChecked={true}
                            checkedChildren={
                              <h5 style={{ color: "white", marginTop: 8 }}>
                                <GoSp cols={3} />
                                🌅 To School <GoSp cols={2} />
                              </h5>
                            }
                            unCheckedChildren={
                              <h5 style={{ color: "whitesmoke", marginTop: 8 }}>
                                <GoSp cols={2} />
                                🌇 From School
                                <GoSp cols={4} />
                              </h5>
                            }
                            size={"small"}
                            // Events
                            onChange={(val) => this.setState({ IsToSchool: val })}
                          />
                        </Col>
                        <Col span={12} align={"right"}>
                          {this.state.permissions.get(14) &&
                          this.state.permissions.get(14).can_add == 1 ? (
                            <Button
                              onClick={() => this.setState({ AddSchedules: true })}
                              shape={"round"}
                              type={"primary"}
                              icon={"plus"}
                            />
                          ) : null}
                        </Col>
                      </Row>
                      <Row gutter={10} style={{ padding: "10px" }}>
                        {_.from(this.state.student.schedules)
                          .where((r) => r.Direction === (IsToSchool ? "To School" : "From School"))
                          .select((schedule, idx) => {
                            let driver = users.firstOrDefault((u) => u.id == schedule.DriverId);
                            let attendant = users.firstOrDefault(
                              (u) => u.id == schedule.AttendantId
                            );
                            let vehicle = vehicles.firstOrDefault(
                              (v) => v.id == schedule.VehicleId
                            );

                            return (
                              <Col span={23}>
                                <Ant.Card
                                  bordered={false}
                                  className={"shadow"}
                                  style={{
                                    width: "100%",
                                    paddingTop: 8,
                                    margin: 10,
                                  }}
                                  actions={[
                                    <a
                                      href={"#/schedules?id=" + schedule.id}
                                      target={"_blank"}
                                      disabled={
                                        this.state.permissions.get(14) &&
                                        this.state.permissions.get(14).can_modify == 0
                                          ? true
                                          : false
                                      }
                                    >
                                      <Button type={"link"} icon={"edit"} />
                                    </a>,

                                    <Popconfirm
                                      title={`Remove student from ${schedule.Name}?`}
                                      okButtonProps={{
                                        type: "danger",
                                      }}
                                      okText={"Confirm"}
                                      onConfirm={() =>
                                        this.Handle_Remove_Schedule_Student(schedule.id)
                                      }
                                      disabled={
                                        this.state.permissions.get(14) &&
                                        this.state.permissions.get(14).can_delete == 0
                                          ? true
                                          : false
                                      }
                                    >
                                      <Button
                                        style={{ color: "tomato" }}
                                        type={"link"}
                                        icon={"close"}
                                      />
                                    </Popconfirm>,
                                  ]}
                                >
                                  <Ant.Card.Meta
                                    avatar={
                                      <Avatar
                                        style={{ backgroundColor: "#87d068" }}
                                        // icon="calendar"
                                      >
                                        {idx + 1}
                                      </Avatar>
                                    }
                                    title={
                                      vehicle.PlateNo +
                                      " • " +
                                      window.TitleCase(schedule.Name || null)
                                    }
                                    description={schedule.Direction || null}
                                  />

                                  <hr />
                                  <div style={{ marginLeft: 50 }}>
                                    <Icon type="home" />
                                    &nbsp; &nbsp;
                                    {vehicle.Provider}
                                    <hr />
                                    <Icon type="car" />
                                    &nbsp; &nbsp;
                                    {vehicle.Model || null}
                                    <hr />
                                    <Icon type="user" />
                                    &nbsp; &nbsp;
                                    <Ant.Typography.Text type={"secondary"}>
                                      Driver 👉
                                    </Ant.Typography.Text>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <a
                                      href={"#/m-user?id=" + driver?.id}
                                      style={{ float: "right" }}
                                      target={"_blank"}
                                    >
                                      <Button type={"link"}>
                                        {driver?.name || null}&nbsp;&nbsp;•&nbsp;&nbsp;
                                        {driver?.phone || null}
                                      </Button>
                                    </a>
                                    <hr />
                                    <Icon type="user" />
                                    &nbsp; &nbsp;
                                    <Ant.Typography.Text type={"secondary"}>
                                      Attendant 👉
                                    </Ant.Typography.Text>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <a
                                      href={"#/m-user?id=" + attendant.id}
                                      style={{ float: "right" }}
                                      target={"_blank"}
                                    >
                                      <Button type={"link"}>
                                        {attendant.name || null}&nbsp;&nbsp;•&nbsp;&nbsp;
                                        {attendant.phone || null}
                                      </Button>
                                    </a>
                                  </div>
                                </Ant.Card>{" "}
                              </Col>
                            );
                          })
                          .toArray()}
                      </Row>
                    </Ant.Tabs.TabPane>
                    <Ant.Tabs.TabPane key={"schedule-settings"} tab="Schedule Settings">
                      <Row gutter={10}>
                        <Col span={24}>
                          {this.state.station.toString().split("-").length === 1 ? (
                            <span style={{ color: "red" }}>Select a station</span>
                          ) : (
                            "Route / Station"
                          )}
                          <TreeSelect
                            className={"input-shadow"}
                            style={{ width: "100%" }}
                            value={this.state.station}
                            dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                            treeData={treeData}
                            placeholder="Please select Station"
                            treeCheckable={true}
                            showCheckedStrategy={TreeSelect.SHOW_CHILD}
                            onChange={(value) => {
                              var val = value;
                              if (value.length) {
                                val = value[value.length - 1];
                              }

                              var firstDash = val.toString().indexOf("-") + 1;
                              var lastDash = val.toString().lastIndexOf("-") + 1;

                              var routeId = val
                                .toString()
                                .substr(firstDash, lastDash - firstDash - 1);
                              var stationId = val.toString().substr(lastDash);

                              this.state.student.RouteId = parseInt(routeId);
                              this.state.student.StationId = parseInt(stationId);
                              this.state.station = val;
                              this.forceUpdate();
                            }}
                          />
                        </Col>
                      </Row>

                      <br />
                      <Row gutter={10}>
                        <Col span={14}>
                          <Input
                            addonBefore={"Cost"}
                            className={"input-shadow"}
                            placeholder="Cost"
                            value={this.state.student.Cost}
                            onChange={(val) => {
                              var cState = this.state.student;
                              cState.Cost = val.target.value;
                              this.setState({ student: cState });
                            }}
                          />
                        </Col>

                        <br />
                        <Col span={14}>
                          <Select
                            addonBefore={"Type"}
                            className={"input-shadow"}
                            defaultValue={this.state.student.Subscription}
                            style={{ width: "100%" }}
                            onChange={(val) => {
                              var cState = this.state.student;
                              cState.Subscription = val;
                              this.setState({ student: cState });
                            }}
                          >
                            <Select.Option value="All">All</Select.Option>
                            <Select.Option value="To School">To School</Select.Option>
                            <Select.Option value="From School">From School</Select.Option>
                            <Select.Option value="None">None</Select.Option>
                          </Select>
                        </Col>
                      </Row>

                      <br />
                    </Ant.Tabs.TabPane>
                    <Ant.Tabs.TabPane
                      disabled={true}
                      key={"student-calendar"}
                      tab="Student Calendar"
                    ></Ant.Tabs.TabPane>
                  </Ant.Tabs>
                </Tabs.TabPane>

                <Tabs.TabPane
                  tab={
                    <span>
                      <Icon type="security-scan" />
                      &nbsp;&nbsp;Permissions &nbsp;
                    </span>
                  }
                  style={{ padding: 28 }}
                  key="permissions"
                  disabled={this.state.student.id === -1}
                >
                  <Divider style={{ marginTop: -24 }} orientation={"left"}>
                    <Ant.Typography.Text type={"secondary"}>PERMISSIONS</Ant.Typography.Text>
                  </Divider>
                  <br />
                  <Row gutter={10}>
                    <Col span={2} style={{ paddingTop: 10 }}>
                      <Ant.Typography.Text type={"secondary"}>Status</Ant.Typography.Text>
                    </Col>
                    <Col span={8}>
                      <Select
                        placeholder={"Status..."}
                        value={this.state.student.Status}
                        style={{ width: "100%" }}
                        onChange={(value) => {
                          this.state.student.Status = value;
                          this.forceUpdate();
                        }}
                        size={"large"}
                        className={"input-shadow"}
                        // disabled={this.state.permissions.get(3) && this.state.permissions.get(3).can_modify == 1 ? false : true}
                      >
                        <Select.Option value="Active">Active</Select.Option>
                        <Select.Option value="Suspended">Suspended</Select.Option>
                        <Select.Option value="Alumni">Alumni</Select.Option>
                        <Select.Option value="Transferred">Transferred</Select.Option>
                        <Select.Option value="Deceased">Deceased</Select.Option>
                        <Select.Option value="Expelled">Expelled</Select.Option>
                        <Select.Option value="Inactive">Inactive</Select.Option>
                      </Select>
                    </Col>
                  </Row>
                  <GoBr />
                  <Row gutter={10}>
                    <Divider dashed />
                    <Col span={2}></Col>
                    <Col span={2} align={"center"} style={{ paddingTop: 10 }}>
                      <Ant.Switch
                        checkedChildren={<Icon type="check" />}
                        unCheckedChildren={<Icon type="close" />}
                        defaultChecked
                        // disabled={this.state.permissions.get(3) && this.state.permissions.get(3).can_modify == 1 ? false : true}
                      />
                    </Col>
                    <Col span={20} style={{ paddingTop: 10 }}>
                      <Ant.Typography.Text type={"secondary"}>
                        Allow Gate Access
                      </Ant.Typography.Text>
                    </Col>
                    <br /> <br />
                    <Divider dashed />
                    <Col span={2}></Col>
                    <Col span={2} align={"center"} style={{ paddingTop: 10 }}>
                      <Ant.Switch
                        checkedChildren={<Icon type="check" />}
                        unCheckedChildren={<Icon type="close" />}
                        defaultChecked
                        // disabled={this.state.permissions.get(3) && this.state.permissions.get(3).can_modify == 1 ? false : true}
                      />
                    </Col>
                    <Col span={20} style={{ paddingTop: 10 }}>
                      <Ant.Typography.Text type={"secondary"}>
                        Allow Transport Access
                      </Ant.Typography.Text>
                    </Col>
                    <br />
                    <br />
                    <Divider dashed />
                  </Row>
                </Tabs.TabPane>

                <Tabs.TabPane
                  tab={
                    <span>
                      <Icon type="message" />
                      &nbsp;&nbsp;Messages&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  }
                  key="messages"
                  disabled={true /** this.state.student.id === -1**/}
                >
                  <Divider style={{ marginTop: 0 }} orientation={"left"}>
                    <Ant.Typography.Text type={"secondary"}>MESSAGES</Ant.Typography.Text>
                  </Divider>
                  {this.state.student.id >= 0 && <Studentmessages student={this.state.student} />}
                </Tabs.TabPane>

                <Tabs.TabPane
                  tab={
                    <span>
                      <Icon type="lock" />
                      &nbsp;&nbsp;Activity&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  }
                  key="activity"
                  disabled={true}
                >
                  {/*Activity*/}
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab={
                    <span>
                      <Icon type="lock" />
                      &nbsp;&nbsp;Billing&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  }
                  key="billing"
                  disabled={true}
                >
                  {/*Activity*/}
                </Tabs.TabPane>

                <Tabs.TabPane
                  tab={
                    <span>
                      <Icon type="lock" />
                      &nbsp;&nbsp;Rebates&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  }
                  key="logs"
                  disabled={true}
                >
                  {/*Activity*/}
                </Tabs.TabPane>

                {/*{ Env.isDevelopment() && <Tabs.TabPane*/}
                {/*    tab={*/}
                {/*        <span>*/}
                {/*    <Ant.Tag color="green">Dev</Ant.Tag>&nbsp;&nbsp;Guardians&nbsp;&nbsp;&nbsp;&nbsp;*/}
                {/*</span>*/}
                {/*    }*/}
                {/*    key="22"*/}
                {/*>*/}
                {/*    <StudentGuardians data={this.state.guardians}  />*/}

                {/*</Tabs.TabPane>}*/}

                <Tabs.TabPane
                  disabled={this.state.student.id === -1}
                  tab={
                    <span>
                      <Icon type="edit" />
                      &nbsp;&nbsp;Notes&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  }
                  key="5"
                >
                  <Input.TextArea
                    className={"input-shadow"}
                    value={this.state.student.Notes}
                    placeholder="Record notes about the student here..."
                    style={{ height: 200 }}
                    onChange={(value) => {
                      this.state.student.Notes = value.target.value;
                      this.forceUpdate();
                    }}
                  />
                </Tabs.TabPane>
              </Tabs>
            </Ant.Col>
          </Ant.Row>
        </Card>
      </Layout.Content>
    );
  }
}

///////////////////////////////////////////////////GURADIAN EDITOR
class GuardianEditor extends Component {
  state = {
    name: "GuardianEditor",
    guardians: [],
    permissions: get2("permissions"),
  };

  componentDidMount() {
    subscribe(this);
  }

  render() {
    if (this.state.guardians !== this.props.data) {
      this.setState({ guardians: this.props.data });
    }

    const rows = this.state.guardians.map(
      function (data, idx) {
        return (
          <Ant.Col span={12}>
            <Card
              style={{ margin: 21, width: "100%" }}
              size={"small"}
              title={
                <>
                  <Avatar style={{ backgroundColor: "#87d068" }} icon="user" />{" "}
                  <Ant.Typography.Text
                    style={{
                      marginLeft: 12,
                      marginTop: 10,
                    }}
                  >
                    {" "}
                    {window.TitleCase(data.name)}
                  </Ant.Typography.Text>
                </>
              }
              key={idx}
              className={"shadow"}
              extra={
                this.state.permissions.get(5) && this.state.permissions.get(5).can_delete == 1 ? (
                  <Button
                    type={"link"}
                    shape={"circle"}
                    style={{ color: "crimson" }}
                    onClick={() => {
                      //remove item
                      let g = this.state.guardians;
                      g.splice(idx, 1);
                      this.setState({ guardians: g });
                      this.props.onChange(g);
                    }}
                    icon={"close"}
                  />
                ) : null
              }
            >
              <Row gutter={20} style={{ padding: 28 }}>
                <Col span={24}>
                  <Input
                    addonBefore="Name"
                    placeholder="Full Name • optional"
                    value={window.TitleCase(data.name)}
                    style={{ width: "100%" }}
                    className={"input-shadow"}
                    onChange={(val) => {
                      let cState = this.state.guardians;
                      cState[idx].name = val.target.value;
                      this.setState({ guardians: cState });
                      this.props.onChange(this.state.guardians);
                    }}
                  />
                </Col>
                <GoBr />
                <Col span={24}>
                  <Input
                    addonBefore="Phone"
                    placeholder="Phone"
                    style={{ width: "100%" }}
                    className={"input-shadow"}
                    value={data.phone}
                    onChange={(val) => {
                      if (val.target.value.trim().length >= 10) {
                        if (
                          get("guardians").filter((r) => r.phone === val.target.value.trim())
                            .length > 0
                        ) {
                          var cState = this.state.guardians;
                          cState[idx].name = get("guardians").filter(
                            (r) => r.phone === val.target.value.trim()
                          )[0].name;
                          this.setState({ guardians: cState });
                        }
                      }
                      var cState = this.state.guardians;
                      cState[idx].phone = val.target.value;
                      this.setState({ guardians: cState });
                      this.props.onChange(this.state.guardians);
                    }}
                  />
                </Col>
                <GoBr />

                <Divider dashed />
                <Col span={10}>Relationship</Col>
                <Col span={14}>
                  <Select
                    className={"input-shadow"}
                    defaultValue="Guardian"
                    style={{ width: "100%" }}
                  >
                    <Select.Option value="Guardian">Guardian</Select.Option>
                    <Select.Option value="Mother">Mother</Select.Option>
                    <Select.Option value="Father">Father</Select.Option>
                    <Select.Option value="Sibling">Sibling</Select.Option>
                    <Select.Option value="Nanny">Nanny</Select.Option>
                    <Select.Option value="Grand Parent">Grand Parent</Select.Option>
                    <Select.Option value="Foster Mother">Foster Mother</Select.Option>
                    <Select.Option value="Foster Father">Foster Father</Select.Option>
                    <Select.Option value="Other">Other</Select.Option>
                  </Select>
                </Col>

                <Divider dashed />
                <Col span={10}>Receive SMS</Col>
                <Col span={14}>
                  <Ant.Switch
                    defaultChecked={data.sms}
                    onChange={(val) => {
                      let cState = this.state.guardians;
                      cState[idx].sms = val;
                      this.setState({ guardians: cState });
                      this.props.onChange(this.state.guardians);
                    }}
                  />
                </Col>
              </Row>
            </Card>
          </Ant.Col>
        );
      }.bind(this)
    );
    return (
      <Card bordered={false}>
        {rows}
        <Col span={12} align={"center"}>
          {this.state.permissions.get(5) && this.state.permissions.get(5).can_add == 1 ? (
            <Tooltip title={"Add Guardian"}>
              <Button
                style={{
                  borderWidth: "0px",
                  marginLeft: "15px",
                  fontSize: "100px",
                  height: "190px",
                  width: "190px",
                  marginTop: "23%",
                  paddingBottom: "19px",
                }}
                icon="plus"
                shape={"round"}
                type="outline"
                size={"large"}
                className={"shadow"}
                onClick={() => {
                  let guardians = this.state.guardians;
                  guardians.push({
                    id: -1,
                    name: "",
                    phone: "07",
                    sms: true,
                  });
                  this.setState({ guardians: guardians });
                  this.props.onChange(this.state.guardians);
                }}
              />
            </Tooltip>
          ) : null}
        </Col>
      </Card>
    );
  }
}

export default Students;
