const SortAlphabetic = (a, b) => {
    // Convert to uppercase for case-insensitive sorting
    const nameA = JSON
        .stringify(a || "_")
        .toUpperCase()
        .trim();

    const nameB = JSON
        .stringify(b || "_")
        .toUpperCase()
        .trim();

    if (nameA === nameB)
        return 0;

    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }

    return 0;
}
export default SortAlphabetic;